<template>
  <canvas ref="chartCon"></canvas>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

export default {
  name: "em-commitpush-chart",
  props: {
    options: {
      type: Object,
      default() {
        return {
          type: "horizontalBar",
          data: {
            labels: [],
            datasets: [
              {
                label: "Push ( 서버 전송 )",
                backgroundColor: "rgb(54,162,235)",
                data: [],
              },
              {
                label: "Commit ( 저장 )",
                backgroundColor: "rgb(255,99,132)",
                data: [],
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            tooltips: { enabled: false, mode: "index", intersect: false },
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  display: false,

                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 4,
                  },
                  gridLines: { display: false },
                  scaleLabel: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  display: false,

                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                  },

                  gridLines: { display: false },
                  scaleLabel: {
                    display: false,
                  },
                },
              ],
            },
          },
        };
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: function () {
    var data = {
      chartCtx: null,
      loading: true,
      entities: { content: [] },
    };
    return data;
  },
  watch: {
    data: function (newVal, oldVal) {
      this.updateData();
    },
  },
  methods: {
    updateData: function () {
      //console.log("updateData",this.data);
      this.chartCtx.data.labels = [];
      this.chartCtx.data.datasets[0].data = [this.data.PUSH_CNT];
      this.chartCtx.data.datasets[1].data = [
        this.data.TOTAL_CNT - this.data.PUSH_CNT,
      ];
      this.chartCtx.update();
      //console.log("this.chartCtx",this.chartCtx);
      //console.log("this.chartCtx",this.chartCtx.data.datasets);
    },
  },
  mounted: function () {
    //console.log(this);

    //console.log(this.data);
    //console.log("-----------------",this.data.length);
    var ctx = this.$refs.chartCon.getContext("2d");
    this.chartCtx = new Chart(ctx, _.cloneDeep(this.options));
    this.updateData();
  },
};
</script>

<style></style>