<template>
  <el-table
    ref="table"
    :data="CLASS"
    style="width: 100%"
    size="mini"
    border
    stripe
    :header-row-style="{ 'font-size': '13px' }"
    :row-style="{ 'font-size': '13px' }"
    @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" width="50"> </el-table-column>
    <el-table-column label="서버" width="100">
      <template slot-scope="scope">{{
        scope.row.entity.serverId | formatCode(SERVER)
      }}</template>
    </el-table-column>
    <el-table-column label="인터페이스 분류" width="150">
      <template slot-scope="scope">{{
        scope.row.entity.interfaceClass | formatCode(INTERFACE_CLASS)
      }}</template>
    </el-table-column>
    <el-table-column label="SOURCE TYPE">
      <template slot-scope="scope">{{
        scope.row.entity.consumerType | formatCode(CONSUMER_TYPE)
      }}</template>
    </el-table-column>
    <el-table-column label="TARGET TYPE">
      <template slot-scope="scope">{{
        scope.row.entity.providerType | formatCode(PROVIDER_TYPE)
      }}</template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "em-class-support",
  data: function () {
    var data = {
      CLASS: [],
      INTERFACE_CLASS: [],
      CONSUMER_TYPE: [],
      PROVIDER_TYPE: [],
      classSelection: []
    };
    return data;
  },
  methods: {
    handleSelectionChange: function (val) {
      this.classSelection = val;
      this.$emit("selection-change", val);
    },
    getSelect: function () {
      var classes = [];
      for (var i = 0; i < this.classSelection.length; i++) {
        var e = this.classSelection[i];
        classes.push({
          serverId: e.entity.serverId,
          interfaceClass: e.entity.interfaceClass,
          consumerType: e.entity.consumerType,
          providerType: e.entity.providerType
        });
      }
      return classes;
    },
    setSelect: function (classes) {
      this.$refs.table.clearSelection();
      for (var i = 0; i < this.CLASS.length; i++) {
        var e = this.CLASS[i];
        if (_.findIndex(classes, { serverId: e.entity.serverId, interfaceClass: e.entity.interfaceClass, consumerType: e.entity.consumerType, providerType: e.entity.providerType, }) > -1) {
          this.$refs.table.toggleRowSelection(e);
        }
      }
    },
    init: function () {
      var _this = this;
      var q4 = axios.get("/rest/common/codes?type=class");
      var q1 = axios.get("/rest/common/codes?type=server");
      var q11 = axios.get("/rest/common/codes?type=code&id=INTERFACE_CLASS");
      var q12 = axios.get("/rest/common/codes?type=code&id=CONSUMER_TYPE");
      var q13 = axios.get("/rest/common/codes?type=code&id=PROVIDER_TYPE");

      axios.all([q1, q4, q11, q12, q13]).then(axios.spread(function (v1, v4, v11, v12, v13) {
        _this.SERVER = v1.data;
        _this.CLASS = v4.data;
        _this.INTERFACE_CLASS = v11.data;
        _this.CONSUMER_TYPE = v12.data;
        _this.PROVIDER_TYPE = v13.data;
      }));

    }
  },
  mounted: function () {
    this.init();
    //console.log("BResourceTag mounted");
  },
};
</script>

<style></style>
