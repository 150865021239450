<template>
  <rect
    e
    v-if="shape.type == 'Rect'"
    class="in-mapping-shape"
    shape-rendering="optimizeQuality"
    @mousedown="mousedown($event,item)"
    @click="click($event)"
    :class="classObject"
    :selected="item.row.select==true"
    :hovered="item.row.hover==true"
    :recommend="item.row.recommend==true"
    :x="shape.x"
    :y="shape.y"
    :width="shape.width"
    :height="shape.height"
  />
  <polygon
    v-else-if="shape.type == 'Triangle'"
    class="in-mapping-shape"
    shape-rendering="optimizeQuality"
    @mousedown="mousedown($event)"
    @click="click($event)"
    :class="classObject"
    :selected="item.row.select==true"
    :hovered="item.row.hover==true"
    :recommend="item.row.recommend==true"
    :points="item.svg.target.start.points"
  />
  <circle
    v-else-if="shape.type == 'Circle'"
    class="in-mapping-shape"
    shape-rendering="optimizeQuality"
    @mousedown="mousedown($event)"
    @click="click($event)"
    :class="classObject"
    :selected="item.row.select==true"
    :hovered="item.row.hover==true"
    :recommend="item.row.recommend==true"
    :clicked="item.row.click==true"
    :cx="shape.cx"
    :cy="shape.cy"
    :r="shape.r"
  />
  <path
    v-else-if="shape.type == 'Path'"
    class="in-mapping-path"
    shape-rendering="optimizeQuality"
    @mousedown="mousedown($event)"
    @click="click($event)"
    :class="classObject"
    :selected="item.row.select==true"
    :hovered="item.row.hover==true"
    :recommend="item.row.recommend==true"
    :d="shape.d"
  />
</template>

<script>
export default {
  name: "em-mapping-svg-shape",
  props: {
    shape: {
      type: Object,
    },
    error: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  computed: {
    classObject: function () {
      var classObject = {
        dash: this.shape.dash,
        fill: this.shape.fill,
        stroke: !this.shape.fill,
        error: this.error,
      };
      //
      // console.log(this.item);
      // console.log(this.shape);
      return classObject;
    },
  },
  methods: {
    mousedown: function (e, item) {
      // console.log("dragstartshape");
      this.$emit("dragstartshape", e);
    },
    click: function (e, item) {
      //console.log("click" , e  );
      this.$emit("clickshape", e);
    },
  },
};
</script>

<style scoped>
</style>
