<template>
  <tr
    class="em-model-tr-cell"
    :rowselected="item.row.select == true"
    :rowreadonly="item.row.readonly == true"
    :readonly="readonly == true"
  >
    <td
      valign="middle"
      class="p-0"
      style="position: relative"
      :style="indicateStyle()"
    >
      <div style="width: 40px">
        <label
          class="em-model-form-control-cell m-0"
          draggable="true"
          @click="$parent.clickIndicate($event, item)"
          @dragstart="$parent.dragStartIndicate($event, item)"
          @dragenter="$parent.dragEnterIndicate($event, item)"
          @dragover="$parent.dragOverIndicate($event, item)"
          v-text="item.tid"
        ></label>
      </div>
      <el-tooltip
        v-if="item.data.indicate && item.data.indicate != ''"
        :content="item.data.indicateMsg"
      >
        <div
          class="grid-indicate-dot"
          :style="indicateDotStyle()"
          v-if="item.data.indicate && item.data.indicate != ''"
        ></div>
      </el-tooltip>
    </td>
    <td
      class="p-0 em-model-td-cell"
      v-for="(c, col) in $parent.mfields"
      :selected="item.row.select == false && item.cols[col].select == true"
      :actived="item.row.select == false && item.cols[col].active == true"
      :error="item.cols[col].error == true"
      :rowselected="item.row.select == true"
      :key="'col_' + col"
    >
      <template v-if="readonly == true || item.row.readonly == true">
        <template v-if="col == 0">
          <div
            class="row no-gutters"
            style="height: 27px; float: left; flex-wrap: nowrap"
          >
            <div
              class="col border-right"
              v-for="n in item.depth"
              style="width: 10px; max-width: 10px"
              :key="'col_' + col + '_depth' + n"
            ></div>
            <div
              class="col border-right1 text-center"
              v-if="item.children.length > 0"
              style="width: 12px; max-width: 12px"
            >
              <i class="fa fa-caret-down" style="margin-top: 6px"></i>
            </div>
            <div
              class="col border-right1 text-center"
              v-if="item.children.length == 0"
              style="width: 12px; max-width: 12px"
            ></div>
            <div class="col">
              <label
                class="em-model-form-control-cell m-0"
                draggable="true"
                tabindex="0"
                v-text="$parent.getColLabel(item, col)"
                :id="idlabel(col)"
                @dragstart="$parent.dragStartLabel($event, item, col)"
                @dragover="$parent.dragOverLabel($event, item, col)"
                @drop="$parent.dropLabel($event, item, col)"
              ></label>
            </div>
          </div>
        </template>
        <template v-else>
          <label
            class="em-model-form-control-cell m-0"
            draggable="true"
            tabindex="0"
            v-text="$parent.getColLabel(item, col)"
            :id="idlabel(col)"
            @dragstart="$parent.dragStartLabel($event, item, col)"
            @dragover="$parent.dragOverLabel($event, item, col)"
            @drop="$parent.dropLabel($event, item, col)"
          ></label>
        </template>
      </template>
      <template v-else>
        <template v-if="item.cols[col].active == false">
          <template v-if="col == 0">
            <div
              class="row no-gutters"
              style="height: 26px; float: left; flex-wrap: nowrap; width: 100%"
            >
              <div
                class="col border-right"
                v-for="n in item.depth"
                style="width: 10px; max-width: 10px"
                :key="'col_' + col + '_depth' + n"
              ></div>
              <div
                class="col border-right1 text-center"
                v-if="item.children.length > 0"
                style="width: 12px; max-width: 12px"
              >
                <i class="fa fa-caret-down" style="margin-top: 6px"></i>
              </div>
              <div
                class="col border-right1 text-center"
                v-if="item.children.length == 0"
                style="width: 12px; max-width: 12px"
              ></div>
              <div class="col">
                <label
                  class="em-model-form-control-cell m-0"
                  draggable="true"
                  tabindex="0"
                  v-text="$parent.getColLabel(item, col)"
                  :id="idlabel(col)"
                  @click="$parent.clickLabel($event, item, col)"
                  @dragstart="$parent.dragStartLabel($event, item, col)"
                  @dragenter="$parent.dragEnterLabel($event, item, col)"
                  @dragover="$parent.dragOverLabel($event, item, col)"
                  @dragend="$parent.dragEndLabel($event, item, col)"
                  @drop="$parent.dropLabel($event, item, col)"
                  @keydown="$parent.keyDownLabel($event, item, col)"
                  :draging="item.cols[col].draging == true"
                ></label>
              </div>
            </div>
          </template>
          <template v-else>
            <label
              class="em-model-form-control-cell m-0"
              draggable="true"
              tabindex="0"
              v-text="$parent.getColLabel(item, col)"
              :id="idlabel(col)"
              @click="$parent.clickLabel($event, item, col)"
              @dragstart="$parent.dragStartLabel($event, item, col)"
              @dragenter="$parent.dragEnterLabel($event, item, col)"
              @dragover="$parent.dragOverLabel($event, item, col)"
              @dragend="$parent.dragEndLabel($event, item, col)"
              @drop="$parent.dropLabel($event, item, col)"
              @keydown="$parent.keyDownLabel($event, item, col)"
              :draging="item.cols[col].draging == true"
            ></label>
          </template>
        </template>
        <template v-else>
          <template v-if="c.type == 'text'">
            <template v-if="c.property">
              <input
                type="text"
                class="em-model-form-control-cell"
                v-model="item.data.properties[c.key]"
                :id="idcell(col)"
                @click.stop
                @change="$parent.changeCell($event, item, col)"
                @keydown.stop="$parent.keyDownInputCell($event, item, col)"
              />
            </template>
            <template v-else>
              <input
                type="text"
                class="em-model-form-control-cell"
                v-model="item.data[c.key]"
                :id="idcell(col)"
                @click.stop
                @change="$parent.changeCell($event, item, col)"
                @keydown.stop="$parent.keyDownInputCell($event, item, col)"
              />
            </template>
          </template>
          <template v-if="c.type == 'select'">
            <template v-if="c.property">
              <select
                class="em-model-form-control-cell"
                size="mini"
                v-model="item.data.properties[c.key]"
                :id="idcell(col)"
                @click.stop
                @change="$parent.changeCell($event, item, col)"
                @keydown.stop="$parent.keyDownInputCell($event, item, col)"
              >
                <template v-for="o in c.option">
                  <option
                    v-show="o.show != false"
                    :label="o.label"
                    :value="o.value"
                    :key="o.value"
                  ></option>
                </template>
              </select>
            </template>
            <template v-else>
              <select
                class="em-model-form-control-cell"
                size="mini"
                v-model="item.data[c.key]"
                :id="idcell(col)"
                @click.stop
                @change="$parent.changeCell($event, item, col)"
                @keydown.stop="$parent.keyDownInputCell($event, item, col)"
              >
                <template v-for="o in c.option">
                  <option
                    v-show="o.show != false"
                    :label="o.label"
                    :value="o.value"
                    :key="o.value"
                  ></option>
                </template>
              </select>
            </template>
          </template>
        </template>
        <el-tooltip
          v-if="item.cols[col].error == true"
          :content="item.cols[col].errorMsg"
        >
          <div class="grid-cell-dot" v-if="item.cols[col].error == true"></div>
        </el-tooltip>
      </template>
    </td>
  </tr>
</template>

<script>
export default {
  name: "em-model-row",
  watch: {},
  computed: {},
  props: {
    item: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    indicateDotStyle: function() {
      if (this.item.data.indicate == "C") {
        return { "background-color": "#E6A23C" };
      }
      if (this.item.data.indicate == "W") {
        return { "background-color": "#E6A23C" };
      }
      if (this.item.data.indicate == "E") {
        return { "background-color": "#F56C6C" };
      }
    },
    indicateStyle: function() {
      if (this.item.row.select) {
        return { "background-color": "#daeeff" };
      } else {
        //console.log( this.item.children );
        if (this.item.children.length > 0) {
          return { "background-color": "#eeeeee" };
        } else {
          return { "background-color": "#eeeeee" };
        }
      }
    },
    idlabel: function(col) {
      return this.$parent.id + "_label_" + this.item.rowNo + "_" + col;
    },
    idcell: function(col) {
      return this.$parent.id + "_cell_" + this.item.rowNo + "_" + col;
    }
  },
  updated: function() {
    //   console.log("updated row" , this.item.data.key);
  }
};
</script>

<style scoped>
.em-model-form-control-cell {
  border: 0;
  border-radius: 0;
  background-color: transparent;
  display: block;
  width: 100%;
  font-weight: 400;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.5em + 0.5em + 2px);
  padding: 0.25rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  outline: 0;
}

.em-model-tr-cell[rowreadonly] {
  background-color: rgba(255, 255, 0, 0.1);
}
.em-model-tr-cell[readonly] {
  background-color: rgba(255, 255, 0, 0.1);
}
.em-model-tr-cell[rowselected] {
  background-color: #daeeff;
}

.em-model-td-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  position: relative;
  outline: 0;
}

.em-model-td-cell[rowselected] {
  box-sizing: border-box;
}

.em-model-td-cell[selected] {
  background-color: #daeeff;
  box-sizing: border-box;
}

.em-model-td-cell[actived] {
  background-color: transparent;
  box-sizing: border-box;
  outline: 2px solid #008aff;
  outline-offset: -2px;
}
.em-model-td-cell[error] {
  box-sizing: border-box;
  background-color: #ffdae3;
}
.em-model-td-cell-title {
  position: absolute;
  left: 10px;
  top: 10px;
  background: black;
  color: white;
}

.em-model-td-cell[include] {
  outline: 2px solid #e6a23c;
  outline-offset: -2px;
  box-sizing: border-box;
}
</style>
