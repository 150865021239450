<template>
  <el-row>
    <el-col>
      <i class="el-icon-collection-tag"></i>
      <el-tag
        size="mini"
        class="mr-1"
        :key="e.seq"
        v-for=" e in entities "
        closable
        :disable-transitions="false"
        @close="handleClose(e)"
      >{{e.resourceTag}}</el-tag>
      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="mini"
        style="width:100px"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      ></el-input>
      <el-button v-else class="button-new-tag" size="mini" @click="showInput">+</el-button>
    </el-col>
  </el-row>
</template>

<script>

export default {
  name: "em-resource-tag",
  props: {
    resourcetype: {
      type: String,
    },
    resourceid: {
      type: String,
    },
  },
  data: function () {
    var data = {
      inputVisible: false,
      inputValue: "",
      entities: [],
    };
    return data;
  },
  methods: {
    setDirty: function () {
      this.dirty = true;
    },
    getItems: function () {
      console.log(this.id, "tags getItems");

      var items = [];
      for (var i = 0; i < this.entities.length; i++) {
        items.push(this.entities[i]);
      }
      return items;
    },
    saveTag: function () {
      console.log("saveTag");

      var q1 = axios.put(
        "/rest/portal/tag/" + this.resourcetype + "/" + this.resourceid,
        this.getItems()
      );

      axios
        .all([q1])
        .then(axios.spread(function (v1) {}))
        .finally(function () {
          app.loading = false;
        });
    },
    handleClose: function (e) {
      console.log("handleClose", e, this.entities.indexOf(e));
      this.entities.splice(this.entities.indexOf(e), 1);
    },
    handleInputConfirm: function () {
      if (this.inputValue) {
        this.entities.push({ resourceTag: this.inputValue });
        this.$emit("changeitem");
      }

      this.inputValue = "";
      this.inputVisible = false;
    },
    showInput: function () {
      this.inputVisible = true;
      this.$nextTick(function () {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    load: function () {
      var _this = this;
      var q1 = axios.get(
        "/rest/portal/tag/" + this.resourcetype + "/" + this.resourceid
      );
      axios.all([q1]).then(
        axios.spread(function (v1) {
          _this.entities = v1.data;
        })
      );
    },
    init: function () {
      this.load();
    },
  },
  mounted: function () {
    this.init();
    console.log("BResourceTag mounted");
  },
};
</script>

<style></style>
