<template>
  <div>
    <div class="row no-gutters">
      <div class="col-4">
        <div class="table-responsive in-tree-grid- cell" style="overflow: hidden" ref="headDiv">
          <table class="table table-bordered in-table-table-cell table-sm mb-0" style="100px">
            <thead>
              <tr>
                <th scope="col" width="40" style="width:40px"></th>
                <th
                  scope="col"
                  v-for="(e,index) in $parent.mfields"
                  v-text="e.label"
                  :style="headerStyle(index)"
                  :key="index"
                ></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div class="col-4 border"></div>
      <div class="col-4">
        <div class="table-responsive in-tree-grid-cell" style="overflow: hidden" ref="headDiv">
          <table class="table table-bordered in-table-table-cell table-sm mb-0">
            <thead>
              <tr>
                <th scope="col" width="40" style="width:40px"></th>
                <th
                  scope="col"
                  v-for="(e,index) in $parent.mfields"
                  :width="e.width"
                  v-text="e.label"
                  :style="'width: '+ e.width"
                  :key="index"
                ></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "em-mapping-head",
  methods: {
    headerStyle: function (index) {
      var width = this.$parent.mfields[index].width;
      var style = { width: width };
      //    console.log("headerStyle",style);
      //    console.log(this.$refs);
      return style;
    },
  },
};
</script>

<style scoped>
</style>
