<template>
  <el-popover placement="top" title="사용자정보" width="500" trigger="hover" @show="show(textvalue)">
    <div>아이디 : {{params.userId}}</div>
    <div>성명 : {{params.userNm}}</div>
    <div>회사 : {{params.companyNm}}</div>
    <div>전화번호 : {{params.telNo}}</div>
    <span slot="reference">{{textvalue}}</span>
  </el-popover>
</template>

<script>
export default {
  name: "em-popover-user",
  props: {
    textvalue: {
      type: String,
    },
  },

  data: function () {
    var data = {
      params: {
        userId: "",
        userNm: "",
        companyNm: "",
        telNo: "",
      },
      visible: false,
    };
    return data;
  },

  methods: {
    show: function (e) {
      var _this = this;

      var q1 = axios.get("/rest/common/user?id=" + _this.textvalue, {
        popup: false,
      });
      axios.all([q1]).then(
        axios.spread(function (v1) {
          _this.params.userId = v1.data.userId;
          _this.params.userNm = v1.data.userNm;
          _this.params.companyNm = v1.data.companyNm;
          _this.params.telNo = v1.data.telNo;
        })
      );
    },
  },
  mounted: function () {},
};
</script>

<style></style>
