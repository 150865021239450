
export const toPath = function (sx, sy, ex, ey) {
  var midx = sx + (ex - sx) / 2;
  var path = "M " + sx + "," + sy + " Q " + midx + "," + sy + " " + ex + "," + ey;
  return path;
}

export const toTriplePath = function (sx, sy, ex, ey) {
  var mid1 = sx + ((ex - sx) * 0.25);
  var midX = sx + ((ex - sx) * 0.5);
  var midY = (sy + ey) * 0.5;
  var path = "M " + sx + "," + sy + " Q " + mid1 + " " + sy + "," + midX + "," + midY + " T " + ex + "," + ey;

  return path;
}

export const count = function (array, element) {

  var c = 0;
  for (var i = 0; i < array.length; i++) {
    if (array[i] == element) {
      c++;
    }
  }
  return c;
}
 

export const toTriangle = function (x, y, size) {

  var theight = (size * Math.sqrt(2)) / 2;

  return toPolygon([[x, y]
    , [x + size, y - theight]
    , [x + size, y + theight]]);


}


export const toPolygon = function (points) {

  //var polygon = "";
  var polygons = [];
  for (var i = 0; i < points.length; i++) {
    polygons.push(_.join(points[i], ","));
  }

  return _.join(polygons, " ");
}

export const freqMax = function (array) {

  var freqE = [];
  var freqC = [];
  for (var i = 0; i < array.length; i++) {
    var e = array[i];
    var indexOf = _.indexOf(freqE, e);

    if (indexOf == -1) {
      freqE.push(e);
      freqC.push(0);
    }
    else {
      freqC[indexOf] = freqC[indexOf] + 1;
    }
  }
  var maxC = 0;
  var maxI = 0;
  for (i = 0; i < freqC.length; i++) {
    var c = freqC[i];
    if (maxC < c) {
      maxC = c;
      maxI = i;
    }
  }

  //  console.log(freqE);
  //  console.log(freqC);
  return freqE[maxI];

}
export const getSourcePathModel = function (sourcePath) {

  var sourcePaths = _.split(sourcePath, ".");
  sourcePaths = _.drop(sourcePaths);
  return _.join(sourcePaths, ".");

}