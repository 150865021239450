<template>
  <div class="my-2">
    <el-row class="mt-2">
      <el-col class="text-left" :span="24">
        <span class="font-weight-bolder">* Comment</span>
      </el-col>
    </el-row>
    <el-row class="pb-1" v-for="e in entities.content" :key="e.seq">
      <el-col :span="20" class="text-left">
        <span class="font-weight-bolder" v-text="e.userId"></span>
        <span v-text="e.resourceComment"></span>
      </el-col>
      <el-col :span="4" class="text-right">
        <span
          class="font-weight-bolder"
          v-if="e.crtUser == sessionUsername"
          @click="delcomment(e)"
          :key="e.seq"
          style="cursor: pointer;"
        >삭제</span>
        <span class="text-muted">[ {{e.commentDate|formatDate}} {{e.commentTime|formatTime}}]</span>
      </el-col>
      <el-col :span="22" class="text-left"></el-col>
    </el-row>
    <el-row class="mt-2">
      <el-col class="text-center" :span="23">
        <el-input
          type="textarea"
          size="mini"
          :autosize="{minRows:1,maxRows:4}"
          v-model="entities.resourceComment"
          :show-word-limit="true"
          :maxlength="4000"
          rows="1"
        ></el-input>
      </el-col>
      <el-col class="text-right" :span="1">
        <el-button type="primary" size="mini" @click="commentSave" :loading="loading">저장</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */


export default {
  name: "em-resource-comment",
  props: {
    resourcetype: {
      type: String,
    },
    resourceid: {
      type: String,
    },
    sessionuser: {
      type: String,
    },
  },
  data: function () {
    var data = {
      params: { page: 0, size: 10 },
      loading: true,
      entities: {},
      sessionUsername: "",
    };
    return data;
  },
  methods: {
    commentSave: function () {
      this.entities.resourceId = this.resourceid;
      this.entities.resourceType = this.resourcetype;
      var q1 = axios.post("/rest/portal/comment", this.entities);

      var _this = this;
      axios.all([q1]).then(
        axios.spread(function (v1) {
          _this.loading = false;
          _this.load();
          app.$message("저장 되었습니다.");
        })
      );
    },
    delcomment: function (e) {
      var _this = this;
      var q1 = axios["delete"]("/rest/portal/comment/" + e.seq);

      axios.all([q1]).then(
        axios.spread(function (v1) {
          alert("삭제되었습니다.");
          _this.load();
        })
      );
    },
    move: function (page) {
      this.params.page = page - 1;
      this.load();
    },
    load: function () {
      this.loading = true;
      this.params.RESOURCE_ID = this.resourceid;
      this.params.RESOURCE_TYPE = this.resourcetype;
      this.sessionUsername = this.sessionuser;
      var _this = this;
      var q1 = axios.get("/rest/portal/comment", { params: this.params });
      axios.all([q1]).then(
        axios.spread(function (v1) {
          _this.entities = v1.data;
          _this.loading = false;
        })
      );
    },
    init: function () {
      this.load();
    },
  },
  mounted: function () {
    this.init();
    console.log("BCommentList mounted");
  },
};
</script>

<style></style>
