
export const forEachTreeChildren = function (items, func) {
  __forEachTreeChildren(0, undefined, items, func);
};

export const __forEachTreeChildren = function (depth, parent, items, func) {
  func(parent, items);

  for (var i = 0; i < items.length; i++) {
    if (_.isArray(items[i].children)) {
      __forEachTreeChildren(depth + 1, items[i], items[i].children, func);
    }
  }
};


export const forEachTree = function (items, func) {
  __forEachTree(0, undefined, items, func);
};

export const __forEachTree = function (depth, parent, items, func) {
  for (var i = 0; i < items.length; i++) {
    func(depth, i, parent, items[i]);
    if (_.isArray(items[i].children)) {
      __forEachTree(depth + 1, items[i], items[i].children, func);
    }
  }
};

export const getPathTree = function (items, item) {
  var paths = [];
  paths.unshift(item);
  var currentItem = item;
  for (var i = 0; i < 100; i++) {
    var parent = findParentTree(items, currentItem);
    if (parent == undefined) {
      break;
    }
    paths.unshift(parent);
    currentItem = parent;
  }
  var pathString = [];
  var arrayIndex = 1;
  for (var i = 0; i < paths.length; i++) {
    if (paths[i].data.arrayType == "Not") {
      pathString.push(paths[i].data.key);
    } else {
      pathString.push(paths[i].data.key + "[i" + arrayIndex + "]");
      arrayIndex++;
    }
  }
  return _.join(pathString, ".");
};

export const findParentTree = function (items, item, parent) {
  var indexOf = _.indexOf(items, item);
  if (indexOf >= 0) {
    return parent;
  }
  for (var i = 0; i < items.length; i++) {
    if (_.isArray(items[i].children)) {
      parent = findParentTree(items[i].children, item, items[i]);
      if (parent != undefined) {
        return parent;
      }
    }
  }
  // console.log( "findParentTree inner" , parent );

  return undefined;
};

export const findTreeFieldPath = function (items, path) {
  //console.log("findTreeFieldPath", items , path  );
  var paths = _.split(path, ".");
  if (paths[0] == "") {
    paths.shift();
  }
  var currentItems = items;
  var currentItem = undefined;
  //  console.log( paths );
  for (var i = 0; i < paths.length; i++) {
    var key = paths[i];

    var matched = false;
    for (var j = 0; j < currentItems.length; j++) {
      var data = currentItems[j].data;
      // console.log( i , j , currentItems[j].data.key , key )
      if (data.arrayType != "Not") {
        var indexOf = _.indexOf(key, "[");
        if (indexOf >= 0) {
          key = key.substr(0, indexOf);
          //          console.log("indexOf" , indexOf , key );
        }
        if (data.key == key) {
          currentItem = currentItems[j];
          currentItems = currentItem.children;
          // console.log( "mathch", i , j , currentItem )
          matched = true;
          break;
        }
      } else {
        if (data.key == key) {
          currentItem = currentItems[j];
          currentItems = currentItem.children;
          // console.log( "mathch", i , j , currentItem )
          matched = true;
          break;
        }
      }
    }
    if (matched == false) {
      currentItem = undefined;
    }
  }

  return currentItem;
};

export const deepCopyItems = function (v) {
  return JSON.parse(
    JSON.stringify(v, function (key, value) {
      return key == "parent" ? undefined : value;
    })
  );
};
