<template>
  <div class="table-responsive" style="overflow: hidden" ref="headDiv">
    <table class="table table-bordered table-sm mb-0 em-tree-table-cell">
      <colgroup>
        <col style="width: 40px" />
        <col
          v-for="(c, cidx) in $parent.mfields"
          :style="'width: ' + c.width"
          :key="cidx"
        />
      </colgroup>
      <thead>
        <tr>
          <th scope="col" width="40" style="width: 40px"></th>
          <th
            :key="index"
            scope="col"
            v-for="(e, index) in $parent.mfields"
            v-text="e.label"
            @click.stop="$parent.clickHeaderCol($event, index)"
          ></th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
  name: "em-tree-head",
  props: {
    copyable: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.em-tree-table-cell {
  table-layout: fixed;
  width: 100px;
}
</style>
