<template>
  <table class="table table-hover table-bordered table-striped table-sm mb-0">
    <tfoot>
      <tr>
        <td>
          <div class="row">
            <div class="col-auto">
              <el-button-group>
                <el-button
                  icon="el-icon-plus"
                  size="mini"
                  @click.stop="$parent.clickMenuInsertRow()"
                  :disabled="$parent.readonly"
                  xstyle="padding : 7px"
                ></el-button>
                <el-button
                  icon="el-icon-minus"
                  size="mini"
                  @click.stop="$parent.clickMenuDeleteRow()"
                  :disabled="$parent.readonly"
                  xstyle="padding : 7px"
                ></el-button>
                <el-button
                  icon="el-icon-caret-top"
                  size="mini"
                  @click.stop="$parent.clickMenuMoveUp()"
                  :disabled="$parent.readonly"
                  xstyle="padding : 7px"
                ></el-button>
                <el-button
                  icon="el-icon-caret-bottom"
                  size="mini"
                  @click.stop="$parent.clickMenuMoveDown()"
                  :disabled="$parent.readonly"
                  xstyle="padding : 7px"
                ></el-button>
                <el-button
                  icon="el-icon-caret-left"
                  size="mini"
                  @click.stop="$parent.clickMenuMoveLeft()"
                  :disabled="$parent.readonly"
                  xstyle="padding : 7px"
                ></el-button>
                <el-button
                  icon="el-icon-caret-right"
                  size="mini"
                  @click.stop="$parent.clickMenuMoveRight()"
                  :disabled="$parent.readonly"
                  xstyle="padding : 7px"
                ></el-button>
                <el-tooltip content="모델 검사">
                  <el-button
                    icon="el-icon-circle-check"
                    size="mini"
                    @click.stop="$parent.clickMenuCheck()"
                    :disabled="$parent.readonly"
                    xstyle="padding : 7px"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  content="참조 모델"
                  v-if="$parent.showInclude == true"
                >
                  <el-button
                    icon="el-icon-search"
                    size="mini"
                    @click.stop="$parent.clickMenuInclude()"
                    :disabled="$parent.readonly"
                    xstyle="padding : 7px"
                  ></el-button>
                </el-tooltip>
              </el-button-group>
              <el-button-group>
                <el-tooltip content="메타 내용을 검색 합니다.">
                  <el-button
                    icon="el-icon-search"
                    size="mini"
                    :disabled="$parent.readonly"
                    @click.stop="$parent.clickMenuMetaSearch()"
                    >메타 검색</el-button
                  >
                </el-tooltip>
                <el-tooltip
                  content="ID 나 이름으로 메타를 조회 하여 자동으로 필드 값을 채움니다."
                >
                  <el-button
                    icon="el-icon-magic-stick"
                    size="mini"
                    :disabled="$parent.readonly"
                    @click.stop="$parent.clickMenuMetaFill()"
                    >메타 채우기</el-button
                  >
                </el-tooltip>
              </el-button-group>
              <el-button-group>
                <el-upload
                  ref="uploadExcel"
                  action="/"
                  :multiple="false"
                  :limit="1"
                  :show-file-list="false"
                  accept=".xlsx"
                  :auto-upload="false"
                  :file-list="$parent.fileList"
                  :on-change="$parent.clickMenuExcelImport"
                >
                  <el-tooltip content="Excel 업로드">
                    <el-button
                      icon="el-icon-upload"
                      size="mini"
                      :disabled="$parent.readonly"
                      >Excel 업로드</el-button
                    >
                  </el-tooltip>
                </el-upload>
              </el-button-group>
              <el-button-group>
                <el-tooltip content="Excel 다운로드">
                  <el-button
                    icon="el-icon-download"
                    size="mini"
                    @click.stop="$parent.clickMenuExcelExport()"
                    >Excel 다운로드</el-button
                  >
                </el-tooltip>
              </el-button-group>
            </div>
          </div>
          <textarea
            v-if="copyable"
            ref="hiddenCopyText"
            class="sr-only"
            cols="100"
          ></textarea>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: "em-model-menu",
  props: {
    copyable: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
