<template>
  <el-row>
    <el-col :span="8">
      <el-card class shadow="never">
        <div slot="header" class="text-center">
          <h5 class="font-weight-bolder">Source 정보 (FROM)</h5>
        </div>
        <div class="text-center rounded-pill border shadow-sm m-3 p-3">
          <div class="text-center px-5 pt-4">
            <el-badge
              :value="sourceRequestModelErrorsTitle"
              :type="sourceRequestModelErrorsType"
              :hidden="sourceRequestModelErrors.length == 0"
              class="item"
            >
              <el-dropdown @command="handelCommand">
                <el-button-group>
                  <el-button
                    :type="requestMappingButtonType"
                    style="width: 200px"
                    plain
                    size="small"
                    @click="handelCommand('showConsumerRequestModel')"
                    >요청 모델</el-button
                  >
                  <el-button
                    :type="requestMappingButtonType"
                    plain
                    size="small"
                    class="el-dropdown__caret-button"
                    icon="el-dropdown__icon el-icon-arrow-down"
                  ></el-button>
                </el-button-group>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-show="resource.interfaceMeta.requestMappingYn == 'Y'"
                    icon="el-icon-view"
                    command="showConsumerRequestModel"
                    >자세히 보기</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="
                      showInclude == true &&
                      resource.interfaceMeta.requestMappingYn == 'Y'
                    "
                    icon="el-icon-search"
                    v-show="isDataModel(resource.requestModel) == false"
                    command="searchConsumerRequestModel"
                    >요청 모델 검색 ( 공통 )</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="
                      showInclude == true &&
                      resource.interfaceMeta.requestMappingYn == 'Y'
                    "
                    v-show="isDataModel(resource.requestModel) == true"
                    icon="el-icon-minus"
                    command="clearConsumerRequestModel"
                    >요청 모델 참조 삭제</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-show="resource.interfaceMeta.requestMappingYn == 'Y'"
                    icon="el-icon-minus"
                    command="clearConsumerRequestMapping"
                    >요청 맵핑 삭제</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-show="resource.interfaceMeta.requestMappingYn != 'Y'"
                    icon="el-icon-plus"
                    command="setConsumerRequestMapping"
                    >요청 맵핑 추가</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-badge>
          </div>
          <div class="my-3">
            <p class="titleFont my-0">
              &nbsp;{{
                resource.systemMeta.name + " ( " + resource.systemMeta.id + " )"
              }}
            </p>
            <p class="titleFont my-0">
              &nbsp;{{ resource.interfaceMeta.groups }}
            </p>
            <p class="titleFont my-0">
              &nbsp;{{
                resource.interfaceMeta.type1 | formatCode(codeConsumerType)
              }}
            </p>
          </div>
          <div class="text-center px-5 pb-4">
            <el-badge
              :value="sourceResponseModelErrorsTitle"
              :type="sourceResponseModelErrorsType"
              :hidden="sourceResponseModelErrors.length == 0"
              class="item"
            >
              <el-dropdown @command="handelCommand">
                <el-button-group>
                  <el-button
                    :type="responseMappingButtonType"
                    style="width: 200px"
                    plain
                    size="small"
                    @click="handelCommand('showConsumerResponseModel')"
                    >응답 모델</el-button
                  >
                  <el-button
                    :type="responseMappingButtonType"
                    plain
                    size="small"
                    class="el-dropdown__caret-button"
                    icon="el-dropdown__icon el-icon-arrow-down"
                  ></el-button>
                </el-button-group>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    icon="el-icon-view"
                    v-show="resource.interfaceMeta.responseMappingYn == 'Y'"
                    command="showConsumerResponseModel"
                    >자세히 보기</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="
                      showInclude == true &&
                      resource.interfaceMeta.responseMappingYn == 'Y'
                    "
                    icon="el-icon-search"
                    v-show="isDataModel(resource.responseModel) == false"
                    command="searchConsumerResponseModel"
                    >응답 모델 검색 ( 공통 )</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="
                      showInclude == true &&
                      resource.interfaceMeta.responseMappingYn == 'Y'
                    "
                    v-show="isDataModel(resource.responseModel) == true"
                    icon="el-icon-search"
                    command="clearConsumerResponseModel"
                    >응답 모델 참조 삭제</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-minus"
                    v-show="resource.interfaceMeta.responseMappingYn == 'Y'"
                    command="clearConsumerResponseMapping"
                    >응답 맵핑 삭제</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-plus"
                    v-show="resource.interfaceMeta.responseMappingYn != 'Y'"
                    command="setConsumerResponseMapping"
                    >응답 맵핑 추가</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-badge>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="8">
      <el-card class="mx-2" shadow="never">
        <div slot="header" class="text-center">
          <h5 class="font-weight-bolder">Interface</h5>
        </div>
        <div class="text-center rounded-pill border shadow-sm m-3 p-3">
          <div class="text-center px-5 pt-4">
            <el-badge
              :value="requestMappingErrorsTitle"
              :type="requestMappingErrorsType"
              :hidden="requestMappingErrors.length == 0"
              class="item"
            >
              <el-dropdown @command="handelCommand">
                <el-button-group>
                  <el-button
                    :type="requestMappingButtonType"
                    style="width: 200px"
                    plain
                    size="small"
                    @click="handelCommand('showConsumerRequestMapping')"
                    >요청 맵핑</el-button
                  >
                  <el-button
                    :type="requestMappingButtonType"
                    plain
                    size="small"
                    class="el-dropdown__caret-button"
                    icon="el-dropdown__icon el-icon-arrow-down"
                  ></el-button>
                </el-button-group>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    icon="el-icon-view"
                    v-show="resource.interfaceMeta.requestMappingYn == 'Y'"
                    command="showConsumerRequestMapping"
                    >자세히 보기</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-minus"
                    v-show="resource.interfaceMeta.requestMappingYn == 'Y'"
                    command="clearConsumerRequestMapping"
                    >요청 맵핑 삭제</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-plus"
                    v-show="resource.interfaceMeta.requestMappingYn != 'Y'"
                    command="setConsumerRequestMapping"
                    >요청 맵핑 추가</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-badge>
          </div>
          <div class="py-3">
            <p class="titleFont my-0">
              &nbsp;{{
                resource.serverMeta.name + " ( " + resource.serverMeta.id + " )"
              }}
            </p>
            <p class="titleFont my-0">
              &nbsp;{{
                resource.bizMeta.name + " ( " + resource.bizMeta.id + " )"
              }}
            </p>
            <p class="titleFont my-0">
              &nbsp;{{
                resource.interfaceMeta.class1 | formatCode(codeInterfaceClass)
              }}
            </p>
          </div>
          <div class="text-center px-5 pb-4">
            <el-badge
              :value="responseMappingErrorsTitle"
              :type="responseMappingErrorsType"
              :hidden="responseMappingErrors.length == 0"
              class="item"
            >
              <el-dropdown @command="handelCommand">
                <el-button-group>
                  <el-button
                    :type="responseMappingButtonType"
                    style="width: 200px"
                    plain
                    size="small"
                    @click="handelCommand('showConsumerResponseMapping')"
                    >응답 맵핑</el-button
                  >
                  <el-button
                    :type="responseMappingButtonType"
                    plain
                    size="small"
                    class="el-dropdown__caret-button"
                    icon="el-dropdown__icon el-icon-arrow-down"
                  ></el-button>
                </el-button-group>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-show="resource.interfaceMeta.responseMappingYn == 'Y'"
                    icon="el-icon-view"
                    command="showConsumerResponseMapping"
                    >자세히 보기</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-show="resource.interfaceMeta.responseMappingYn == 'Y'"
                    icon="el-icon-minus"
                    command="clearConsumerResponseMapping"
                    >응답 맵핑 삭제</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-show="resource.interfaceMeta.responseMappingYn == 'N'"
                    icon="el-icon-plus"
                    command="setConsumerResponseMapping"
                    >응답 맵핑 추가</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-badge>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="8">
      <el-card class shadow="never">
        <div slot="header" class="text-center">
          <h5 class="font-weight-bold">Target 정보 (TO)</h5>
        </div>
        <div class="text-center rounded-pill border shadow-sm m-3 p-3">
          <div class="text-center px-5 pt-4">
            <el-badge
              :value="targetRequestModelErrorsTitle"
              :type="targetRequestModelErrorsType"
              :hidden="targetRequestModelErrors.length == 0"
              class="item"
            >
              <el-dropdown type="primary" @command="handelCommand">
                <el-button-group>
                  <el-button
                    type="primary"
                    style="width: 200px"
                    plain
                    size="small"
                    @click="handelCommand('showProviderRequestModel')"
                    >요청 모델</el-button
                  >
                  <el-button
                    type="primary"
                    plain
                    size="small"
                    class="el-dropdown__caret-button"
                    icon="el-dropdown__icon el-icon-arrow-down"
                  ></el-button>
                </el-button-group>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    icon="el-icon-view"
                    command="showProviderRequestModel"
                    >자세히 보기</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="showInclude == true"
                    icon="el-icon-search"
                    v-show="
                      isDataModel(resource.serviceResource.requestModel) ==
                      false
                    "
                    command="searchProviderRequestModel"
                    >요청 모델 검색 ( 공통 )</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="showInclude == true"
                    v-show="
                      isDataModel(resource.serviceResource.requestModel) == true
                    "
                    icon="el-icon-minus"
                    command="clearProviderRequestModel"
                    >요청 모델 참조 삭제</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-back"
                    command="pasteProviderRequestModel"
                    >Source (FROM) 요청모델로 복사</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-badge>
          </div>
          <div class="py-3" v-if="resource.serviceResource">
            <p class="titleFont my-0">
              &nbsp;{{
                resource.serviceResource.systemMeta.name +
                " ( " +
                resource.serviceResource.systemMeta.id +
                " )"
              }}
            </p>
            <p class="titleFont my-0">
              &nbsp;{{ resource.serviceResource.serviceMeta.groups }}
            </p>
            <p class="titleFont my-0">
              &nbsp;{{
                resource.serviceResource.serviceMeta.type1
                  | formatCode(codeProviderType)
              }}
            </p>
          </div>
          <div class="text-center px-5 pb-4">
            <el-badge
              :value="targetResponseModelErrorsTitle"
              :type="targetResponseModelErrorsType"
              :hidden="targetResponseModelErrors.length == 0"
              class="item"
            >
              <el-dropdown
                style="width: 100%"
                size="small"
                @command="handelCommand"
              >
                <el-button-group>
                  <el-button
                    type="primary"
                    style="width: 200px"
                    plain
                    size="small"
                    @click="handelCommand('showProviderResponseModel')"
                    >응답 모델</el-button
                  >
                  <el-button
                    type="primary"
                    plain
                    size="small"
                    class="el-dropdown__caret-button"
                    icon="el-dropdown__icon el-icon-arrow-down"
                  ></el-button>
                </el-button-group>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    icon="el-icon-view"
                    command="showProviderResponseModel"
                    >자세히 보기</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-search"
                    v-if="showInclude == true"
                    v-show="
                      isDataModel(resource.serviceResource.responseModel) ==
                      false
                    "
                    command="searchProviderResponseModel"
                    >요청 모델 검색 ( 공통 )</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="showInclude == true"
                    v-show="
                      isDataModel(resource.serviceResource.responseModel) ==
                      true
                    "
                    icon="el-icon-minus"
                    command="clearProviderResponseModel"
                    >요청 모델 참조 삭제</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-back"
                    command="pasteProviderResponseModel"
                    >Source (FROM) 응답 모델로 복사</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-badge>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "em-interface-summary",
  props: {
    showInclude: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object,
    },
    codeInterfaceClass: {
      type: Array,
    },
    codeConsumerType: {
      type: Array,
    },
    codeProviderType: {
      type: Array,
    },
    consumerExtendProperties: {
      type: Array,
    },
    providerExtendProperties: {
      type: Array,
    },
    ierrors: {
      type: Array,
    },
  },
  computed: {
    requestMappingButtonType: function () {
      return this.resource.interfaceMeta.requestMappingYn == "Y"
        ? "primary"
        : "info";
    },
    responseMappingButtonType: function () {
      return this.resource.interfaceMeta.responseMappingYn == "Y"
        ? "primary"
        : "info";
    },
    sourceRequestModelErrorsType: function () {
      var errorE = _.filter(this.sourceRequestModelErrors, { errorLevel: "E" });
      if (errorE.length > 0) {
        return "danger";
      }
      return "warning";
    },
    sourceRequestModelErrorsTitle: function () {
      var title = "";
      var errorE = _.filter(this.sourceRequestModelErrors, { errorLevel: "E" });
      if (errorE.length > 0) {
        title = "오류 : " + errorE.length;
      }
      var errorW = _.filter(this.sourceRequestModelErrors, { errorLevel: "W" });
      if (errorW.length > 0) {
        title = title + "경고 : " + errorW.length;
      }
      return title;
    },
    sourceRequestModelErrors: function () {
      return _.filter(this.ierrors, {
        resourceId: this.resource.interfaceMeta.requestModelId,
      });
    },
    sourceResponseModelErrorsType: function () {
      var errorE = _.filter(this.sourceResponseModelErrors, {
        errorLevel: "E",
      });
      if (errorE.length > 0) {
        return "danger";
      }
      return "warning";
    },
    sourceResponseModelErrorsTitle: function () {
      var title = "";
      var errorE = _.filter(this.sourceResponseModelErrors, {
        errorLevel: "E",
      });
      if (errorE.length > 0) {
        title = "오류 : " + errorE.length;
      }
      var errorW = _.filter(this.sourceResponseModelErrors, {
        errorLevel: "W",
      });
      if (errorW.length > 0) {
        title = title + "경고 : " + errorW.length;
      }
      return title;
    },
    sourceResponseModelErrors: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.
      return _.filter(this.ierrors, {
        resourceId: this.resource.interfaceMeta.responseModelId,
      });
    },
    requestMappingErrorsType: function () {
      var errorE = _.filter(this.requestMappingErrors, { errorLevel: "E" });
      if (errorE.length > 0) {
        return "danger";
      }
      return "warning";
    },
    requestMappingErrorsTitle: function () {
      var title = "";
      var errorE = _.filter(this.requestMappingErrors, { errorLevel: "E" });
      if (errorE.length > 0) {
        title = "오류 : " + errorE.length;
      }
      var errorW = _.filter(this.requestMappingErrors, { errorLevel: "W" });
      if (errorW.length > 0) {
        title = title + "경고 : " + errorW.length;
      }
      return title;
    },
    requestMappingErrors: function () {
      var requestMappingErrors = _.filter(this.ierrors, {
        resourceId: this.resource.interfaceMeta.requestMappingId,
      });
      //console.log("requestMappingErrors",requestMappingErrors);
      return requestMappingErrors;
    },
    responseMappingErrorsType: function () {
      var errorE = _.filter(this.responseMappingErrors, { errorLevel: "E" });
      if (errorE.length > 0) {
        return "danger";
      }
      return "warning";
    },
    responseMappingErrorsTitle: function () {
      var title = "";
      var errorE = _.filter(this.responseMappingErrors, { errorLevel: "E" });
      if (errorE.length > 0) {
        title = "오류 : " + errorE.length;
      }
      var errorW = _.filter(this.responseMappingErrors, { errorLevel: "W" });
      if (errorW.length > 0) {
        title = title + "경고 : " + errorW.length;
      }
      return title;
    },
    responseMappingErrors: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.
      return _.filter(this.ierrors, {
        resourceId: this.resource.interfaceMeta.responseMappingId,
      });
    },
    targetRequestModelErrorsType: function () {
      var errorE = _.filter(this.targetRequestModelErrors, { errorLevel: "E" });
      if (errorE.length > 0) {
        return "danger";
      }
      return "warning";
    },
    targetRequestModelErrorsTitle: function () {
      var title = "";
      var errorE = _.filter(this.targetRequestModelErrors, { errorLevel: "E" });
      if (errorE.length > 0) {
        title = "오류 : " + errorE.length;
      }
      var errorW = _.filter(this.targetRequestModelErrors, { errorLevel: "W" });
      if (errorW.length > 0) {
        title = title + "경고 : " + errorW.length;
      }
      return title;
    },
    targetRequestModelErrors: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.
      return _.filter(this.ierrors, {
        resourceId: this.resource.serviceResource.serviceMeta.requestModelId,
      });
    },
    targetResponseModelErrorsType: function () {
      var errorE = _.filter(this.targetResponseModelErrors, {
        errorLevel: "E",
      });
      if (errorE.length > 0) {
        return "danger";
      }
      return "warning";
    },
    targetResponseModelErrorsTitle: function () {
      var title = "";
      var errorE = _.filter(this.targetResponseModelErrors, {
        errorLevel: "E",
      });
      if (errorE.length > 0) {
        title = "오류 : " + errorE.length;
      }
      var errorW = _.filter(this.targetResponseModelErrors, {
        errorLevel: "W",
      });
      if (errorW.length > 0) {
        title = title + "경고 : " + errorW.length;
      }
      return title;
    },
    targetResponseModelErrors: function () {
      return _.filter(this.ierrors, {
        resourceId: this.resource.serviceResource.serviceMeta.responseModelId,
      });
    },
  },
  methods: {
    handelCommand: function (command) {
      //console.log("handelCommand", command);
      this.$emit("command", command);
    },
    isDataModel: function (model) {
      return model && model.modelMeta && model.modelMeta.modelType == "D";
    },
  },
};
</script>

<style></style>
