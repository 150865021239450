<template>
  <tr class="in-model-tr-cell" readonly>
    <td
      valign="middle"
      class="p-0"
      style="position: relative"
      :style="indicateStyle()"
    >
      <div style="width: 40px">
        <label class="em-model-form-control-cell m-0" v-text="item.tid"></label>
      </div>
    </td>
    <td
      class="p-0 in-model-td-cell"
      v-for="(c, col) in $parent.mfields"
      :key="col"
    >
      <template v-if="col == 0">
        <div
          class="row no-gutters"
          style="height: 27px; float: left; flex-wrap: nowrap"
        >
          <div
            class="col border-right"
            v-for="n in item.depth"
            :key="n"
            style="width: 10px; max-width: 10px"
          ></div>
          <div
            class="col border-right1 text-center"
            v-if="item.children.length > 0"
            style="width: 12px; max-width: 12px"
          >
            <i class="fa fa-caret-down" style="margin-top: 6px"></i>
          </div>
          <div
            class="col border-right1 text-center"
            v-if="item.children.length == 0"
            style="width: 12px; max-width: 12px"
          ></div>
          <div class="col">
            <label
              class="form-control-xs form-control in-table-form-control-cell in-table-label-cell m-0"
              v-text="$parent.getColLabel(item, col)"
            ></label>
          </div>
        </div>
      </template>
      <template v-else>
        <label
          class="form-control-xs form-control in-table-form-control-cell in-table-label-cell m-0"
          tabindex="0"
          v-text="$parent.getColLabel(item, col)"
        ></label>
      </template>
    </td>
  </tr>
</template>

<script>
export default {
  name: "em-model-row-include",
  watch: {},
  computed: {},
  props: {
    item: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    indicateStyle: function() {
      if (this.item.row.select) {
        return { "background-color": "#daeeff" };
      } else {
        //console.log( this.item.children );
        if (this.item.children.length > 0) {
          return { "background-color": "#eeeeee" };
        } else {
          return { "background-color": "#eeeeee" };
        }
      }
    },
    idlabel: function(col) {
      return this.$parent.id + "_label_" + this.item.rowNo + "_" + col;
    },
    idcell: function(col) {
      return this.$parent.id + "_cell_" + this.item.rowNo + "_" + col;
    }
  },
  updated: function() {
    //   console.log("updated row" , this.item.data.key);
  }
};
</script>

<style scoped></style>
