<template>
  <div>
    <em-mapping-menu ref="topMenu"></em-mapping-menu>
    <em-mapping-info ref="mappingInfo"></em-mapping-info>
    <em-mapping-head ref="mappingHead"></em-mapping-head>
    <div :style="'height:'+height">
      <div class="row no-gutters">
        <div class="col-4">
          <em-mapping-model
            ref="targetModel"
            :id="id+'_request'"
            :items="targetItems"
            :min-rows="10"
            @select-row="setSelectTargetRow"
            @@dropitem="dropItem"
          ></em-mapping-model>
        </div>
        <div class="col-4 border">
          <div style="left:50px;right:50px;top:5px;position:absolute;opacity: 0.7;">
            <el-alert
              class="my-0"
              title="Click 맵핑"
              v-if="!drag&&(selectedTargetPoint||selectedSourcePoint)"
              type="warning"
              :closable="false"
              :center="true"
            ></el-alert>
            <el-alert
              class="my-0"
              title="Drag&Drop 맵핑"
              v-if="drag"
              type="warning"
              :closable="false"
              :center="true"
            ></el-alert>
          </div>
          <svg
            ref="mappingSvg"
            style="width:100%;height:100%"
            @click="clickSvg($event)"
            @mouseup="mouseUp($event)"
            @mousemove="mouseMove($event)"
            @mouseleave="mouseLeave($event)"
          >
            <template v-if="drag != undefined">
              <em-mapping-svg-shape
                :shape="drag.svg.point"
                v-show="drag.svg.point.show==true"
                :item="drag.item"
              />
              <em-mapping-svg-shape
                :shape="drag.svg.path"
                v-show="drag.svg.path.show==true"
                :item="drag.item"
              />
              <template v-for="(path,pidx) in drag.svg.paths">
                <em-mapping-svg-shape
                  :shape="path"
                  v-show="path.show==true"
                  :item="drag.item"
                  :key="'dp_'+pidx"
                />
              </template>
            </template>
            <template v-for="(item) in targetPoints">
              <g
                class="in-mapping-dnd-point"
                @mouseenter="dragEnterTarget($event,item)"
                @mouseleave="dragLeaveTarget($event,item)"
                @click="clickTargetPoint($event,item)"
                :key="'tp_'+item.rowNo"
              >
                <em-mapping-svg-shape
                  :shape="item.svg.point"
                  :item="item"
                  @dragstartshape="dragStartTargetLink($event,item)"
                />
              </g>
            </template>
            <template v-for="(item) in sourcePoints">
              <g
                class="in-mapping-dnd-point"
                @mouseenter="dragEnterSource($event,item)"
                @mouseleave="dragLeaveSource($event,item)"
                @click="clickSourcePoint($event,item)"
                :key="'sp_'+item.rowNo"
              >
                <em-mapping-svg-shape
                  :shape="item.svg.point"
                  :item="item"
                  @dragstartshape="dragStartSourceLink($event,item)"
                />
              </g>
            </template>
            <template v-if="drag != undefined && drag.show == true">
              <em-mapping-svg-shape
                :shape="drag.svg.point"
                v-show="drag.svg.point.show==true"
                :item="drag.item"
              />
            </template>
            <template v-for="(item,idx) in items">
              <g
                class="in-mapping-graph"
                @click="clickMapping($event,item)"
                @mouseover="mouseoverMapping(item)"
                @mouseout="mouseoutMapping(item)"
                :selected="item.row.select==true"
                :hovered="item.row.hover==true"
                :data-tippy-content="item.data.indicateMsg"
                :key="'mg_'+idx"
              >
                <template v-if="item.svg && item.svg.target">
                  <em-mapping-svg-shape
                    :shape="item.svg.target.path"
                    v-if="item.svg.target.path.show"
                    :error="item.svg.target.error"
                    :item="item"
                  />
                  <em-mapping-svg-shape
                    :shape="item.svg.target.start"
                    v-if="item.svg.target.start.show"
                    :error="item.svg.target.error"
                    :item="item"
                    @dragstartshape="dragStartTarget($event,item)"
                  />
                  <em-mapping-svg-shape
                    :shape="item.svg.target.end"
                    v-if="item.svg.target.end.show"
                    :error="item.svg.target.error"
                    :item="item"
                  />
                </template>
                <template v-for="(source,sidx) in item.svg.sources">
                  <em-mapping-svg-shape
                    :shape="source.start"
                    v-if="source.start.show"
                    :error="source.error"
                    :item="item"
                    :key="'mgss_'+idx+'_'+sidx"
                  />
                  <em-mapping-svg-shape
                    :shape="source.path"
                    v-if="source.path.show"
                    :error="source.error"
                    :item="item"
                    :key="'mgsp_'+idx+'_'+sidx"
                  />
                  <em-mapping-svg-shape
                    :shape="source.end"
                    v-if="source.end.show"
                    :error="source.error"
                    :item="item"
                    @dragstartshape="dragStartSource($event,item)"
                    :key="'mgse_'+idx+'_'+sidx"
                  />
                </template>
              </g>
            </template>
          </svg>
        </div>
        <div class="col-4">
          <em-mapping-model
            ref="sourceModel"
            @select-row="setSelectSourceRow"
            :id="id+'_response'"
            :items="sourceItems"
            :min-rows="10"
          ></em-mapping-model>
        </div>
      </div>
    </div>
    <div class>
      <div class="row no-gutters">
        <div class="col-12">
          <el-card></el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

var MAPPING_SOURCETYPE_MODEL = "M";
var MAPPING_SHAPE_WIDTH = 10;
var MAPPING_SHAPE_MARGIN = 2;
var DUP_X_MAX_ = 10;



import {
  toTriplePath,
  toTriangle,
  toPath,
  getSourcePathModel,
  freqMax,
  count,
} from "../../utils/mapping";

import MappingHead from "./MappingHead.vue";
import MappingInfo from "./MappingInfo.vue";
import MappingMenu from "./MappingMenu.vue";
import MappingSvgShape from "./MappingSvgShape.vue";
import MappingModel from "../tree/Model/MappingModel.vue";

export default {
  name: "em-mapping",
  components: {
    "em-mapping-head": MappingHead,
    "em-mapping-info": MappingInfo,
    "em-mapping-menu": MappingMenu,
    "em-mapping-svg-shape": MappingSvgShape,
    "em-mapping-model": MappingModel,
  },
  props: {
    id: {
      type: String,
    },
    targetItems: {
      type: Array,
    },
    sourceItems: {
      type: Array,
    },
    mappingFields: {
      type: Array,
      default() {
        return [];
      },
    },
    height: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    minRows: {
      type: Number,
      default: 20,
    },
    mfields: {
      type: Array,
      default() {
        return [
          {
            key: "key",
            width: "200px",
            label: "ID",
            type: "text",
            required: true,
            unique: true,
          },
          {
            key: "type",
            width: "100px",
            label: "타입",
            type: "label",
            labelFunction: function (item) {
              //console.log("labelFunction", item );
              var text = item.data.fieldType;
              var sText = [];

              if (item.data.fieldLength != 0) {
                sText.push(item.data.fieldLength);
              }
              if (item.data.fieldScale != 0) {
                if (sText.length > 0) {
                  sText.push(",");
                }
                //console.log('['+item.data.fieldScale+']')
                sText.push(item.data.fieldScale);
              }
              if (sText.length > 0) {
                sText.unshift("(");
                sText.push(")");
              }
              text = text + _.join(sText, "");
              if (item.data.fieldType == "Group") {
                text = text + " ( " + item.data.arrayType + " ) ";
              }
              return text;
            },
          },
          {
            key: "name",
            width: "200px",
            label: "이름",
            type: "text",
            required: true,
          },
        ];
      },
    },
  },
  data: function () {
    var data = {
      items: [],
      //targetItems  : [],
      //sourceItems : [],
      mappingField: {},
      selectedItems: [],
      drag: undefined,
      drop: undefined,
      sourcePoints: [],
      targetPoints: [],
      selectedSourcePoint: undefined,
      selectedTargetPoint: undefined,
      svgHeight: 0,
      svgWidth: 0,
      svgSize: {},
    };
    return data;
  },
  watch: {
    //    target : function(newItems, oldItems)
    //    {
    ////      console.log("mapping watch target");
    //      this.targetItems = this.target;
    //      this.$nextTick( function() {
    //        _.debounce( this.refresh , 100 )
    //      });
    //    },
    //    source : function(newItems, oldItems)
    //    {
    ////      console.log("mapping watch source");
    //      this.sourceItems = this.source;
    //      this.$nextTick( function() {
    //        _.debounce( this.refresh , 100 )
    //      });
    //
    //    },
    mappingFields: function (newItems, oldItems) {
      //      console.log("mapping watch mappingFields");
      this.createDatas(this.mappingFields);
      // var length = this.mappingFields.length ;
      // for( var i = 0 ; i < length ; i++ )
      // {
      // if ( this.mappingFields[i].svg == undefined )
      // {
      // var svg = {path:'',start:'',end:'',sourceText:{x:'',y:''},over:false};
      // Vue.set( this.mappingFields[i] , 'svg' , svg );
      // Vue.set( this.mappingFields[i] , 'error' , false );
      // Vue.set( this.mappingFields[i] , 'selected' , false );
      // }
      // }
      //
      //console.log("this.$nextTick" , this);
      this.$nextTick(function () {
        _.debounce(this.refresh, 100);
      });
    },
  },
  computed: {},
  methods: {
    deactiveDropTarget: function () {
      for (var i = 0; i < this.targetPoints.length; i++) {
        Vue.set(this.targetPoints[i].row, "hover", false);
        Vue.set(this.targetPoints[i].row, "select", false);
        Vue.set(this.targetPoints[i].row, "recommend", false);
        Vue.set(this.targetPoints[i].row, "click", false);
      }
    },
    activeDropTarget: function (item) {
      var paths = [];
      //      console.log(item);

      for (var i = 0; i < this.targetPoints.length; i++) {
        Vue.set(this.targetPoints[i].row, "hover", true);

        if (
          item.data.name == this.targetPoints[i].item.data.name ||
          item.data.key == this.targetPoints[i].item.data.key
        ) {
          Vue.set(this.targetPoints[i].row, "recommend", true);
          var path = {
            type: "Path",
            fill: false,
            dash: true,
            show: true,
            ex: this.targetPoints[i].svg.point.cx,
            ey: this.targetPoints[i].svg.point.cy,
          };
          paths.push(path);
        }
      }

      return paths;
    },
    deactiveDropSource: function () {
      for (var i = 0; i < this.sourcePoints.length; i++) {
        Vue.set(this.sourcePoints[i].row, "hover", false);
        Vue.set(this.sourcePoints[i].row, "select", false);
        Vue.set(this.sourcePoints[i].row, "recommend", false);
        Vue.set(this.sourcePoints[i].row, "click", false);
      }
    },
    activeDropSource: function (item) {
      var paths = [];
      for (var i = 0; i < this.sourcePoints.length; i++) {
        Vue.set(this.sourcePoints[i].row, "hover", true);

        if (
          item.data.name == this.sourcePoints[i].item.data.name ||
          item.data.id == this.sourcePoints[i].item.data.id
        ) {
          Vue.set(this.sourcePoints[i].row, "select", true);
          var path = {
            type: "Path",
            fill: false,
            dash: true,
            show: true,
            ex: this.sourcePoints[i].svg.point.cx,
            ey: this.sourcePoints[i].svg.point.cy,
          };
          paths.push(path);
        }
      }

      return paths;
    },
    activeDropTargetPoint: function (item, type) {
      //console.log("activeDropTargetPoint");
      Vue.set(this.drag.svg.point, "show", false);
      var length = this.drag.svg.paths.length;
      for (var i = 0; i < length; i++) {
        Vue.set(this.drag.svg.paths[i], "show", false);
      }

      Vue.set(item.svg.point, "fill", true);
      Vue.set(item.row, "select", true);
      this.drop = { target: item };
    },
    deactiveDropTargetPoint: function (item, type) {
      //console.log("deactiveDropTargetPoint");
      Vue.set(this.drag.svg.point, "show", true);
      var length = this.drag.svg.paths.length;
      for (var i = 0; i < length; i++) {
        Vue.set(this.drag.svg.paths[i], "show", true);
      }

      Vue.set(item.svg.point, "fill", false);
      Vue.set(item.row, "select", false);

      this.drop = undefined;
    },
    activeDropSourcePoint: function (item, type) {
      Vue.set(this.drag.svg.point, "show", false);
      var length = this.drag.svg.paths.length;
      for (var i = 0; i < length; i++) {
        Vue.set(this.drag.svg.paths[i], "show", false);
      }

      Vue.set(item.svg.point, "fill", true);
      Vue.set(item.row, "select", true);
      this.drop = { target: item };
    },
    deactiveDropSourcePoint: function (item, type) {
      Vue.set(this.drag.svg.point, "show", true);
      var length = this.drag.svg.paths.length;
      for (var i = 0; i < length; i++) {
        Vue.set(this.drag.svg.paths[i], "show", true);
      }

      Vue.set(item.svg.point, "fill", false);
      Vue.set(item.row, "select", false);

      this.drop = undefined;
    },
    dragEnterTarget: function (e, item) {
      if (this.drag != undefined && this.drag.type == "Target") {
        this.activeDropTargetPoint(item);
      }
      if (this.drag != undefined && this.drag.type == "SourceLink") {
        this.activeDropTargetPoint(item);
      }
    },
    dragLeaveTarget: function (e, item) {
      if (this.drag != undefined && this.drag.type == "Target") {
        this.deactiveDropTargetPoint(item);
      }
      if (this.drag != undefined && this.drag.type == "SourceLink") {
        this.deactiveDropTargetPoint(item);
      }
    },
    dragEnterSource: function (e, item) {
      if (this.drag != undefined && this.drag.type == "Source") {
        this.activeDropTargetPoint(item);
      }
      if (this.drag != undefined && this.drag.type == "TargetLink") {
        this.activeDropTargetPoint(item);
      }
    },
    dragLeaveSource: function (e, item) {
      if (this.drag != undefined && this.drag.type == "Source") {
        this.deactiveDropTargetPoint(item);
      }
      if (this.drag != undefined && this.drag.type == "TargetLink") {
        this.deactiveDropTargetPoint(item);
      }
    },
    createDragPoint: function (type, source) {
      var drag = {};
      drag.item = { row: { select: false, hover: true } };
      drag.source = source;
      drag.type = type;
      drag.move = false;
      drag.show = false;
      drag.svg = {};
      drag.svg.point = {
        type: "Circle",
        fill: true,
        show: false,
        r: (MAPPING_SHAPE_WIDTH - MAPPING_SHAPE_MARGIN) / 2,
      };
      drag.svg.path = { type: "Path", fill: false, dash: true, show: false };
      drag.svg.paths = [];
      return drag;
    },
    moveDragPoint: function (sx, sy, cx, cy) {
      Vue.set(this.drag, "show", true);
      Vue.set(this.drag.svg.point, "show", true);
      Vue.set(this.drag.svg.point, "cx", cx);
      Vue.set(this.drag.svg.point, "cy", cy);

      var d = toTriplePath(sx, sy, cx, cy);
      Vue.set(this.drag.svg.path, "d", d);
      Vue.set(this.drag.svg.path, "show", true);

      var length = this.drag.svg.paths.length;
      //      console.log(length);
      for (var i = 0; i < length; i++) {
        var path = this.drag.svg.paths[i];
        //        console.log(path);
        var d = toTriplePath(cx, cy, path.ex, path.ey);
        Vue.set(path, "d", d);
        Vue.set(path, "show", true);
      }
    },
    dragStartTargetLink: function (e, targetPoint) {
      Vue.set(targetPoint.row, "hover", true);
      var drag = this.createDragPoint("TargetLink", targetPoint);
      drag.svg.paths = this.activeDropSource(targetPoint.item);
      //        console.log( drag );
      this.drag = drag;
    },
    dragStartSourceLink: function (e, sourcePoint) {
      Vue.set(sourcePoint.row, "hover", true);
      var drag = this.createDragPoint("SourceLink", sourcePoint);
      //        console.log("dragStartLink" , item);
      drag.svg.paths = this.activeDropTarget(sourcePoint.item);
      //        console.log( drag );
      this.drag = drag;
    },
    dragStartSource: function (e, item) {
      if (item.data.sourceType == "M") {
        var unitHeight =
          this.$refs.sourceModel.$refs.treeBody.clientHeight /
          this.$refs.sourceModel.$refs.treeBody.children.length;
        var drag = {};
        var drag = this.createDragPoint("Source", item);
        drag.unitHeight = unitHeight;

        if (item.targetItem != undefined) {
          drag.svg.paths = this.activeDropSource(item.targetItem);
        }

        this.drag = drag;
      }
    },
    dragStartTarget: function (e, item) {
      // console.log("dragstartshape dragStartTarget",e,item);
      var unitHeight =
        this.$refs.targetModel.$refs.treeBody.clientHeight /
        this.$refs.targetModel.$refs.treeBody.children.length;
      var drag = this.createDragPoint("Target", item);
      drag.unitHeight = unitHeight;

      var key = "";
      var name = "";
      if (
        item.data.sourceType == "M" &&
        item.sourceItems != undefined &&
        item.sourceItems.length > 0
      ) {
        key = item.sourceItems[0].data.key;
        name = item.sourceItems[0].data.name;
        drag.svg.paths = this.activeDropTarget(item.sourceItems[0]);
      }
      //      console.log( "dragStartTarget" ,drag);
      this.drag = drag;
    },
    mouseLeave: function () {
      if (this.drag != undefined) {
        // this.drag = undefined;
        // this.drop = undefined;
        // this.deactiveDropSource();
        // this.deactiveDropTarget();
      }
    },
    mouseMove: function (e) {
      if (this.drag != undefined) {
        var cx = e.offsetX - 5;
        var cy = e.offsetY - 5;

        if (this.drop != undefined) {
          // console.log(this.drop.item.svg.point.cx);
          sx = this.drop.target.svg.point.cx;
          sy = this.drop.target.svg.point.cy;
        }
        if (this.drag.type == "Target") {
          var sx = this.drag.source.svg.sources[0].ex;
          var sy = this.drag.source.svg.sources[0].ey;
          this.moveDragPoint(sx, sy, cx, cy);
        } else if (this.drag.type == "Source") {
          var sx = this.drag.source.svg.target.sx;
          var sy = this.drag.source.svg.target.sy;
          this.moveDragPoint(sx, sy, cx, cy);
        } else if (this.drag.type == "SourceLink") {
          var sx = this.drag.source.svg.point.cx;
          var sy = this.drag.source.svg.point.cy;
          this.moveDragPoint(sx, sy, cx, cy);
        } else if (this.drag.type == "TargetLink") {
          var sx = this.drag.source.svg.point.cx;
          var sy = this.drag.source.svg.point.cy;
          this.moveDragPoint(sx, sy, cx, cy);
        }
      }
    },
    mouseUp: function (e) {
      //console.log("mouseUp",this.drag,this.drop);
      if (this.drag != undefined && this.drop != undefined) {
        if (this.drag.type == "Target") {
          var path = this.$refs.targetModel.getPath(this.drop.target.item);
          Vue.set(this.drag.source.data, "targetPath", path);
          this.refreshDatas();
        } else if (this.drag.type == "Source") {
          var path = this.$refs.sourceModel.getPath(this.drop.target.item);
          Vue.set(this.drag.source.data, "sourceExpression", path);
          this.refreshDatas();
        } else if (this.drag.type == "SourceLink") {
          var sourcePath = this.$refs.sourceModel.getPath(
            this.drag.source.item
          );
          var targetPath = this.$refs.targetModel.getPath(
            this.drop.target.item
          );
          this.addMappingFieldSimple(targetPath, sourcePath);
          this.refreshDatas();
        } else if (this.drag.type == "TargetLink") {
          var targetPath = this.$refs.targetModel.getPath(
            this.drag.source.item
          );
          var sourcePath = this.$refs.sourceModel.getPath(
            this.drop.target.item
          );
          this.addMappingFieldSimple(targetPath, sourcePath);
          this.refreshDatas();
        }
      }
      this.drag = undefined;
      this.drop = undefined;
      this.deactiveDropSource();
      this.deactiveDropTarget();
    },
    movePoint: function (e) {},
    clickTargetPoint: function (e, targetPoint) {
      e.stopPropagation();

      if (this.selectedSourcePoint == undefined) {
        this.deactiveDropSource();
        this.deactiveDropTarget();
        this.selectedTargetPoint = targetPoint;
        //console.log( "clickTargetPoint" , targetPoint );
        Vue.set(targetPoint.row, "click", true);

        this.activeDropSource(targetPoint.item);
      } else {
        var sourcePath = this.$refs.sourceModel.getPath(
          this.selectedSourcePoint.item
        );
        var targetPath = this.$refs.targetModel.getPath(targetPoint.item);
        this.addMappingFieldSimple(targetPath, sourcePath);
        this.refreshDatas();
        this.selectedTargetPoint = undefined;
        this.selectedSourcePoint = undefined;
      }
    },
    clickSourcePoint: function (e, sourcePoint) {
      //console.log("clickSourcePoint");
      e.stopPropagation();

      if (this.selectedTargetPoint == undefined) {
        this.deactiveDropSource();
        this.deactiveDropTarget();
        this.selectedSourcePoint = sourcePoint;

        //console.log( "clickTargetPoint" , targetPoint );
        Vue.set(sourcePoint.row, "click", true);

        this.activeDropTarget(sourcePoint.item);
      } else {
        var sourcePath = this.$refs.sourceModel.getPath(sourcePoint.item);
        var targetPath = this.$refs.targetModel.getPath(
          this.selectedTargetPoint.item
        );
        this.addMappingFieldSimple(targetPath, sourcePath);
        this.refreshDatas();
        this.selectedTargetPoint = undefined;
        this.selectedSourcePoint = undefined;
      }
    },
    refresh: function () {
      var s = new Date();
      //      console.log("mapping refresh" , new Date());
      //      console.log("mapping refresh" ,this.target.length ,this.targetItems.length);
      //      this.targetItems = this.target;
      //      this.sourceItems = this.source;
      this.resizeSvg();
      this.createMappingData();
      this.createDndData();
      this.refreshMapping();
      this.refreshDnd();
      var e = new Date();
      //      console.log("mapping refresh" , e.getTime() - s.getTime());
    },

    refreshDnd: function () {
      //      console.log("mapping refreshDnd");

      var clientHeight = this.$refs.mappingSvg.clientHeight;
      var clientWidth = this.$refs.mappingSvg.clientWidth;

      var length = this.sourcePoints.length;
      var dropWidth = MAPPING_SHAPE_WIDTH + MAPPING_SHAPE_MARGIN;
      var r = dropWidth * 0.55;
      var dragX = clientWidth - (MAPPING_SHAPE_MARGIN + r);
      for (var i = 0; i < length; i++) {
        var sourcePoint = this.sourcePoints[i];
        var point = {
          type: "Circle",
          fill: false,
          r: r,
          cy: sourcePoint.y,
          cx: dragX,
        };
        Vue.set(sourcePoint.svg, "point", point);
      }

      var length = this.targetPoints.length;
      var dropWidth = MAPPING_SHAPE_WIDTH + MAPPING_SHAPE_MARGIN;
      var r = dropWidth * 0.55;
      var dragX = MAPPING_SHAPE_MARGIN + r;
      for (var i = 0; i < length; i++) {
        var targetPoint = this.targetPoints[i];
        var point = {
          type: "Circle",
          fill: false,
          r: r,
          cy: targetPoint.y,
          cx: dragX,
        };
        Vue.set(targetPoint.svg, "point", point);
      }
    },
    refreshMapping: function () {
      //      console.log("mapping refreshMapping");

      var shapeSize = this.svgSize.shapeSize;
      var margin = this.svgSize.margin;

      var startX = this.svgSize.startX;
      var startMoreX = this.svgSize.startMoreX;
      var midLessX = this.svgSize.midLessX;
      var midX = this.svgSize.midX;
      var midMoreX = this.svgSize.midMoreX;
      var endLessX = this.svgSize.endLessX;
      var endX = this.svgSize.endX;

      var length = this.items.length;

      for (var i = 0; i < length; i++) {
        var item = this.items[i];
        var sourceType = item.data.sourceType;
        var target = item.svg.target;
        var sources = item.svg.sources;
        var targetItem = item.targetItem;
        //        console.log("refreshMapping item",item.svg);

        // 1. Target Y X 구하기
        {
          if (targetItem == undefined) {
            target.sx = startMoreX;
            target.sy = target.errorIdx * (shapeSize + margin);
          } else {
            var targetTd = this.$refs.targetModel.$refs.treeBody.children[
              targetItem.rowNo
            ];
            target.sx =
              startX +
              Math.min(target.depth, DUP_X_MAX_) * (shapeSize + margin);
            target.sy = targetTd.offsetTop + targetTd.offsetHeight / 2;
          }
        }
        // 2. Source Y X 구하기
        var minSourceY = 100000;
        var minSourceX = 100000;
        var sourceLength = sources.length;
        {
          for (var j = 0; j < sourceLength; j++) {
            var source = sources[j];
            //            console.log("source",j,source);
            if (sourceType == "M") {
              if (source.item == undefined) {
                source.ex = endLessX;
                source.ey = target.sy;
              } else {
                var sourceTd = this.$refs.sourceModel.$refs.treeBody.children[
                  source.item.rowNo
                ];
                source.ex =
                  endX -
                  Math.min(source.depth, DUP_X_MAX_) * (shapeSize + margin);
                source.ey = sourceTd.offsetTop + sourceTd.offsetHeight / 2;
              }
            } else {
              if (source.item == undefined) {
                source.ex = midMoreX;
                source.ey = target.sy;
              } else {
                var sourceTd = this.$refs.sourceModel.$refs.treeBody.children[
                  source.item.rowNo
                ];
                source.ex =
                  endX -
                  Math.min(source.depth, DUP_X_MAX_) * (shapeSize + margin);
                source.ey = sourceTd.offsetTop + sourceTd.offsetHeight / 2;
              }
            }

            minSourceX = Math.min(minSourceX, source.ex);
            minSourceY = Math.min(minSourceY, source.ey);
          }
        }
        // 3. Center X Y 구하기
        var centerInfo = {};
        {
          centerInfo.y = (target.sy + minSourceY) / 2;
          if (sourceType == "M") {
            centerInfo.x = (target.sx + minSourceX) / 2;
          } else {
            centerInfo.x = midX;
          }
        }

        /**
         * TARGET
         */
        {
          var sx = target.sx;
          var sy = target.sy;
          var ex = centerInfo.x;
          var ey = centerInfo.y;

          if (target.start.type == "Rect") {
            var x = sx - target.start.width;
            var y = sy - target.start.width / 2;
            Vue.set(target.start, "x", x);
            Vue.set(target.start, "y", y);
          } else if (target.start.type == "Triangle") {
            var points = toTriangle(sx, sy, shapeSize);
            Vue.set(target.start, "points", points);
          }

          if (target.end.type == "Rect") {
            var x = ex - target.end.width / 2;
            var y = ey - target.end.width / 2;

            Vue.set(target.end, "x", x);
            Vue.set(target.end, "y", y);
          }

          {
            Vue.set(target.path, "d", toPath(sx, sy, ex, ey));
          }
        }

        for (var j = 0; j < sourceLength; j++) {
          var source = sources[j];
          //          console.log(sourceInfo);

          var sx = centerInfo.x;
          var sy = centerInfo.y;
          var ex = source.ex;
          var ey = source.ey;

          if (source.start.type == "Rect") {
            var x = sx - source.start.width / 2;
            var y = sy - source.start.width / 2;

            Vue.set(source.start, "x", x);
            Vue.set(source.start, "y", y);
          }

          // setShapeCenter(sourceSvg.end , ex , ey , size );
          if (source.end.type == "Rect") {
            var x = ex - source.end.width;
            var y = ey - source.end.width / 2;

            Vue.set(source.end, "x", x);
            Vue.set(source.end, "y", y);
          } else if (source.end.type == "Circle") {
            var cx = ex;
            var cy = ey;

            Vue.set(source.end, "cx", cx);
            Vue.set(source.end, "cy", cy);
          }

          //          console.log("source.end.type",source.end.type , source.end);

          // var end = toPolygon( [ [ sourceX-twidth , sourceY+(twidth/2) ]
          // , [ sourceX-twidth , sourceY-(twidth/2) ]
          // , [ sourceX , sourceY-(twidth/2) ]
          // , [ sourceX , sourceY+(twidth/2) ] ] );

          Vue.set(source.path, "d", toPath(ex, ey, sx, sy));
        }
      }

      //this.$nextTick(function () {
      //  var ss = tippy("[data-tippy-content]");
      //  //console.log( "tippy('[data-mapping-error]')" ,ss);
      //});
      // this.groups.push( group );
    },
    createDatas: function () {
      //      console.log("mapping createDatas");
      var rowNo = 0;
      var items = [];

      for (var i = 0; i < this.mappingFields.length; i++) {
        var item = {};
        item.data = this.mappingFields[i];
        item.rowNo = rowNo;
        item.row = { select: false, readOnly: false, error: false };
        item.svg = {};
        item.errors = [];
        items.push(item);
        rowNo++;
      }

      this.items = items;
      //console.log("this.$nextTick" , this);
      this.$nextTick(function () {
        this.createMappingData();
        this.createDndData();
      });
    },
    refreshDatas: function () {
      //console.log("mapping refreshDatas" , new Date());
      this.createMappingData();
      this.createDndData();
      this.$emit("changeitem");
      //console.log("mapping refreshDatas end" , new Date());
    },
    createMappingData: function () {
      //      console.log("mapping createMappingData",this.svgSize);
      var shapeSize = this.svgSize.shapeSize;
      var margin = this.svgSize.margin;

      var length = this.items.length;
      var totalSourceItems = [];
      var totalTargetItems = [];
      var svgTemp = {
        start: { show: true },
        end: { show: true },
        path: { show: true, type: "Path" },
      };

      for (var i = 0; i < length; i++) {
        var errors = [];
        var item = this.items[i];
        var sourceType = item.data.sourceType;
        var svg = { target: _.cloneDeep(svgTemp), sources: [] };
        var sourceItems = [];
        var targetItem = this.$refs.targetModel.getItem(item.data.targetPath);
        var errorIdx = 1;
        // 1. TARGET Error 확인
        //console.log("mapping field ",item.data.indicate , item.data);

        if (targetItem == undefined) {
          errors.push({
            message:
              "타켓 필드 [" +
              item.data.targetPath +
              "] 가 잘못 된 매핍입니다. [해당 필드을 찾을수 없습니다.]",
            item: item,
          });

          svg.target.error = true;
          svg.target.errorIdx = errorIdx++;

          svg.target.start.type = "Rect";
          svg.target.start.fill = true;
          svg.target.start.width = shapeSize;
          svg.target.start.height = shapeSize;
        } else {
          totalTargetItems.push(targetItem);
          svg.target.depth = count(totalTargetItems, targetItem) - 1;

          svg.target.start.type = "Triangle";
          svg.target.start.fill = true;

          if (targetItem.data.fieldType == "Group") {
            svg.target.error = true;
            errors.push({
              message:
                "타켓 필드 [" +
                item.data.targetPath +
                "] 가 잘못 된 매핍입니다. [Group Type 에 맵핑 할 수 없습니다.]",
              item: item,
            });
          }
        }

        // 2. Source Error 확인
        {
          if (sourceType == "M") {
            var source = _.cloneDeep(svgTemp);

            source.start.show = false;
            svg.target.end.show = false;

            source.end.type = "Rect";
            source.end.fill = true;
            source.end.width = shapeSize;
            source.end.height = shapeSize;

            var sourceItem = this.$refs.sourceModel.getItem(
              item.data.sourceExpression
            );
            if (sourceItem == undefined) {
              source.error = true;
              errors.push({
                message:
                  "소스 필드 [" +
                  item.data.sourceExpression +
                  "] 가 잘못 된 매핍입니다. [해당 필드을 찾을수 없습니다.]",
                item: item,
              });
            } else {
              totalSourceItems.push(sourceItem);
              sourceItems.push(sourceItem);

              source.depth = count(totalSourceItems, sourceItem) - 1;

              source.item = sourceItem;
              if (sourceItem.data.fieldType == "Group") {
                source.error = true;
                errors.push({
                  message:
                    "소스 필드 [" +
                    item.data.sourceExpression +
                    "] 가 잘못 된 매핍입니다. [Group Type 에 맵핑 할 수 없습니다.]",
                  item: item,
                });
              }
            }

            svg.sources.push(source);
          } else if (sourceType == "L") {
            var source = _.cloneDeep(svgTemp);

            source.start.show = true;

            svg.target.end.show = true;
            svg.target.end.type = "Rect";
            svg.target.end.fill = false;
            svg.target.end.width = shapeSize;
            svg.target.end.height = shapeSize;

            source.start.type = "Rect";
            source.start.fill = true;
            source.start.width = shapeSize - margin;
            source.start.height = shapeSize - margin;

            source.end.type = "Circle";
            source.end.fill = false;
            source.end.r = shapeSize / 2;

            source.path.dash = true;

            svg.sources.push(source);
          } 
        }

        if (item.data.indicate != undefined) {
          svg.target.error = true;
          for (var i1 = 0; i1 < svg.sources.length; i1++) {
            svg.sources[i1].error = true;
          }
        }
        Vue.set(item, "sourceItems", sourceItems);
        Vue.set(item, "targetItem", targetItem);
        Vue.set(item, "errors", errors);
        Vue.set(item, "svg", svg);
      }

      this.refreshMapping();
    },
    createDndData: function () {
      //      console.log("mapping createMappingData");
      var targetPoints = [];

      var size = this.$refs.targetModel.getRowSize();
      for (var i = 0; i < size; i++) {
        var item = this.$refs.targetModel.getRow(i);
        if (item.data.fieldType == "Group") {
          continue;
        }
        var td = this.$refs.targetModel.$refs.treeBody.children[i];
        var y = td.offsetTop + td.offsetHeight / 2;
        var dropPoint = {};
        dropPoint.item = item;
        dropPoint.rowNo = i;
        dropPoint.row = { select: false, readOnly: false, error: false };
        dropPoint.y = y;
        dropPoint.svg = {};

        targetPoints.push(dropPoint);
      }

      this.targetPoints = targetPoints;

      var sourcePoints = [];
      var size = this.$refs.sourceModel.getRowSize();
      for (var i = 0; i < size; i++) {
        var item = this.$refs.sourceModel.getRow(i);
        if (item.data.fieldType == "Group") {
          continue;
        }
        var sourceTd = this.$refs.sourceModel.$refs.treeBody.children[i];
        var y = sourceTd.offsetTop + sourceTd.offsetHeight / 2;
        var dragPoint = {};
        dragPoint.item = item;
        dragPoint.rowNo = i;
        dragPoint.row = { select: false, readOnly: false, error: false };
        dragPoint.y = y;
        dragPoint.svg = {};

        sourcePoints.push(dragPoint);
      }
      this.sourcePoints = sourcePoints;

      this.refreshDnd();
    },
    addMappingField: function (mappingField) {
      var item = {};
      item.data = mappingField;
      item.rowNo = this.items.length;
      item.row = { select: false, readOnly: false, error: false };
      item.svg = {};

      this.items.push(item);
      this.refreshDatas();
    },
    addSelect: function (item) {
      _.remove(this.selectedItems, item);
      this.selectedItems.push(item);
    },
    removeSelect: function (item) {
      _.remove(this.selectedItems, item);
    },
    removeAllSelect: function () {
      this.selectedItems = [];
    },
    clearSelect: function () {
      for (var i = 0; i < this.selectedItems.length; i++) {
        var item = this.selectedItems[i];
        Vue.set(item.row, "select", false);
      }

      this.removeAllSelect();
    },
    setSelect: function (item) {
      this.addSelect(item);
      Vue.set(item.row, "select", true);
      // this.$refs.sourceModel.setSelectRow(item.sourceItem);
      // this.$refs.targetModel.setSelectRow(item.targetItem);
    },
    setUnSelect: function (item) {
      this.removeSelect(item);
      Vue.set(item.row, "select", false);
    },
    setSelectTargetRow: function (targetItem) {
      //      console.log( "setSelectTargetRow" , targetItem );
      this.clearSelect();
      this.$refs.sourceModel.clearSelect();

      var length = this.items.length;

      for (var i = 0; i < length; i++) {
        var item = this.items[i];
        if (item.targetItem == targetItem) {
          this.setSelect(item);
          if (item.sourceItem != undefined) {
            this.$refs.sourceModel.setSelectRow(item.sourceItem, false);
          }
        }
      }
    },
    setSelectSourceRow: function (sourceItem) {
      //      console.log( "setSelectSourceRow" , sourceItem );
      this.clearSelect();
      this.$refs.targetModel.clearSelect();

      var length = this.items.length;

      for (var i = 0; i < length; i++) {
        var item = this.items[i];
        if (_.indexOf(item.sourceItems, sourceItem) >= 0) {
          this.setSelect(item);
          if (item.targetItem != undefined) {
            this.$refs.targetModel.setSelectRow(item.targetItem, false);
          }
        }
      }
    },
    addMappingFieldSimple: function (targetPath, sourcePath) {
      //      console.log("addMappingField" , targetPath , sourcePath );

      var mappingField = {
        targetPath: targetPath,
        sourceType: "M",
        sourceExpression: sourcePath,
        sourceSize: "",
        sourceProperty: sourcePath,
      };
      this.addMappingField(mappingField);
    },
    dropItem: function (target, source) {
      //      console.log("dropItem" , target , source );
      this.addMappingFieldSimple(target.paths, source.paths);
    },
    mouseoverMapping: function (item) {
      //      console.log("mouseoverMapping" , item );
      Vue.set(item.row, "hover", true);

      // var sourceItem = this.$refs.sourceModel.getItem( item.data.sourceExpression
      // );
      // this.$refs.sourceModel.clearSelect();
      // this.$refs.sourceModel.setSelectRow(sourceItem);
      //
      // var targetItem = this.$refs.targetModel.getItem( item.data.targetPath );
      // this.$refs.targetModel.clearSelect();
      // this.$refs.targetModel.setSelectRow(targetItem);

      // var indexOf = _.indexOf(this.groups,g);
      // Vue.set( this.groups[indexOf] , 'over' , true );
    },
    mouseoutMapping: function (item) {
      //      console.log("mouseoutMapping" , item );
      Vue.set(item.row, "hover", false);
    },
    clickSvg: function () {
      //console.log("clickSvg");
      this.clearSelect();
      this.$refs.sourceModel.clearSelect();
      this.$refs.targetModel.clearSelect();
      this.selectedTargetPoint = undefined;
      this.selectedSourcePoint = undefined;
    },
    editMapping: function (mappingField) {
      //console.log( "editMapping", this.targetItems , this.sourceItems ,  mappingField );
      var _this = this;
      this.$root.$refs.cPopup.mappingFieldPopupOpen(
        this.targetItems,
        this.sourceItems,
        mappingField,
        function (field) {
          //        console.log("mappingFieldPopupOpen callback" , field );

          mappingField.targetPath = field.targetPath;
          mappingField.sourceExpression = field.sourceExpression;
          mappingField.sourceType = field.sourceType;
          mappingField.sourceDefine = field.sourceDefine;

          _this.$nextTick(function () {
            this.refresh();
            this.$emit("changeitem");
          });
        }
      );
    },
    clickMapping: function (e, item) {
      //      console.log('clickMapping',  item,e.ctrlKey) ;
      e.stopPropagation();
      // this.clearSelectCell();
      if (e.ctrlKey == true) {
        if (item.row.select) {
          this.setUnSelect(item);
          if (item.sourceItems != undefined) {
            for (var i = 0; i < item.sourceItems.length; i++) {
              this.$refs.sourceModel.setUnSelectRow(item.sourceItems[i]);
            }
          }
          if (item.targetItem != undefined) {
            this.$refs.targetModel.setUnSelectRow(item.targetItem);
          }
        } else {
          this.setSelect(item);
          if (item.sourceItems != undefined) {
            for (var i = 0; i < item.sourceItems.length; i++) {
              this.$refs.sourceModel.setSelectRow(item.sourceItems[i], false);
            }
          }
          if (item.targetItem != undefined) {
            this.$refs.targetModel.setSelectRow(item.targetItem, false);
          }
        }
      } else {
        if (item.row.select) {
          this.editMapping(item.data);
        } else {
          this.clearSelect();
          this.setSelect(item);
          this.$refs.sourceModel.clearSelect();
          this.$refs.targetModel.clearSelect();
          if (item.sourceItems != undefined) {
            for (var i = 0; i < item.sourceItems.length; i++) {
              this.$refs.sourceModel.setSelectRow(item.sourceItems[i], false);
            }
          }
          if (item.targetItem != undefined) {
            this.$refs.targetModel.setSelectRow(item.targetItem, false);
          }
        }
      }

      // var _this = this;
      // this.$root.$refs.cPopup.mappingFieldPopupOpen( this.targetItems ,
      // this.sourceItems , mappingField ,
      // function(field) {
      // mappingField.targetPath = field.targetPath;
      // mappingField.sourceExpression = field.sourceExpression;
      // mappingField.sourceType = field.sourceType;
      // console.log("mappingFieldPopupOpen callback" , field );
      // _this.$nextTick( function(){
      // this.refresh();
      // this.$emit("changeitem" );
      // });
      // }
      // );

      // console.log("clickMapping" , g );
    },
    clickMenuLinkName: function () {
      this.items = [];
      var targetSize = this.$refs.targetModel.getRowSize();
      var sourceSize = this.$refs.sourceModel.getRowSize();
      var usedSourceItems = [];
      for (var i = 0; i < targetSize; i++) {
        var targetItem = this.$refs.targetModel.getRow(i);

        if (targetItem.data.fieldType == "Group") {
          continue;
        }
        var matchedSourceItem = this.mappingSourceItem(targetItem);

        if (matchedSourceItem != undefined) {
          var targetPath = this.$refs.targetModel.getPath(targetItem);
          var sourcePath = this.$refs.sourceModel.getPath(matchedSourceItem);
          this.addMappingFieldSimple(targetPath, sourcePath);
        }
      }
    },
    matchPath: function (targetPath, sourcePath) {
      var numString = "";
      if (_.startsWith(targetPath, sourcePath)) {
        numString = _.replace(targetPath, sourcePath, "");
      } else if (_.startsWith(sourcePath, targetPath)) {
        numString = _.replace(sourcePath, targetPath, "");
      }
      //console.log("targetPath", targetPath ,"sourcePath" , sourcePath, numString);
      var num = _.parseInt(numString);
      //console.log(num);
      //console.log(_.isInteger( num ) );
      if (_.isInteger(num)) {
        return true;
      }
      return false;
    },
    mappingSourceItem: function (targetItem) {
      var targetPath = this.$refs.targetModel.getPath(targetItem);
      var sourceSize = this.$refs.sourceModel.getRowSize();
      var matchedSourceItem = undefined;
      var candidateSourceItems = [];
      for (var j = 0; j < sourceSize; j++) {
        var sourceItem = this.$refs.sourceModel.getRow(j);

        if (sourceItem.data.fieldType == "Group") {
          continue;
        }

        var sourcePath = this.$refs.sourceModel.getPath(sourceItem);
        var sourcePathModel = getSourcePathModel(sourcePath);
        //console.log("sourcePath",sourcePath);
        //console.log("sourcePathModel",sourcePathModel);
        if (sourcePathModel == targetPath) {
          matchedSourceItem = sourceItem;
          break;
        }
        if (this.matchPath(targetPath, sourcePathModel) == true) {
          candidateSourceItems.push(sourceItem);
        }
        if (sourceItem.data.key == targetItem.data.key) {
          candidateSourceItems.push(sourceItem);
        }
        if (sourceItem.data.name == targetItem.data.name) {
          candidateSourceItems.push(sourceItem);
        }
      }

      if (matchedSourceItem == undefined) {
        matchedSourceItem = freqMax(candidateSourceItems);
      }
      return matchedSourceItem;
    },
    clickMenuDeleteAll: function () {
      this.items = [];
      this.clearSelect();
      this.$refs.sourceModel.clearSelect();
      this.$refs.targetModel.clearSelect();
      this.$nextTick(function () {
        this.refresh();
        this.$emit("changeitem");
      });
    },
    clickMenuDelete: function () {
      if (!!this.selectedItems && this.selectedItems.length == 0) {
        this.$message.error("선택된 맵핑이 없습니다.");
        return;
      }
      var confirm = window.confirm("선택된 맵핑을 삭제 하시겠습니까?");
      if (confirm == false) {
        return;
      }
      _.pullAll(this.items, this.selectedItems);
      this.clearSelect();
      this.$refs.sourceModel.clearSelect();
      this.$refs.targetModel.clearSelect();
      this.$nextTick(function () {
        this.refresh();
        this.$emit("changeitem");
      });
    },
    clickMenuInsert: function () {
      var _this = this;
      this.$root.$refs.cPopup.mappingFieldPopupOpen(
        this.targetItems,
        this.sourceItems,
        {},
        function (field) {
          //        console.log("mappingFieldPopupOpen callback" , field );
          _this.addMappingField(field);
        }
      );
    },
    getItems: function () {
      //      console.log(this.id , "mapping getItems");

      var items = [];
      for (var i = 0; i < this.items.length; i++) {
        items.push(this.items[i].data);
      }
      return items;
    },
    resizeSvg: function () {
      var shapeSize = MAPPING_SHAPE_WIDTH;
      var margin = MAPPING_SHAPE_MARGIN;
      var clienWidth = this.$refs.mappingSvg.clientWidth;
      var clientHeight = this.$refs.mappingSvg.clientHeight;

      this.svgSize.svgWidth = clienWidth;
      this.svgSize.svgHeight = clientHeight;
      this.svgSize.shapeSize = shapeSize;
      this.svgSize.margin = margin;

      this.svgSize.startX = margin + (margin + shapeSize) + margin;
      this.svgSize.startMoreX = clienWidth * 0.1;
      this.svgSize.midLessX = clienWidth * 0.4;
      this.svgSize.midX = clienWidth * 0.5;
      this.svgSize.midMoreX = clienWidth * 0.6;
      this.svgSize.endLessX = clienWidth * 0.9;
      this.svgSize.endX = clienWidth - (margin + (margin + shapeSize) + margin);
    },
    resize: function (refresh) {
      //console.log("mapping resize",refresh);

      this.resizeSvg();
      this.refreshMapping();
      this.refreshDnd();
    },
  },
  mounted: function () {
    //console.log("this.$nextTick" , this);

    this.$nextTick(function () {
      window.addEventListener("resize", _.debounce(this.resize, 100));
      this.resize();
      this.createDatas();
    });
  },
};
</script>

<style scoped>
</style>
