<template>
  <div
    @keydown="keyDownTop($event)"
    @click="clickTop($event)"
    ref="top"
    tabindex="-1"
  >
    <em-model-menu
      v-if="showMenu"
      ref="topMenu"
      :id="id"
      copyable
    ></em-model-menu>
    <em-tree-head v-if="showHead" ref="head"></em-tree-head>
    <div
      class="table-responsive em-model-scroll"
      :style="'height:' + height"
      @scroll.passive="scrollTable($event)"
    >
      <table
        tabindex="-1"
        ref="table"
        class="table table-bordered em-model-table-cell table-sm mb-0"
      >
        <colgroup>
          <col style="width: 40px" />
          <col
            v-for="(c, col) in mfields"
            :style="'width: ' + c.width"
            :key="'col_' + col"
          />
        </colgroup>
        <tbody ref="treeBody">
          <template v-for="e in datas">
            <template v-if="e.data.fieldType == 'Include'">
              <tr class="in-model-tr-cell" :key="'row_' + e.rowNo">
                <td class="p-0 in-model-td-cell" include colspan="12">
                  <div class="in-model-td-cell-title"></div>
                  <table
                    class="table table-bordered em-model-table-cell table-sm mb-0"
                  >
                    <colgroup>
                      <col style="width: 40px" />
                      <col
                        v-for="(c, col) in mfields"
                        :style="'width: ' + c.width"
                        :key="'include_row_' + e.rowNo + '_col_' + col"
                      />
                    </colgroup>
                    <em-model-row :item="e" :readonly="readonly"></em-model-row>
                    <template v-for="ei in e.includedata">
                      <em-model-row-include
                        :item="ei"
                        :readonly="true"
                        :key="'row_icl_' + ei.rowNo"
                      ></em-model-row-include>
                    </template>
                  </table>
                </td>
              </tr>
            </template>
            <template v-else>
              <em-model-row
                :item="e"
                :readonly="readonly"
                :key="'row_' + e.rowNo"
              ></em-model-row>
            </template>
          </template>
          <template ng-if="items.length<minRows" v-for="n in emptyRowSize">
            <em-tree-row-empty :key="'em_row_' + n"></em-tree-row-empty>
          </template>
        </tbody>
      </table>
    </div>
    <em-model-menu v-if="showMenu"></em-model-menu>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import Tree from "../Tree";
import ModelMenu from "./ModelMenu.vue";
import TreeHead from "../Tree/TreeHead.vue";
import ModelRow from "./ModelRow.vue";
import ModelRowInclude from "./ModelRowInclude.vue";
import TreeRowEmpty from "../Tree/TreeRowEmpty.vue";
//import _ from "lodash";

import {
  deepCopyItems,
  forEachTreeChildren,
  forEachTree
} from "../../../utils/tree";

export default {
  extends: Tree,
  components: {
    "em-model-menu": ModelMenu,
    "em-tree-head": TreeHead,
    "em-model-row": ModelRow,
    "em-model-row-include": ModelRowInclude,
    "em-tree-row-empty": TreeRowEmpty
  },
  name: "em-model",
  props: {
    mfields: {
      type: Array,
      default() {
        return [
          {
            key: "key",
            width: "250px",
            label: "ID",
            type: "text",
            required: true,
            unique: true
          },
          {
            key: "name",
            width: "250px",
            label: "이름",
            type: "text",
            required: true
          },
          {
            key: "description",
            width: "100px",
            label: "설명",
            type: "text"
          },
          {
            key: "fieldType",
            width: "100px",
            label: "타입",
            type: "select",
            option: [
              { label: "String", value: "String" },
              { label: "Number", value: "Number" },
              { label: "Group", value: "Group" },
              { label: "Include", value: "Include", show: false }
            ],
            defaultValue: "String"
          },
          {
            key: "fieldLength",
            width: "50px",
            label: "길이",
            type: "text",
            defaultValue: "0"
          },
          {
            key: "fieldScale",
            width: "50px",
            label: "소수",
            type: "text",
            defaultValue: "0"
          },
          {
            key: "fieldDefault",
            width: "100px",
            label: "기본값",
            type: "text"
          },
          {
            key: "arrayType",
            width: "100px",
            label: "반복타입",
            type: "select",
            option: [
              { label: "Not", value: "Not" },
              { label: "Fixed", value: "Fixed" },
              { label: "Variable", value: "Variable" }
            ],
            defaultValue: "Not"
          },
          {
            key: "arrayLength",
            width: "100px",
            label: "반복횟수",
            type: "text"
          },
          {
            key: "fieldHiddenYn",
            width: "50px",
            label: "마스킹",
            type: "select",
            required: false,
            option: [
              { label: "N", value: "N" },
              { label: "Y", value: "Y" }
            ],
            defaultValue: "N"
          },
          {
            key: "property",
            width: "200px",
            label: "추가속성",
            type: "text"
          }
        ];
      }
    },
    showInclude: {
      type: Boolean,
      default: false
    },
    modelId: {
      type: String
    },
    systemId: {
      type: String
    }
  },
  methods: {
    createDatas: function(oitems) {
      //          console.log("model createDatas" );

      var items = deepCopyItems(oitems);
      this.tree.children = items;
      var rowNo = 0;
      var datas = [];
      var defaultCell = this.defaultCell;
      var tidx = 0;
      // Include 처리
      //console.log(this.tree.children);
      forEachTree(this.tree.children, function(depth, index, parent, item) {
        if (!item.data.properties) {
          item.data.properties = {};
        }
        Vue.set(item, "parent", parent);
        Vue.set(item, "depth", depth);
        Vue.set(item, "rowNo", rowNo);
        Vue.set(item, "cols", deepCopyItems(defaultCell));
        Vue.set(item, "row", {
          select: false,
          readonly: item.data.fieldType == "Include"
        });

        //console.log( parent , item);

        var tid = "";

        if (item.data.fieldType == "Include") {
          //	console.log( "Include" , item);
          var iRowNo = 0;
          var iData = [];
          forEachTree(item.include, function(idepth, iindex, iparent, iitem) {
            Vue.set(iitem, "parent", iparent);
            Vue.set(iitem, "depth", depth + idepth);
            Vue.set(iitem, "rowNo", iRowNo);
            Vue.set(iitem, "cols", deepCopyItems(defaultCell));
            Vue.set(iitem, "row", {
              select: false,
              readonly: true
            });

            if (parent == undefined) {
              tidx++;
              tid = tidx;
            } else {
              tid = parent.tid + "." + (iindex + 1);
              if (
                parent.data.fieldType == "Group" &&
                parent.data.arrayType == "Not"
              ) {
                tidx++;
                tid = tidx;
              }
            }
            if (
              item.data.fieldType == "Group" &&
              item.data.arrayType == "Not"
            ) {
              tid = "";
              tidx--;
            }
            Vue.set(iitem, "tid", tid);

            iRowNo++;
            iData.push(iitem);
          });
          Vue.set(item, "tid", "");
          item.includedata = iData;
        } else {
          if (parent == undefined) {
            tidx++;
            tid = tidx;
          } else {
            tid = parent.tid + "." + (index + 1);

            if (
              parent.data.fieldType == "Group" &&
              parent.data.arrayType == "Not"
            ) {
              tidx++;
              tid = tidx;
            }
          }
          if (item.data.fieldType == "Group" && item.data.arrayType == "Not") {
            tid = "";
            tidx--;
          }
          Vue.set(item, "tid", tid);
        }

        datas.push(item);
        //console.log("depth" ,depth ,"index",index,parent);
        rowNo++;
      });
      this.datas = datas;
      this.saveItems();
      //      console.log("tree createDatas",datas.length );
    },
    refreshDatas: function() {
      //      console.log("refreshDatas");

      var rowNo = 0;
      var datas = [];
      var defaultCell = this.defaultCell;
      var tidx = 0;

      forEachTree(this.tree.children, function(depth, index, parent, item) {
        Vue.set(item, "parent", parent);
        Vue.set(item, "depth", depth);
        Vue.set(item, "rowNo", rowNo);
        Vue.set(item, "row", {
          select: false,
          readonly: item.data.fieldType == "Include"
        });
        var tid = "";

        if (item.data.fieldType == "Include") {
          //	console.log( "Include" , item);
          var iRowNo = 0;
          var iData = [];
          forEachTree(item.include, function(idepth, iindex, iparent, iitem) {
            Vue.set(iitem, "parent", iparent);
            Vue.set(iitem, "depth", depth + idepth);
            Vue.set(iitem, "rowNo", iRowNo);
            Vue.set(iitem, "cols", deepCopyItems(defaultCell));
            Vue.set(iitem, "row", {
              select: false,
              readonly: true
            });

            if (parent == undefined) {
              tidx++;
              tid = tidx;
            } else {
              tid = parent.tid + "." + (iindex + 1);
              if (
                parent.data.fieldType == "Group" &&
                parent.data.arrayType == "Not"
              ) {
                tidx++;
                tid = tidx;
              }
            }
            if (
              item.data.fieldType == "Group" &&
              item.data.arrayType == "Not"
            ) {
              tid = "";
              tidx--;
            }
            Vue.set(iitem, "tid", tid);

            iRowNo++;
            iData.push(iitem);
          });
          Vue.set(item, "tid", "");
          item.includedata = iData;
        } else {
          if (parent == undefined) {
            tidx++;
            tid = tidx;
          } else {
            tid = parent.tid + "." + (index + 1);

            if (
              parent.data.fieldType == "Group" &&
              parent.data.arrayType == "Not"
            ) {
              tidx++;
              tid = tidx;
            }
          }
          if (item.data.fieldType == "Group" && item.data.arrayType == "Not") {
            tid = "";
            tidx--;
          }
          Vue.set(item, "tid", tid);
        }
        datas.push(item);
        rowNo++;
      });
      this.datas = datas;
    },
    clickMenuCheck: function() {
      console.log("b-model1", "clickMenuCheck", items);

      var items = this.getItems();
      var url =
        "/rest/portal/resource/model/validate?systemId=" + this.systemId;
      axios
        .post(url, items) //
        .then(response => {
          this.createDatas(response.data);
        });
    },
    clickMenuCompare: function() {
      console.log("clickMenuCompare");
      popupHistorySearch.open(entity => {});
    },
    clickMenuInclude: function() {
      console.log("clickMenuInclude");
      popupModelSearch.open(entity => {
        console.log(entity);
        var last = this.getLastSelectedCell();
        //        console.log("last",last);
        axios
          .get("/rest/portal/resource/model/" + entity.ID) //
          .then(response => {
            var data = response.data;
            if (last == undefined) {
              var item = this.insertRow(undefined);
              item.data.key = entity.ID;
              item.data.name = entity.NAME;
              item.data.fieldType = "Include";
              item.data.fieldLength = "0";
              item.data.fieldScale = "0";
              item.include = data.fields;
            } else {
              item.data.key = entity.ID;
              item.data.name = entity.NAME;
              item.data.fieldType = "Include";
              item.data.fieldLength = "0";
              item.data.fieldScale = "0";
              item.include = data.fields;
            }
            this.refreshDatas();
          });
      });
    },
    clickMenuExcelImport: function(file, files) {
      const formData = new FormData();
      var _this = this;
      formData.append("file", file.raw);
      axios
        .post("/rest/portal/resource/model/import/" + this.modelId, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(function(response) {
          _this.createDatas(response.data);
          _this.$refs.topMenu.$refs.uploadExcel.clearFiles();
        })
        .then(function() {
          _this.$refs.topMenu.$refs.uploadExcel.clearFiles();
        });
    },
    clickMenuExcelExport: function() {
      var items = this.getItems();
      var _this = this;
      axios
        .post("/rest/portal/resource/model/export/" + this.modelId, items, {
          responseType: "blob"
        })
        .then(function(response) {
          var url = window.URL.createObjectURL(response.data, {
            type: "application/vnd.ms-excel"
          });
          var link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", response.headers.filename);
          link.click();
        });
    },

    clickMenuMetaFill: function() {
      var items = this.getItems();
      console.log(items);
      var q1 = axios.post("/rest/portal/resource/model/fill", items);
      var _this = this;
      axios.all([q1]).then(
        axios.spread(function(v1) {
          _this.createDatas(v1.data);
        })
      );
    },
    clickMenuConvCamel: function() {
      var items = this.getItems();
      //      console.log(items);
      var q1 = axios.post("/rest/portal/resource/model/camel", items);
      var _this = this;
      axios.all([q1]).then(
        axios.spread(function(v1) {
          _this.createDatas(v1.data);
        })
      );
    },
    clickMenuConvUnderscore: function() {
      var items = this.getItems();
      //      console.log(items);
      var q1 = axios.post("/rest/portal/resource/model/underscore", items);
      var _this = this;
      axios.all([q1]).then(
        axios.spread(function(v1) {
          _this.createDatas(v1.data);
        })
      );
    },
    clickMenuMetaSearch: function() {
      console.log("clickMenuMeta");
      var _this = this;
      popupMetaField.open(function(entities) {
        //        console.log( entities );
        //
        var last = _this.getLastSelectedCell();
        //        console.log("last",last);
        if (last == undefined) {
          for (var i = 0; i < entities.length; i++) {
            var entity = entities[i];
            var item = _this.insertRow(undefined);
            item.data.key = entity.key;
            item.data.name = entity.name;
            item.data.fieldType = entity.fieldType;
            item.data.fieldLength = entity.fieldLength;
            item.data.fieldScale = entity.fieldScale;
            item.data.fieldLength = entity.fieldLength;
          }
        } else {
          for (var i = 0; i < entities.length; i++) {
            var entity = entities[entities.length - 1 - i];
            var item = _this.insertRow(last.item);
            item.data.key = entity.key;
            item.data.name = entity.name;
            item.data.fieldType = entity.fieldType;
            item.data.fieldLength = entity.fieldLength;
            item.data.fieldScale = entity.fieldScale;
            item.data.fieldLength = entity.fieldLength;
          }
        }

        console.log("item", item);
        _this.$nextTick(function() {
          _this.clearSelect();
          _this.setFocus(item, 0);
        });
      });
    },
    validateModel: function(callback) {
      console.log("b-model1", "validateModel");
      var _this = this;
      var mfields = this.mfields;
      for (var i = 0; i < mfields.length; i++) {
        if (mfields[i].option != undefined) {
          mfields[i].optionValues = _.map(mfields[i].option, "value");
        }
      }
      var colLength = this.mfields.length;
      var result = true;
      //console.log(this.tree.children);
      forEachTreeChildren(this.tree.children, function(parent, items) {
        var uniques = [];

        for (var row = 0; row < items.length; row++) {
          var uniqueKey = "";
          var item = items[row];

          for (var col = 0; col < colLength; col++) {
            var field = mfields[col];
            var cell = item.cols[col];
            var data = item.data;
            var key = field.key;
            var value = data[key];
            if (field.property == true) {
              value = data.properties[key];
            }
            cell.error = false;
            if (field.required && (value == undefined || value == "")) {
              cell.error = true;
              cell.errorMsg = "필수값를 확인 하세요";
              result = false;
            }
            if (field.type == "select") {
              if (_.indexOf(field.optionValues, value) == -1) {
                // if (!field.defaultValue || field.defaultValue == "") {
                //   console.log(field.optionValues, value);
                //   cell.error = true;
                //   cell.errorMsg = "입력값을  확인 하세요";
                //   result = false;
                // } else {
                if (field.property == true) {
                  data.properties[key] = field.defaultValue;
                } else {
                  data[key] = field.defaultValue;
                }
                // }
              }
            }
            // if (
            //   field.type == "select" &&
            //   _.indexOf(field.optionValues, value) == -1
            // ) {
            //   console.log(field.optionValues, value);
            //   cell.error = true;
            //   cell.errorMsg = "입력값을  확인 하세요";
            //   result = false;
            // }
            if (field.unique) {
              //console.log( key , "["+value+"]" );
              uniqueKey = uniqueKey + value;
            }
          }

          if (
            item.data.fieldType == "Group" &&
            item.data.arrayType == "Fixed" &&
            item.data.arrayLength == "1"
          ) {
            item.cols[7].error = true;
            item.cols[7].errorMsg =
              "반복 타입을 확인 하세요 Fixed 타입의 경우 반복횟수가 1 이 될 수 없습니다.";
            result = false;
          }

          if (item.data.fieldType != "Group" && item.children.length > 0) {
            item.cols[3].error = true;
            item.cols[3].errorMsg = "필드 타입을 확인 하세요 ! Group";
            result = false;
          }

          if (uniqueKey != "" && _.indexOf(uniques, uniqueKey) > -1) {
            for (var col = 0; col < colLength; col++) {
              var field = mfields[col];
              var cell = item.cols[col];
              console.log(uniqueKey);
              if (field.unique) {
                cell.error = true;
                cell.errorMsg = "중복값을 확인 하세요";
                result = false;
              }
            }
          } else {
            uniques.push(uniqueKey);
          }
        }
      });

      console.log(this.tree.children);

      return result;
    },
    validateModelP: function() {
      console.log("b-model1", "validateModel");
      var _this = this;
      var mfields = this.mfields;
      for (var i = 0; i < mfields.length; i++) {
        if (mfields[i].option != undefined) {
          mfields[i].optionValues = _.map(mfields[i].option, "value");
        }
      }
      var colLength = this.mfields.length;
      var result = true;
      //console.log(this.tree.children);
      forEachTreeChildren(this.tree.children, function(parent, items) {
        var uniques = [];

        for (var row = 0; row < items.length; row++) {
          var uniqueKey = "";
          var item = items[row];
          var data = item.data;

          for (var col = 0; col < colLength; col++) {
            var field = mfields[col];
            var cell = item.cols[col];
            var key = field.key;
            var value = data[key];
            if (field.property == true) {
              value = data.properties[key];
            }
            cell.error = false;
            if (field.required && (value == undefined || value == "")) {
              cell.error = true;
              cell.errorMsg = "필수값를 확인 하세요";
              result = false;
            }

            if (field.type == "select") {
              if (_.indexOf(field.optionValues, value) == -1) {
                // if (!field.defaultValue || field.defaultValue == "") {
                //   console.log(field.optionValues, value);
                //   cell.error = true;
                //   cell.errorMsg = "입력값을  확인 하세요";
                //   result = false;
                // } else {
                if (field.property == true) {
                  data.properties[key] = field.defaultValue;
                } else {
                  data[key] = field.defaultValue;
                }
                // }
              }
            }
            // if (
            //   field.type == "select" &&
            //   _.indexOf(field.optionValues, value) == -1
            // ) {
            //   console.log(field.optionValues, value);
            //   cell.error = true;
            //   cell.errorMsg = "입력값을  확인 하세요";
            //   result = false;
            // }
            if (field.unique) {
              //console.log( key , "["+value+"]" );
              uniqueKey = uniqueKey + value;
            }
          }

          if (
            item.data.fieldType == "Group" &&
            item.data.arrayType == "Fixed" &&
            item.data.arrayLength == "1"
          ) {
            item.cols[7].error = true;
            item.cols[7].errorMsg =
              "반복 타입을 확인 하세요 Fixed 타입의 경우 반복횟수가 1 이 될 수 없습니다.";
            result = false;
          }

          if (item.data.fieldType != "Group" && item.children.length > 0) {
            item.cols[3].error = true;
            item.cols[3].errorMsg = "필드 타입을 확인 하세요 ! Group";
            result = false;
          }

          if (uniqueKey != "" && _.indexOf(uniques, uniqueKey) > -1) {
            for (var col = 0; col < colLength; col++) {
              var field = mfields[col];
              var cell = item.cols[col];
              console.log(uniqueKey);
              if (field.unique) {
                cell.error = true;
                cell.errorMsg = "중복값을 확인 하세요";
                result = false;
              }
            }
          } else {
            uniques.push(uniqueKey);
          }
        }
      });
      var items = this.getItems();
      console.log("b-model1", "validateModel", items);

      return new Promise(function(resolve, reject) {
        if (result == false) {
          resolve("ERROR");
          return;
        }
        var url =
          "/rest/portal/resource/model/validate?systemId=" + _this.systemId;
        axios
          .post(url, items) //
          .then(response => {
            _this.createDatas(response.data);
            //console.log(_this.datas);
            if (
              _.some(_this.datas, function(o) {
                return o.data.indicate == "E";
              })
            ) {
              resolve("ERROR");
              return;
            } else if (
              _.some(_this.datas, function(o) {
                return o.data.indicate == "W";
              })
            ) {
              resolve("WARN");
              return;
            } else {
              resolve("SUCESS");
              return;
            }
          });
      });
    }
  }
};
</script>

<style scoped>
.em-model-scroll {
  overflow-y: auto;
}
.em-model-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: lightgrey;
}

.em-model-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.em-model-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.em-model-table-cell {
  table-layout: fixed;
  width: 100px;
}
</style>
