<script>
//import _ from "lodash";
import Tree from "../Tree/Tree.vue";

export default {
  extends: Tree,
  name: "em-mapping-model",
  props: {
    readonly: {
      type: Boolean,
      default: true
    },
    showMenu: {
      type: Boolean,
      default: false
    },
    showHead: {
      type: Boolean,
      default: false
    },
    colSelect: {
      type: Boolean,
      default: false
    },
    mfields: {
      type: Array,
      default() {
        return [
          {
            key: "key",
            width: "200px",
            label: "ID",
            type: "text",
            required: true,
            unique: true
          },
          {
            key: "type",
            width: "100px",
            label: "타입",
            type: "label",
            labelFunction: function(item) {
              //console.log("labelFunction", item );
              var text = item.data.fieldType;
              var sText = [];

              if (item.data.fieldLength != 0) {
                sText.push(item.data.fieldLength);
              }
              if (item.data.fieldScale != 0) {
                sText.push(item.data.fieldScale);
              }
              if (sText.length > 0) {
                text = text + " ( " + _.join(sText) + " ) ";
              }
              //console.log("labelFunction" , item );
              if (item.data.fieldType == "Group") {
                text = text + " ( " + item.data.arrayType + " ) ";
              }
              return text;
            }
          },
          {
            key: "name",
            width: "200px",
            label: "이름",
            type: "text",
            required: true
          }
        ];
      }
    }
  },
  methods: {}
};
</script>

<style scoped></style>
