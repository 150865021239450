<template>
  <table class="table table-hover table-bordered table-striped table-sm mb-0">
    <tfoot>
      <tr>
        <td>
          <div class="row">
            <div class="col-auto">
              <el-button-group>
                <el-button
                  icon="el-icon-plus"
                  size="mini"
                  @click.stop="$parent.clickMenuInsertRow()"
                  :disabled="$parent.readonly"
                  xstyle="padding : 7px"
                ></el-button>
                <el-button
                  icon="el-icon-minus"
                  size="mini"
                  @click.stop="$parent.clickMenuDeleteRow()"
                  :disabled="$parent.readonly"
                  xstyle="padding : 7px"
                ></el-button>
                <el-button
                  icon="el-icon-caret-top"
                  size="mini"
                  @click.stop="$parent.clickMenuMoveUp()"
                  :disabled="$parent.readonly"
                  xstyle="padding : 7px"
                ></el-button>
                <el-button
                  icon="el-icon-caret-bottom"
                  size="mini"
                  @click.stop="$parent.clickMenuMoveDown()"
                  :disabled="$parent.readonly"
                  xstyle="padding : 7px"
                ></el-button>
              </el-button-group>
            </div>
          </div>
          <textarea
            v-if="copyable"
            ref="hiddenCopyText"
            class="visually-hidden sr-only"
            cols="100"
          ></textarea>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: "em-table-menu",
  props: {
    copyable: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
