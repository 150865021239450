<template>
  <tr :key="n">
    <td class="em-tree-empty-cell p-0">
      <label
        class="form-control-xs form-control in-table-form-control-cell m-0"
      ></label>
    </td>
    <td
      class="em-tree-empty-cell p-0"
      v-for="(c, col) in $parent.mfields"
      :key="col"
    >
      <label
        class="form-control-xs form-control in-table-form-control-cell m-0"
      ></label>
    </td>
  </tr>
</template>

<script>
export default {
  name: "em-tree-row-empty"
};
</script>

<style scope>
.em-tree-empty-cell {
  border: 1px solid #e2e2e2 !important;
}
.em-tree-form-control-cell {
  border: 1px solid #e2e2e2 !important;
}
</style>
