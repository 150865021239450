<template>
  <el-card class="my-2" shadow="never">
    <div slot="header">
      <h2 class="titleH2 my-1">기본정보</h2>
    </div>
    <el-form-item :label="resourcename + ' ID'" prop="id">
      <el-input
        v-model="entity.id"
        :readonly="true"
        :disabled="true"
        :show-word-limit="true"
        :maxlength="16"
      ></el-input>
    </el-form-item>
    <slot name="header"></slot>
    <el-form-item :label="resourcename + '명'" prop="name">
      <el-input v-model="entity.name" :show-word-limit="true" :maxlength="200"></el-input>
    </el-form-item>
    <el-form-item :label="resourcename + ' 설명'" prop="description">
      <el-input
        type="textarea"
        v-model="entity.description"
        :show-word-limit="true"
        :maxlength="4000"
        rows="5"
      ></el-input>
    </el-form-item>
    <el-form-item label="그룹" prop="groups">
      <el-input v-model="entity.groups">
        <el-button slot="append" icon="el-icon-search" @click="popupGroups()"></el-button>
      </el-input>
    </el-form-item>
    <el-form-item label="권한" prop="privilegeId">
      <el-select v-model="entity.privilegeId" placeholder="권한">
        <el-option v-for="e in codePrivilege"  :label="e.label" :value="e.value" :key="e.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="공개여부" prop="privateYn">
      <el-select v-model="entity.privateYn">
        <el-option value="Y" label="비공개"></el-option>
        <el-option value="N" label="공개"></el-option>
      </el-select>
    </el-form-item>
    <slot name="footer"></slot>
  </el-card>
</template>

<script>
export default {
  name: "em-resource-base-form",
  props: {
    entity: {
      type: Object,
    },
    resourcename: {
      type: String,
      default: "리소스",
    },
    codePrivilege: {
      type: Array,
    },
  },
  methods: {
    popupGroups: function () {
      this.$emit("popup-groups");
    },
    handelCommand: function (command) {
      console.log("handelCommand", command);
      this.$emit("command", command);
    },
  },
};
</script>

<style></style>
