<template>
  <table class="table table-hover table-bordered table-striped table-sm mb-0">
    <tfoot>
      <tr>
        <td>
          <template v-for="item in $parent.items">
            <template v-if="item.errors.length>0">
              <el-alert class="mb-1 p-1" type="error" :key="item.data.seq">
                <div>
                  <strong v-text="'맵핑오류 ( ' + item.data.seq + ' ,'+ item.data.targetPath +' )'"></strong>
                  <span v-text="' * '+item.errors[0].message"></span>
                  <el-button
                    type="danger"
                    size="mini"
                    plain
                    @click="$parent.editMapping( item.data );"
                  >수정</el-button>
                </div>
              </el-alert>
            </template>
          </template>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: "em-mapping-info",
};
</script>

<style scoped>
</style>
