<template>
  <div
    @keydown="keyDownTop($event)"
    @click="clickTop($event)"
    ref="top"
    tabindex="-1"
  >
    <em-table-menu
      v-if="showMenu"
      ref="topMenu"
      :id="id"
      copyable
    ></em-table-menu>
    <em-table-head v-if="showHead" ref="head"></em-table-head>
    <div
      class="table-responsive in-table-scroll"
      :style="'height:' + height"
      @scroll.passive="scrollTable($event)"
    >
      <table
        tabindex="-1"
        ref="table"
        class="table table-bordered in-table-table-cell table-sm mb-0"
      >
        <colgroup>
          <col style="width: 10px" />
          <col
            v-for="(c, col) in mfields"
            :style="'width: ' + c.width"
            :key="'col_' + col"
          />
        </colgroup>
        <tbody ref="treeBody">
          <em-table-row
            :item="e"
            :readonly="readonly"
            v-for="e in datas"
            :key="'row_' + e.rowNo"
          ></em-table-row>
          <template ng-if="items.length<minRows" v-for="n in emptyRowSize">
            <tr :key="'em_row_' + n">
              <td
                width="10"
                valign="middle"
                class="td-empty-cell p-0"
                style="width: 10px"
              >
                <div style="width: 10px"></div>
              </td>
              <td
                class="in-table-empty-cell p-0 td-cell"
                v-for="(c, col) in mfields"
                :width="c.width"
                :style="'width: ' + c.width"
                :key="'em_row_' + n + '_col_' + col"
              >
                <div class="row no-gutters">
                  <div class="col">
                    <label
                      class="form-control-xs form-control in-table-form-control-cell m-0"
                    ></label>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <em-table-menu v-if="showMenu"></em-table-menu>
  </div>
</template>

<script>
import TableMenu from "./TableMenu.vue";
import TableHead from "./TableHead.vue";
import TableRow from "./TableRow.vue";
//import _ from "lodash";
import Vue from "vue";
import {
  getPathTree,
  findTreeFieldPath,
  deepCopyItems,
  forEachTreeChildren
} from "../../../utils/tree";

export default {
  name: "em-table",
  components: {
    "em-table-menu": TableMenu,
    "em-table-head": TableHead,
    "em-table-row": TableRow
  },
  props: {
    id: {
      type: String
    },
    mfields: {
      type: Array
    },
    items: {
      type: Array
    },
    height: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    colSelect: {
      type: Boolean,
      default: true
    },
    showMenu: {
      type: Boolean,
      default: true
    },
    showHead: {
      type: Boolean,
      default: true
    },
    minRows: {
      type: Number,
      default: 20
    }
  },
  data: function() {
    var data = {
      datas: [],
      tree: { children: [] },
      defaultCell: [],
      defaultItem: {},
      selectedCells: [],
      undoData: [],
      redoData: []
    };
    return data;
  },
  watch: {
    mfields: {
      handler: function(_nv, _ov) {
        this.makeFieldsInfo();
      },
      deep: false
    },
    items: {
      handler: function(_nv, _ov) {
        this.createDatas(this.items);
      },
      deep: false
    }
  },
  computed: {
    emptyRowSize: function() {
      return Math.max(this.minRows - this.getRowSize(), 0);
    }
  },
  methods: {
    makeFieldsInfo: function() {
      var defaultItem = { properties: {} };
      var defaultCell = [];
      //      console.log("makeFieldsInfo" ,   this.$refs.head.$refs.headDiv );
      for (var i = 0; i < this.mfields.length; i++) {
        var f = this.mfields[i];
        if (f.option != undefined) {
          f.optionValues = _.map(f.option, "value");
        }
        if (f.defaultValue != undefined) {
          defaultItem[f.key] = f.defaultValue;
        }
        defaultCell.push({
          active: false,
          select: false,
          error: false,
          message: ""
        });
      }
      this.defaultCell = defaultCell;
      this.defaultItem = defaultItem;
    },
    createDatas: function(oitems) {
      //      console.log("table createDatas");
      var items = _.cloneDeep(oitems);
      var rowNo = 0;
      var datas = [];
      var defaultCell = this.defaultCell;

      for (var i = 0; i < this.items.length; i++) {
        var data = this.items[i];
        var item = { data: data, children: [] };
        Vue.set(item, "parent", undefined);
        Vue.set(item, "depth", 0);
        Vue.set(item, "rowNo", rowNo);
        Vue.set(item, "cols", _.cloneDeep(defaultCell));
        Vue.set(item, "row", { select: false, readOnly: false });
        datas.push(item);
        rowNo++;
      }
      this.tree.children = datas;
      this.datas = datas;
      this.saveItems();
    },
    refreshDatas: function() {
      //      console.log("refreshDatas");

      var rowNo = 0;
      var datas = [];
      var defaultCell = this.defaultCell;
      for (var i = 0; i < this.tree.children.length; i++) {
        var item = this.tree.children[i];
        Vue.set(item, "parent", undefined);
        Vue.set(item, "depth", 0);
        Vue.set(item, "rowNo", rowNo);
        datas.push(item);
        rowNo++;
      }

      this.datas = datas;
    },
    getColLabel: function(item, col) {
      //      console.log("getColLabel");
      var f = this.getColDefine(col);
      var v = "";
      if (f.property == true) {
        if (item.data.properties) {
          v = item.data.properties[f.key];
        }
      } else {
        v = item.data[f.key];
      }
      if (f.labelFunction != undefined) {
        //        console.log("labelFunction");
        v = f.labelFunction(item);
      }
      return v;
    },
    decodeProperty(propertyValue) {
      var properties = {};
      if (propertyValue != undefined && propertyValue != "") {
        var propertyValues = _.split(propertyValue, ",");
        for (var i = 0; i < propertyValues.length; i++) {
          var propertyKeyValue = _.split(propertyValues[i], "=");
          if (propertyKeyValue.length == 2) {
            properties[propertyKeyValue[0]] = propertyKeyValue[1];
          } else {
            properties[propertyKeyValue[0]] = "";
          }
        }
      }
      return properties;
    },
    encodeProperty(properties) {
      var propertyValues = [];

      _.forEach(properties, function(value, key) {
        if (value == undefined || value == "") {
          propertyValues.push(key);
        } else {
          propertyValues.push(key + "=" + value);
        }
      });

      return _.join(propertyValues, ",");
    },
    setPropertyValue: function(propertyValue, key, value) {
      var properties = this.decodeProperty(propertyValue);
      properties[key] = value;
      return this.encodeProperty(properties);
    },
    setColValue: function(item, col, value) {
      var f = this.getColDefine(col);
      var newValue = value;
      if (
        f.optionValues != undefined &&
        _.indexOf(f.optionValues, value) == -1
      ) {
        var defaultValue = this.defaultItem[f.key];
        if (defaultValue != undefined) {
          newValue = defaultValue;
        } else {
          newValue = f.optionValues[0];
        }
      }
      if (value == "") {
        if (defaultValue != undefined) {
          newValue = defaultValue;
        }
      }
      //      Vue.set(item.data, key, newValue) ;
      if (f.property == true) {
        item.data.properties[f.key] = newValue;
      } else {
        item.data[f.key] = newValue;
      }

      if (item.cols[col].error) {
        item.cols[col].error = false;
      }
    },
    getColSize: function() {
      return this.mfields.length;
    },
    getColDefine: function(col) {
      return this.mfields[col];
    },
    getColKey: function(col) {
      return this.mfields[col].key;
    },
    // isClearSelect: function (item) {
    //   for (var i = 0; i < item.cols.length; i++) {
    //     if (item.cols[i].select == false) {
    //       //
    //     }
    //   }
    //   return this.fields[col].key;
    // },
    getPath: function(item) {
      var path = getPathTree(this.tree.children, item);
      return path;
    },
    getItem: function(path) {
      var item = findTreeFieldPath(this.tree.children, path);
      return item;
    },
    getRowSize: function() {
      return this.datas.length;
    },
    getRow: function(row) {
      return this.datas[row];
    },
    getRows: function(row1, row2) {
      var minRow = Math.min(row1, row2);
      var maxRow = Math.max(row1, row2);
      var rows = _.slice(this.datas, minRow, maxRow + 1);
      return rows;
    },
    getRowNo: function(row) {
      return this.datas.indexOf(row);
    },
    getParent: function(data) {
      return data.parent;
    },
    isYoungest: function(item) {
      var siblings = this.getSiblings(item);
      return siblings[siblings.length - 1] == item;
    },
    isSibling: function(items) {
      //      console.log("isSibling",items);
      var parents = _.map(items, "parent");
      var parent = _.uniq(parents);
      return parent.length == 1;
    },
    getSiblings: function(data) {
      //      console.log("getSiblings",data);
      return data == undefined || data.parent == undefined
        ? this.tree.children
        : data.parent.children;
    },
    getChildren: function(data) {
      //      console.log("getChildren",data);
      return data == undefined ? this.tree.children : data.children;
    },
    getSiblingsIndex: function(data) {
      //      console.log("getSiblingsIndex",data);

      var getSiblings = this.getSiblings(data);
      //      console.log("getSiblingsIndex getSiblings",getSiblings);

      return this.getSiblings(data).indexOf(data);
    },
    removeChildren: function(rows) {
      //      console.log("removeChildren " );
      var siblings = this.getSiblings(rows[0]);
      _.pullAll(siblings, rows);
    },
    addChildren: function(parent, index, rows) {
      //      console.log("addChildren " );
      var children = this.getChildren(parent);
      var i = 0;
      if (index == -1) {
        for (i = 0; i < rows.length; i++) {
          children.splice(children.length, 0, rows[i]);
        }
      } else {
        for (i = 0; i < rows.length; i++) {
          children.splice(index + 1, 0, rows[rows.length - (i + 1)]);
        }
      }
    },
    addRow: function(data) {
      var newItem = {
        data: _.cloneDeep(this.defaultItem),
        children: [],
        cols: _.cloneDeep(this.defaultCell),
        row: { select: false, readOnly: false }
      };

      var siblings = this.getSiblings(data);
      var index = _.indexOf(siblings, data);
      if (index == -1) {
        siblings.push(newItem);
      } else {
        siblings.splice(index + 1, 0, newItem);
      }
      return newItem;
    },
    removeRows: function(rows) {
      //      console.log("removeRows",rows);
      if (rows == undefined || rows.length == 0) {
        //        console.log("removeRows false rows");
        return false;
      }
      //      var isSibling = this.isSibling( rows );
      //      if ( isSibling == false  )
      //      {
      //        console.log("removeRows false isSibling");
      //        return false;
      //      }
      for (var i = 0; i < rows.length; i++) {
        var item = rows[i];
        this.removeChildren([item]);
      }

      return true;
    },
    /**
     * 선택 관련
     */
    getLastSelectedCell: function() {
      return _.last(this.selectedCells);
    },
    getSelectedCell: function() {
      return _.slice(this.selectedCells);
    },
    getSelectedRow: function() {
      var items = _.map(this.selectedCells, "item");
      items = _.uniq(items);
      items.sort(function(itema, itemb) {
        return itema.rowNo - itemb.rowNo;
      });
      return items;
    },
    getSelectedCol: function() {
      var cols = _.map(this.selectedCells, "col");
      cols = _.uniq(cols);
      cols.sort(function(a, b) {
        return a - b;
      });
      return cols;
    },
    addSelect: function(item, col) {
      _.remove(this.selectedCells, { item: item, col: col });
      this.selectedCells.push({ item: item, col: col });
    },
    removeSelect: function(item, col) {
      //      console.log("removeSelect a" , this.selectedCells.length );
      _.remove(this.selectedCells, { item: item, col: col });
      //      console.log("removeSelect b" , this.selectedCells.length );
    },
    removeAllSelect: function() {
      this.selectedCells = [];
    },
    setSelectRangeRow: function(item1, item2) {
      //      console.log("setSelectRangeRow");
      var row1 = item1.rowNo;
      var row2 = item2.rowNo;
      var minRow = Math.min(row1, row2);
      var maxRow = Math.max(row1, row2);
      var rows = this.getRows(minRow, maxRow);
      this.clearSelect();
      for (var i = 0; i < rows.length; i++) {
        this.setSelectRow(rows[i]);
      }
    },
    setUnSelectRow: function(item) {
      this.removeSelect(item, -1);
      Vue.set(item.row, "select", false);
    },
    setSelectRow: function(item, emit) {
      this.addSelect(item, -1);
      Vue.set(item.row, "select", true);
      if (emit == undefined || emit == true) {
        this.$emit("select-row", item);
      }
    },
    setSelectColAll: function(col) {
      var rowSize = this.getRowSize();
      for (var i = 0; i < rowSize; i++) {
        var row = this.getRow(i);
        row.cols[col].select = true;
        this.addSelect(row, col);
      }
      this.addSelect(this.getRow(0), col);
    },
    clearSelectCell: function() {
      var selectedCells = this.getSelectedCell();

      for (var i = 0; i < selectedCells.length; i++) {
        var item = selectedCells[i].item;
        var col = selectedCells[i].col;
        if (col != -1) {
          Vue.set(item.cols[col], "active", false);
          Vue.set(item.cols[col], "select", false);
          this.removeSelect(item, col);
        }
      }
    },
    clearSelectRow: function() {
      var selectedCells = this.getSelectedCell();

      for (var i = 0; i < selectedCells.length; i++) {
        var item = selectedCells[i].item;
        var col = selectedCells[i].col;
        if (col == -1) {
          Vue.set(item.row, "select", false);
          this.removeSelect(item, col);
        }
      }
    },
    clearSelectForce: function() {
      //      console.log("clearSelectForce");
      var rowSize = this.getRowSize();
      var colSize = this.getColSize();
      for (var i = 0; i < rowSize; i++) {
        var data = this.getRow(i);
        Vue.set(data.row, "select", false);
        for (var col = 0; col < colSize; col++) {
          Vue.set(data.cols[col], "active", false);
          Vue.set(data.cols[col], "select", false);
        }
      }
      this.removeAllSelect();
    },
    clearSelect: function() {
      var selectedCells = this.getSelectedCell();

      for (var i = 0; i < selectedCells.length; i++) {
        var item = selectedCells[i].item;
        var col = selectedCells[i].col;
        if (col == -1) {
          Vue.set(item.row, "select", false);
        } else {
          Vue.set(item.cols[col], "active", false);
          Vue.set(item.cols[col], "select", false);
        }
      }
      this.removeAllSelect();
    },
    setSelectRange: function(item1, col1, item2, col2) {
      // console.log("setSelectRange", item1, col1 , item2 , col2 ) ;

      var row1 = item1.rowNo;
      var row2 = item2.rowNo;
      var minRow = Math.min(row1, row2);
      var maxRow = Math.max(row1, row2);

      var minCol = Math.min(col1, col2);
      var maxCol = Math.max(col1, col2);

      var selectedItems = this.getRows(minRow, maxRow);

      this.clearSelect();

      for (var i = 0; i < selectedItems.length; i++) {
        for (var col = minCol; col <= maxCol; col++) {
          this.setSelect(selectedItems[i], col);
        }
      }
    },
    setUnSelect: function(item, col) {
      //      console.log("setUnSelect") ;
      item.cols[col].select = false;
      item.cols[col].active = false;
      this.removeSelect(item, col);
    },
    setSelect: function(item, col) {
      //      console.log("setSelect" , col ) ;
      item.cols[col].select = true;
      item.cols[col].active = false;
      this.addSelect(item, col);
      this.$emit("select-col", item, col);
    },
    setActive: function(item, col) {
      //      console.log('setActive', item, col) ;
      this.setSelect(item, col);
      this.$set(item.cols[col], "active", true);
    },
    moveUpRows: function(rows) {
      //      console.log("moveUpRows",rows);
      if (rows == undefined || rows.length == 0) {
        //        console.log("moveUpRows false rows");
        return false;
      }
      var isSibling = this.isSibling(rows);
      if (isSibling == false) {
        //        console.log("moveUpRows false isSibling");
        return false;
      }
      var siblings = this.getSiblings(rows[0]);
      if (siblings[0] == rows[0]) {
        //        console.log("moveUpRows false first ");
        return false;
      }

      for (var i = 0; i < rows.length; i++) {
        var item = rows[i];
        var indexOf = _.indexOf(siblings, item);
        siblings.splice(indexOf, 1);
        siblings.splice(indexOf - 1, 0, item);
        // console.log( indexOf );
      }
      this.saveItems();
      this.refreshDatas();
      return true;
    },
    moveDownRows: function(rows) {
      //      console.log("moveDownRows",rows);
      if (rows == undefined || rows.length == 0) {
        console.log("moveDownRows false rows");
        return false;
      }
      var isSibling = this.isSibling(rows);
      if (isSibling == false) {
        console.log("moveDownRows false isSibling");
        return false;
      }
      var siblings = this.getSiblings(rows[0]);
      if (_.last(siblings) == _.last(rows)) {
        console.log("moveDownRows false last ");
        return false;
      }
      _.reverse(rows);
      for (var i = 0; i < rows.length; i++) {
        var item = rows[i];
        var indexOf = _.indexOf(siblings, item);
        siblings.splice(indexOf, 1);
        siblings.splice(indexOf + 1, 0, item);
        // console.log( indexOf );
      }
      this.saveItems();
      this.refreshDatas();
      return true;
    },
    insertRow: function(data) {
      var addRow = this.addRow(data);
      this.saveItems();
      this.refreshDatas();
      return addRow;
    },
    deleteRows: function(rows) {
      var removeRows = this.removeRows(rows);
      this.saveItems();
      this.refreshDatas();
      return removeRows;
    },
    deleteCells: function() {
      var selectedCells = this.getSelectedCell();

      for (var i = 0; i < selectedCells.length; i++) {
        var item = selectedCells[i].item;
        var col = selectedCells[i].col;
        this.setColValue(item, col, "");
      }

      this.saveItems();
      this.refreshDatas();
    },
    changeCell: function(_e, item, col) {
      //console.log("changeCell");
      // console.log(item.data.inputType);
      if (item.data.key == undefined) {
        item.data.key = "";
      }
      item.data.key = item.data.key.trim();
      item.cols[col].error = false;
      item.data.indicate = "C";
      item.data.indicateMsg = "";
      //console.log("changeCell",item);

      this.saveItems();
    },
    popupCell: function(_e, item, col) {
      var f = this.getColDefine(col);
      f.popupFunction(this, item, col, this.popupCallBack);
    },
    popupCallBack: function(_item, _col, _value) {},
    redo: function() {
      var length = this.redoData.length;
      console.log("redo", this.redoData.length);
      if (length > 0) {
        var items = this.redoData.pop();

        if (items != undefined) {
          this.undoData.push(deepCopyItems(items));
          this.loadItems(items);
        }
      }

      return;
    },
    undo: function() {
      var length = this.undoData.length;
      console.log("undo", this.undoData.length);
      var tree = this.undoData.pop();
      if (tree != undefined) {
        this.redoData.push(deepCopyItems(tree));
        this.loadItems(tree);
      }

      return;
    },
    saveItems: function() {
      console.log("saveItems", this.undoData.length);
      this.undoData.push(_.cloneDeep(this.tree));
      if (this.undoData.length > 10) {
        this.undoData.shift();
      }
      this.redoData = [];
      this.$emit("changeitem");
      console.log("changeitem");
    },
    loadItems: function(tree) {
      this.tree = tree;
      this.refreshDatas();
      this.clearFocus();
    },
    scrollTable: function(e) {
      //      console.log( this.$refs );
      if (this.showHead && this.$refs.head != undefined) {
        this.$refs.head.$refs.headDiv.scrollLeft = e.target.scrollLeft;
      }
    },
    dragStartIndicate: function(e, item) {
      console.log("dragStartIndicate");
      this.setSelectRow(item);
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.setData("application/tree-row", "");
    },
    dragOverIndicate: function(e, _item) {
      e.preventDefault();
      var allow = e.dataTransfer.types.includes("application/tree-row");
      e.dataTransfer.dropEffect = allow ? "move" : "none";
      return allow;
    },
    dragEnterIndicate: function(e, item) {
      var allow = e.dataTransfer.types.includes("application/tree-row");
      // console.log("dragOverLabel" , allow);
      if (allow == true) {
        var lastCell = this.getLastSelectedCell();
        if (lastCell != undefined && lastCell.col == -1) {
          this.setSelectRangeRow(lastCell.item, item);
          this.setSelectRow(lastCell.item);
        }
      }
    },
    dragStartLabel: function(e, item, col) {
      this.setSelect(item, col);
      e.dataTransfer.setData("application/tree-cell", "");
    },
    dragEndLabel: function(_e, _item, _col) {
      console.log("dragEndLabel");
    },
    dragOverLabel: function(e, _item, _col) {
      e.preventDefault();
      var allow = e.dataTransfer.types.includes("application/tree-cell");
      e.dataTransfer.dropEffect = allow ? "move" : "none";
      return allow;
    },
    dragEnterLabel: function(e, item, col) {
      //console.log( "dragEnterLabel ",  e );
      var allow = e.dataTransfer.types.includes("application/tree-cell");
      if (allow) {
        var lastCell = this.getLastSelectedCell();
        if (lastCell != undefined && lastCell.col != -1) {
          this.setSelectRange(lastCell.item, lastCell.col, item, col);
          this.setSelect(lastCell.item, lastCell.col);
        }
      }
    },
    dropLabel: function(e, item, _col) {
      console.log("dropLabel ");
      var targetPaths = getPathTree(this.tree.children, item);
      var targetData = { id: this.id, paths: targetPaths };
      var sourceDataString = e.dataTransfer.getData(
        "application/tree-cell-link"
      );
      var sourceData = JSON.parse(sourceDataString);
      this.$emit("dropitem", targetData, sourceData);
    },
    keyDownTop: function(e) {
      var ctrlKey = e.ctrlKey;
      // console.log("keyDownTable");
      // Delete
      if (e.key == "Delete") {
        this.deleteCell();
        return;
      }
      //
      if (e.key == "Escape") {
        this.clearSelectForce();
        this.clearFocus();
        return;
      }
      if (ctrlKey == true) {
        if (e.key == "c" || e.key == "C") {
          e.preventDefault();
          this.copyCell();
          return;
        } else if (e.key == "v" || e.key == "V") {
          e.preventDefault();
          this.pasteCell();
          return;
        } else if (e.key == "a" || e.key == "A") {
          e.preventDefault();
          this.clearSelect();
          this.selectAll();
          return;
        } else if (e.key == "z" || e.key == "Z") {
          e.preventDefault();
          this.undo();
          return;
        } else if (e.key == "y" || e.key == "Y") {
          e.preventDefault();
          this.redo();
          return;
        }
      }
    },
    keyDownLabel: function(e, item, col) {
      console.log("keyDownLabel", col);
      // console.log('keyDownLabel', e,item, col) ;
      var select = item.cols[col].select;
      var active = item.cols[col].active;
      var charKey = this.isCharKey(e.keyCode);
      var ctrlKey = e.ctrlKey;

      // MOVE
      if (
        e.key == "Tab" ||
        e.key == "Enter" || //
        e.key == "ArrowDown" ||
        e.key == "ArrowLeft" ||
        e.key == "ArrowRight" ||
        e.key == "ArrowUp" //
        // || e.key == 'Home' || e.key == 'End' || e.key == 'PageUp' || e.key
        // == 'PageDown'//
      ) {
        e.preventDefault();
        var moveRow = item.rowNo;
        var moveCol = col;
        if ((e.key == "Tab" && e.shiftKey == false) || e.key == "ArrowRight") {
          moveCol++;
        } else if (
          (e.key == "Tab" && e.shiftKey == true) ||
          e.key == "ArrowLeft"
        ) {
          moveCol--;
        } else if (
          e.key == "ArrowUp" ||
          (e.key == "Enter" && e.shiftKey == true)
        ) {
          moveRow--;
        } else if (
          e.key == "ArrowDown" ||
          (e.key == "Enter" && e.shiftKey == false)
        ) {
          moveRow++;
        }

        this.$nextTick(function() {
          this.moveCell(moveRow, moveCol);
        });

        return;
      }
      if (active == true) {
        return true;
      } else if (ctrlKey == false && charKey == true) {
        this.setActive(item, col);
        this.setActiveFocus(item, col);
      }
      return true;
    },
    keyDownInputCell: function(e, item, col) {
      console.log("keyDownInputCell");
      if (e.key == "Escape") {
        this.$nextTick(function() {
          this.clearSelectForce();
          this.clearFocus();
        });
        return;
      }
      // 이동 처리
      else if (
        e.key == "Tab" ||
        e.key == "Enter" //
        // || e.key == 'PageUp' || e.key == 'PageDown'//
      ) {
        e.preventDefault();
        var row = item.rowNo;
        var moveRow = row;
        var moveCol = col;
        if (e.key == "Tab" && e.shiftKey == false) {
          moveCol++;
        } else if (e.key == "Tab" && e.shiftKey == true) {
          moveCol--;
        } else if (e.key == "Enter" && e.shiftKey == true) {
          moveRow--;
        } else if (e.key == "Enter" && e.shiftKey == false) {
          var isYoungest = this.isYoungest(item);
          console.log("isYoungest", isYoungest);

          if (isYoungest) {
            // 추가 필요
            var newItem = this.insertRow(item);
            var rowNo = this.getRowNo(newItem);
            moveRow = rowNo;
          } else {
            moveRow++;
          }
        }
        this.$nextTick(function() {
          var cell = this.moveCell(moveRow, moveCol);
          console.log(cell);
          if (cell != undefined) {
            this.setActive(cell.item, cell.col);
            this.setActiveFocus(cell.item, cell.col);
          }
        });
      }
    },
    clickTop: function(_e) {
      //      console.log('clickTop') ;
      this.clearSelectForce();
      this.clearFocus();
    },
    clickIndicate: function(e, item) {
      //      console.log('clickIndicate', e, item) ;
      e.stopPropagation();
      this.clearSelectCell();
      if (e.ctrlKey == true) {
        if (item.row.select) {
          this.setUnSelectRow(item);
        } else {
          this.setSelectRow(item);
        }
      } else if (e.shiftKey == true) {
        var lastCell = this.getLastSelectedCell();
        if (lastCell != undefined && lastCell.col == -1) {
          this.setSelectRangeRow(lastCell.item, item);
          this.setSelectRow(lastCell.item);
        }
      } else {
        this.clearSelect();
        this.setSelectRow(item);
      }
    },
    clickLabel: function(e, item, col) {
      //console.log("clickLabel");
      e.stopPropagation();

      // 열선택 모드 인경우
      if (this.colSelect) {
        this.clearSelectRow();
        if (e.shiftKey == true) {
          var lastCell = this.getLastSelectedCell();
          if (lastCell != undefined && lastCell.col != -1) {
            this.setSelectRange(lastCell.item, lastCell.col, item, col);
            this.setSelect(lastCell.item, lastCell.col);
          }
        } else {
          var select = item.cols[col].select;
          var active = item.cols[col].active;
          //console.log(e) ;

          if (e.ctrlKey == true) {
            if (select == true) {
              this.setUnSelect(item, col);
            } else {
              this.setSelect(item, col);
            }
          } else {
            // console.log("clickLabel readonly", this.readonly);

            if (this.readonly) {
              this.clearSelect();
              this.setSelect(item, col);
            } else {
              if (select == true) {
                this.clearSelect();
                this.setActive(item, col);
                this.setActiveFocus(item, col);
              } else {
                this.clearSelect();
                this.setSelect(item, col);
              }
            }
          }
        }
      } else {
        this.clickIndicate(e, item);
      }
    },
    isCharKey: function(keyCode) {
      var result = //
        (keyCode > 47 && keyCode < 58) || // number
        keyCode == 32 ||
        keyCode == 13 || // spacebar return
        (keyCode > 64 && keyCode < 91) || // letter keys
        (keyCode > 95 && keyCode < 112) || // numpad keys
        (keyCode > 185 && keyCode < 193) || // ;=,-./`
        (keyCode > 218 && keyCode < 223); // [/]'
      return result;
    },
    moveCell: function(row, col) {
      console.log("moveCell start ", row, col);
      var maxRow = this.getRowSize();
      var maxCol = this.getColSize();
      if (col < 0) {
        if (row == 0) {
          return;
        }
        col = maxCol - 1;
        row--;
      }
      if (col >= maxCol) {
        if (row == maxRow - 1) {
          return;
        }
        col = 0;
        row++;
      }
      if (row < 0) {
        row = 0;
      }
      if (row >= maxRow) {
        row = maxRow - 1;
      }
      this.clearSelect();
      var item = this.getRow(row);
      //      console.log('moveCell end ', row, col) ;
      this.setSelect(item, col);
      this.setFocus(item, col);
      var cell = { item: item, col: col };
      return cell;
    },
    clearFocus: function() {
      //      console.log("clearFocus");
      this.$nextTick(function() {
        this.$refs.table.focus();
      });
    },
    setFocus: function(item, col) {
      //      console.log("setFocus", item, col) ;
      //return this.$parent.id + "_label_" + this.item.rowNo + "_" + col;

      var colId = this.id + "_label_" + item.rowNo + "_" + col;
      console.log(colId);
      this.$nextTick(function() {
        //$("#" + colId).focus();
        var element = document.getElementById(colId);
        if (element != undefined) {
          element.focus();
        }
      });
    },
    setActiveFocus: function(item, col) {
      //     console.log('setActiveFocus', item, col) ;
      // $parent.id + "_cell_" + data.rowNo + "_" + col;
      var colId = this.id + "_cell_" + item.rowNo + "_" + col;
      console.log(colId);
      this.$nextTick(function() {
        //var s = $("#" + colId);
        var element = document.getElementById(colId);
        if (element != undefined) {
          //console.log("focus" , s);
          element.focus();
          //console.log("select");
          element.select();
        }
      });
    },
    deactiveCell: function(_ridx, _cidx) {
      // this.$set(this.items[ridx].cols[cidx], 'active', false) ;
      // this.$set(this.items[ridx].cols[cidx], 'select', false) ;
    },
    deleteCell: function() {
      console.log("deleteCell");
      if (this.readonly == true) {
        return;
      }
      var selectedItems = this.getSelectedRow();
      // ROW SELECT

      var firstItem = _.head(selectedItems);
      if (firstItem.row.select == true) {
        this.deleteRows(selectedItems);
      }
      // CELL SELECT
      else {
        this.deleteCells();
      }
    },
    pasteCell: function() {
      //      console.log("pasteCell");
      if (this.readonly == true) {
        return;
      }
      var text = "";
      if (window.clipboardData) {
        text = window.clipboardData.getData("Text");
        this.pasteCellText(text);
      } else if (navigator.clipboard) {
        var _this = this;
        navigator.clipboard.readText().then(function(text) {
          _this.pasteCellText(text);
        });
      }
    },
    pasteCellText: function(text) {
      //      console.log("pasteCell", text.length , text) ;
      var rowSize = this.getRowSize();
      var currentRow = rowSize;
      var currentCol = 0;
      var last = this.getLastSelectedCell();
      if (last != undefined && last.col != -1) {
        currentRow = last.item.rowNo;
        currentCol = last.col;
      }
      //      console.log( "currentRow" ,currentRow ,currentCol  );

      var textRows = _.split(text, "\n");
      if (textRows.length == 0) {
        return;
      }
      var firstTextCols = _.split(textRows[0], "\t");

      for (var i = 0; i < textRows.length; i++) {
        if (textRows[i] == "") {
          continue;
        }
        var row = currentRow + i;
        var item = row >= rowSize ? this.addRow() : this.getRow(row);
        var textCols = _.split(textRows[i], "\t");

        for (var j = 0; j < textCols.length; j++) {
          var col = currentCol + j;
          if (col < this.mfields.length) {
            var key = this.mfields[col].key;
            var v = textCols[j];
            v = _.trim(v);
            this.setColValue(item, col, v);
            //console.log(" Vue.set" ,item,  row, v , v.length );
          }
        }
      }
      this.saveItems();
      this.refreshDatas();
      this.setSelectRange(
        this.getRow(currentRow),
        currentCol,
        this.getRow(currentRow + textRows.length - 1),
        currentCol + firstTextCols.length - 1
      );
    },
    selectAll: function() {
      //      console.log("selectAll") ;
      var length = this.getRowSize();
      for (var i = 0; i < length; i++) {
        this.setSelectRow(this.getRow(i));
      }
    },
    copyCell: function() {
      var textRows = [];
      var cols = this.getSelectedCol();
      var selectedItems = this.getSelectedRow();
      var length = selectedItems.length;
      var firstItem = _.head(selectedItems);
      var i = 0;
      if (firstItem.row.select == true) {
        for (i = 0; i < length; i++) {
          var textCols = [];
          var item = selectedItems[i];
          for (var col = 0; col < this.mfields.length; col++) {
            var f = this.mfields[col];
            var key = f.key;
            var v = "";
            if (f.property == true) {
              v = item.data.properties[key];
            } else {
              v = item.data[key];
            }
            v = v == undefined ? "" : _.trim(v);
            //            console.log(key,v,v.length)
            textCols.push(v);
          }
          textRows.push(_.join(textCols, "\t"));
        }
      } else {
        for (i = 0; i < length; i++) {
          var textCols = [];
          var item = selectedItems[i];
          for (var j = cols[0]; j <= cols[cols.length - 1]; j++) {
            var key = this.mfields[j].key;
            var v = item.data[key];
            v = v == undefined ? "" : _.trim(v);
            //            console.log(key,v,v.length)
            textCols.push(v);
          }
          textRows.push(_.join(textCols, "\t"));
        }
      }
      var copyText = _.join(textRows, "\n");
      var hiddenCopyText = this.$refs.topMenu.$refs.hiddenCopyText;

      hiddenCopyText.value = copyText;
      hiddenCopyText.select();
      document.execCommand("copy");
      //      console.log("copyText",copyText.length,copyText) ;
      //      console.log("hiddenCopyText",hiddenCopyText.value.length,hiddenCopyText.value) ;
      this.clearSelect();
      this.clearFocus();
    },
    clickHeaderCol: function(_e, col) {
      //      console.log("clickHeaderCol");
      this.clearSelect();
      this.setSelectColAll(col);
    },
    clickMenuMoveLeft: function() {
      var selectedItems = this.getSelectedRow();
      var moveLeftRows = this.moveLeftRows(selectedItems);
    },
    clickMenuMoveRight: function() {
      var selectedItems = this.getSelectedRow();
      var moveRightRows = this.moveRightRows(selectedItems);
    },
    clickMenuMoveUp: function() {
      var selectedItems = this.getSelectedRow();
      var moveUpRows = this.moveUpRows(selectedItems);
    },
    clickMenuMoveDown: function() {
      var selectedItems = this.getSelectedRow();
      var moveDownRows = this.moveDownRows(selectedItems);
    },
    clickMenuInsertRow: function() {
      //      console.log("clickMenuInsertRow" );
      var last = this.getLastSelectedCell();
      var item = this.insertRow(last == undefined ? last : last.item);

      this.$nextTick(function() {
        this.clearSelect();
        this.setFocus(item, 0);
      });
    },
    clickMenuDeleteRow: function() {
      var selectedItems = this.getSelectedRow();
      if (selectedItems.length == 0) {
        //        console.log("clickMenuDeleteRow selectedItems 0" );
        return;
      }

      var deleteRows = this.deleteRows(selectedItems);

      if (deleteRows) {
        this.clearSelect();
        this.clearFocus();
      }
    },
    validateTree: function() {
      //      console.log( "b-tree","validateModel" , this );

      var _this = this;
      var mfields = this.mfields;
      var colLength = this.mfields.length;
      var result = true;
      forEachTreeChildren(this.tree.children, function(_parent, items) {
        var uniques = [];
        for (var row = 0; row < items.length; row++) {
          var uniqueKey = "";
          var item = items[row];
          for (var col = 0; col < colLength; col++) {
            var field = mfields[col];
            var cell = item.cols[col];
            var data = item.data;
            var key = field.key;
            var value = data[key];
            cell.error = false;
            if (field.required && (value == undefined || value == "")) {
              cell.error = true;
              cell.errorMsg = "필수값를 확인 하세요";
              result = false;
            }
            if (field.unique) {
              //console.log( key , "["+value+"]" );
              uniqueKey = uniqueKey + value;
            }
          }
          if (uniqueKey != "" && _.indexOf(uniques, uniqueKey) > -1) {
            for (var col = 0; col < colLength; col++) {
              var field = mfields[col];
              var cell = item.cols[col];
              console.log(uniqueKey);
              if (field.unique) {
                cell.error = true;
                cell.errorMsg = "중복값을 확인 하세요";
                result = false;
              }
            }
          } else {
            uniques.push(uniqueKey);
          }
        }
      });
      return result;
    },
    getItems: function() {
      //      console.log("table getItems");

      var items = [];
      for (var i = 0; i < this.tree.children.length; i++) {
        items.push(this.tree.children[i].data);
      }
      return items;
    }
  },
  mounted: function() {
    //    console.log("mounted table");
    this.makeFieldsInfo();
    this.createDatas(this.items);
  },
  updated: function() {
    //    console.log("updated tree");
  }
};
</script>

<style scoped>
/* ---------------------------------------- */
/* TABLE
/* ---------------------------------------- */
.in-table-table-cell {
  table-layout: fixed;
  width: 100px;
}

.in-table-tr-cell[rowselected] {
  border: 1px solid darkgrey;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px darkgrey;
  outline: 1px solid darkgrey;
  outline-offset: -1px;
  background-color: #daeeff;
}

.in-table-td-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  border: 2px solid #dee2e6;
  position: relative;
}
.in-table-label-cell[draging] {
  box-sizing: border-box;
  outline: 1px solid darkgrey;
  outline-offset: -1px;
  background-color: #ff0000;
  cursor: all-scroll;
}
.in-table-td-cell[rowselected] {
  box-sizing: border-box;
}

.in-table-td-cell[selected] {
  box-sizing: border-box;
  outline: 1px solid darkgrey;
  outline-offset: -1px;
  background-color: #daeeff;
}

.in-table-td-cell[actived] {
  background-color: transparent;
}
.in-table-td-cell[error] {
  outline: 2px solid #a22727;
  outline-offset: -2px;
  box-sizing: border-box;
  background-color: #ffdae3;
}

.form-control-xs {
  height: calc(1.5em + 0.5em + 2px);
  padding: 0.25rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.in-table-form-control-cell {
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.in-table-form-control-cell:focus {
  box-shadow: none !important;
}

.in-table-form-control-xs {
  height: calc(1.5em + 0.5em + 2px);
  padding: 0.25rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.in-table-empty-cell {
  border: 1px solid #e2e2e2 !important;
}

.in-table-scroll {
  overflow-y: scroll;
}
.in-tree-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: lightgrey;
}

.in-table-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.in-table-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.grid-cell-dot {
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 1px;
  background-color: #a22727;
  width: 8px;
  bottom: 3px;
}

.form-control-xs {
  height: calc(1.5em + 0.5em + 2px);
  padding: 0.25rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
