<template>
  <table class="table table-hover table-bordered table-striped table-sm mb-0">
    <tfoot>
      <tr>
        <td>
          <el-button-group>
            <el-button
              size="mini"
              :disabled="$parent.readonly"
              icon="el-icon-plus"
              @click.stop="$parent.clickMenuInsert( )"
            ></el-button>
            <el-button
              size="mini"
              :disabled="$parent.readonly"
              icon="el-icon-minus"
              @click.stop="$parent.clickMenuDelete( )"
            ></el-button>
            <el-button
              size="mini"
              :disabled="$parent.readonly"
              icon="el-icon-refresh"
              @click.stop="$parent.clickMenuDeleteAll( )"
            ></el-button>
            <el-button
              size="mini"
              :disabled="$parent.readonly"
              icon="el-icon-magic-stick"
              @click.stop="$parent.clickMenuLinkName( )"
            ></el-button>
            <el-button
              size="mini"
              :disabled="$parent.readonly"
              icon="el-icon-warning-outline"
              @click.stop="$parent.clickMenuShowError( )"
            ></el-button>
          </el-button-group>
          <div class="row">
            <div class="col" v-if="showError">
              <template v-for="item in $parent.items">
                <template v-if="item.svg && item.svg.target && item.svg.target.error==true">
                  <el-alert class="my-1" type="error" :key="item.data.seq">
                    <div>
                      <strong v-text="'맵핑오류 ( ' + item.data.seq + ' ,'+ item.data.targetPath +' )'"></strong>
                      <span v-text="'*'+item.svg.target.message"></span>
                      <el-button
                        type="danger"
                        size="mini"
                        plain
                        @click="$parent.editMapping( item.data );"
                      >수정</el-button>
                    </div>
                  </el-alert>
                </template>
              </template>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: "em-mapping-menu",
  data: function () {
    var data = {
      showError: false,
    };
    return data;
  },
  methods: {
    clickMenuShowError: function () {
      this.showError = !this.showError;
      //        console.log(this.showError);
    },
  },
};
</script>

<style scoped>
</style>
