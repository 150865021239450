<template>
  <el-card class="my-2" shadow="never">
    <div slot="header">
      <el-row>
        <el-col :span="12" class="pt-2">
          <h2 class="titleH2">변경이력</h2>
        </el-col>
        <el-col class="text-right" :span="12">
          <el-button native-type="button" size="small" icon="el-icon-refresh" @click="refresh"></el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th scope="col" width="5%">버전</th>
            <th scope="col" width="35%">작성일시</th>
            <th scope="col" width="50%">Comment</th>
            <th scope="col" width="5%">작성자</th>
            <th scope="col" width="5%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in entities.content" :key="e.SEQ">
            <td v-text="e.SEQ"></td>
            <td>{{e.HISTORY_DATE|formatDate}} {{e.HISTORY_TIME|formatTime}}</td>
            <td v-text="e.RESOURCE_COMMENT"></td>
            <td>
              <b-popoveruser :textvalue="e.USER_ID"></b-popoveruser>
            </td>
            <td>
              <a :href="'/popup/history/view?seq='+e.SEQ" target="_blank" size="mini">
                <el-button icon="el-icon-search" size="mini" class="p-1"></el-button>
              </a>
            </td>
          </tr>
          <tr v-if="entities!=undefined&&entities.content.length==0">
            <td colspan="5" valign="middle" align="center">
              <p class="p-3">조회결과가 없습니다.</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <el-row>
      <el-col class="text-center">
        <em-pagination1 :entities="entities" @move="move"></em-pagination1>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import Pagination1 from "./Pagination1.vue";

export default {
  name: "em-history-list",
  components: {
    "em-pagination1": Pagination1
  },    
  props: {
    resourcetype: {
      type: String
    },
    resourceid: {
      type: String
    }
  },
  data: function() {
    var data = {
      params: { page: 0, size: 10 },
      loading: true,
      entities: { content: [] }
    };
    return data;
  },
  methods: {
    refresh: function() {
      this.loading = true;
      this.params.page = 0;
      this.load();
    },
    move: function(page) {
      this.params.page = page - 1;
      this.load();
    },
    load: function() {
      this.loading = true;
      this.params.RESOURCE_ID = this.resourceid;
      this.params.RESOURCE_TYPE = this.resourcetype;
      var _this = this;
      //    debugger;
      var q1 = axios.get("/rest/portal/history", { params: this.params });
      axios.all([q1]).then(
        axios.spread(function(v1) {
          _this.entities = v1.data;
          _this.loading = false;
        })
      );
    },
    init: function() {
      this.load();
    }
  },
  mounted: function() {
    this.init();
    console.log("BHistoryList mounted");
  }
};
</script>

<style></style>
