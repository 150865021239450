<template>
  <el-pagination
    background
    layout="prev,pager,next"
    :total="entities.totalElements"
    :page-size="entities.size"
    :current-page="entities.number + 1"
    @current-change="move"
  ></el-pagination>
</template>
<script>
export default {
  name: "em-pagination1",
  props: {
    entities: {
      type: Object,
    },
  },
  methods: {
    move: function (currentPage) {
      this.$emit("move", currentPage);
    },
  },
};
</script>
<style></style>