<template>
  <el-card>
    <div slot="header">
      <el-row>
        <el-col :span="12" class>{{ title }}</el-col>
      </el-row>
    </div>
    <div class="p-2">
      <el-row>
        <el-col :span="24">
          <span>합계</span>
          <div>
            <h2 class="bold text-center">
              {{ data.TOTAL_CNT | formatNumber }}
              <el-tooltip placement="top">
                <div slot="content">
                  <table class="table table-bordered table-dark table-sm m-0">
                    <thead>
                      <tr>
                        <th scope="col" width="100">구분</th>
                        <th scope="col" width="50">경고</th>
                        <th scope="col" width="50">오류</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>인터페이스</td>
                        <td>
                          {{ error.INTERFACE_WARNING_CNT | formatNumber }}
                        </td>
                        <td>
                          {{ error.INTERFACE_ERROR_CNT | formatNumber }}
                        </td>
                      </tr>
                      <tr>
                        <td>전문</td>
                        <td>
                          {{ error.RECORD_WARNING_CNT | formatNumber }}
                        </td>
                        <td>
                          {{ error.RECORD_ERROR_CNT | formatNumber }}
                        </td>
                      </tr>
                      <tr>
                        <td>맵핑</td>
                        <td>
                          {{ error.MAPPING_WARNING_CNT | formatNumber }}
                        </td>
                        <td>
                          {{ error.MAPPING_ERROR_CNT | formatNumber }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-tag
                  size="small"
                  type="warning"
                  @click="clickWarning"
                  class="mx-1"
                  effect="dark"
                  v-if="data.WARNING_CNT > 0"
                  >경고 : {{ data.WARNING_CNT | formatNumber }}</el-tag
                >
              </el-tooltip>
              <el-tooltip placement="top">
                <div slot="content">
                  <table class="table table-bordered table-dark table-sm m-0">
                    <thead>
                      <tr>
                        <th scope="col" width="100">구분</th>
                        <th scope="col" width="50">경고</th>
                        <th scope="col" width="50">오류</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>인터페이스</td>
                        <td>
                          {{ error.INTERFACE_WARNING_CNT | formatNumber }}
                        </td>
                        <td>
                          {{ error.INTERFACE_ERROR_CNT | formatNumber }}
                        </td>
                      </tr>
                      <tr>
                        <td>전문</td>
                        <td>
                          {{ error.RECORD_WARNING_CNT | formatNumber }}
                        </td>
                        <td>
                          {{ error.RECORD_ERROR_CNT | formatNumber }}
                        </td>
                      </tr>
                      <tr>
                        <td>맵핑</td>
                        <td>
                          {{ error.MAPPING_WARNING_CNT | formatNumber }}
                        </td>
                        <td>
                          {{ error.MAPPING_ERROR_CNT | formatNumber }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-tag
                  size="small"
                  type="danger"
                  @click="clickDanger"
                  class="mx-1"
                  effect="dark"
                  v-if="data.ERROR_CNT > 0"
                  >오류 : {{ data.ERROR_CNT | formatNumber }}</el-tag
                >
              </el-tooltip>
            </h2>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="text-left">
          <small>Commit ( 저장 )</small>
          <small>{{ (data.TOTAL_CNT - data.PUSH_CNT) | formatNumber }}</small>
        </el-col>
        <el-col :span="12" class="text-left">
          <small>Push ( 서버 전송 )</small>
          <small>{{ data.PUSH_CNT | formatNumber }}</small>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="text-left">
          <div class="chart-container" style="height: 3vh">
            <em-commitpush-chart :data.sync="data"></em-commitpush-chart>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import CommitPushChart from "../chart/CommitPushChart.vue";

export default {
  name: "em-server-widget",
  components: {
    "em-commitpush-chart": CommitPushChart,
  },
  props: {
    title: { type: String, default: "" },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    error: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: function () {
    return {};
  },
  watch: {
    data: function (newVal, oldVal) {
      this.updateData(newVal);
    },
    maxy: function (newVal, oldVal) {
      this.updateMax(newVal);
    },
  },
  methods: {
    clickWarning: function () {
      this.$emit("click-warning");
    },
    clickDanger: function () {
      this.$emit("click-danger");
    },
    updateMax: function (max) {
      //console.log("updateMax");
      this.chartCtx.options.scales.yAxes[0].ticks.max = max;
      this.chartCtx.update();
    },
    updateData: function (chartData) {},
  },
  mounted: function () {},
};
</script>

<style></style>
