<template>
  <el-card class="my-2" shadow="never">
    <div slot="header" v-if="title!=''">
      <h2 class="titleH2 my-1">{{title}}</h2>
    </div>
    <template v-for="(e,idx) in forms">
      <el-form-item
        :key="idx"
        v-show="isShowProperty(e)"
        :label="e.name"
        label-width="150px"
        size="mini"
        :rules="e.rules"
        class="mb-1"
      >
        <template v-if="e.inputType=='COMBO'">
          <el-select v-model="extendProperties[idx].value" @change="setDirty">
            <el-option
              v-for="(f,fidx) in e.optionArray"
              :label="f.label"
              :value="f.value"
              :key="fidx"
            ></el-option>
          </el-select>
        </template>
        <template v-else-if="e.inputType=='PASSWORD'">
          <el-input v-model="extendProperties[idx].value" show-password @change="setDirty"></el-input>
        </template>
        <template v-else-if="e.inputType=='TEXTAREA'">
          <el-input
            type="textarea"
            autosize="{minRows : 2 , maxRows : 4 }"
            v-model="extendProperties[idx].value"
            @change="setDirty"
          ></el-input>
        </template>
        <template v-else>
          <el-input v-model="extendProperties[idx].value" @change="setDirty"></el-input>
        </template>
        <small v-show="e.description" class="form-text text-muted " style="white-space: pre;" >{{e.description}}</small>
      </el-form-item>
    </template>
  </el-card>
</template>

<script>
export default {
  name: "em-resource-extendproperty",
  props: {
    extendProperties: {
      type: Array,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data: function () {
    var data = {
      forms: [],
    };
    return data;
  },
  methods: {
    setDirty: function () {
      this.$emit("changeitem");
    },
    isShowProperty: function (e) {
      if (e.inputType === "HIDDEN") {
        return false;
      }
      if (e.viewOption) {
        //console.log("e.viewOption", e.viewOption);
        var viewOption = JSON.parse(e.viewOption);
        //console.log(viewOption.key);
        //console.log(viewOption.value);
        for (var i = 0; i < this.extendProperties.length; i++) {
          var property = this.extendProperties[i];

          if (viewOption.key == property.key) {
            if (viewOption.value == property.value) {
              return true;
            } else {
              e.value = "";
              return false;
            }
          }
        }
      }
      return true;
    },
    popupGroups: function () {
      this.$emit("popup-groups");
    },
    handelCommand: function (command) {
      //console.log("handelCommand", command);
      this.$emit("command", command);
    },
    init: function () {
      //console.log("BResourceExtendProperty Init " , this.extendProperties);
      var forms = [];

      for (var i = 0; i < this.extendProperties.length; i++) {
        var property = this.extendProperties[i];
        var form = {
          inputType: property.inputType,
          name: property.name,
          description: property.description,
          viewOption: property.viewOption,
        };
        /// console.log("property" ,property);

        if (property.inputType == "COMBO") {
          var a = this.extendProperties[i].options;
          var k = JSON.parse(a);
          form.optionArray = k;
        }

        if (property.value == "" || property.value == undefined) {
          property.value = property.defaultValue;
        }

        if (property.inputType == "HIDDEN") {
          property.value = property.defaultValue;
        }

        form.rules = { required: property.requiredYn == "Y" };
        //Vue.set(this.extendProperties[i], 'rules', { required: property.requiredYn == 'Y' });
        forms.push(form);
        //console.log(JSON.stringify(this.extendProperties[i].rules));
        this.forms = forms;
      }
    },
  },
  mounted: function () {
    this.init();
    console.log("BResourceExtendProperty mounted");
  },
};
</script>

<style></style>
