<template>
  <span>
    [최종수정 :{{entity.mdfcnUser}},{{entity.mdfcnYmd|formatDateTime}}]
    [최초생성 :{{entity.crtUser}},{{entity.crtYmd|formatDateTime}}]
  </span>
</template>

<script>
export default {
  name: "em-last-modify",
  props: {
    entity: {
      type: Object,
    },
  },
  data: function () {
    var data = {};
    return data;
  },
};
</script>

<style></style>
