<script>
//import _ from "lodash";
import Table from "../Table";
import { forEachTreeChildren } from "../../../utils/tree";
export default {
  extends: Table,
  name: "em-property",
  props: {
    mfields: {
      type: Array,
      default() {
        return [
          {
            key: "key",
            width: "200px",
            label: "ID",
            type: "text",
            required: true,
            unique: true
          },
          {
            key: "name",
            width: "300px",
            label: "이름",
            type: "text",
            required: true
          },
          {
            key: "description",
            width: "300px",
            label: "설명",
            type: "text"
          },
          {
            key: "property",
            width: "500px",
            label: "속성",
            type: "text"
          }
        ];
      }
    }
  },
  methods: {
    validateModel: function () {
      //      console.log( "b-model","validateModel" );
      var mfields = this.mfields;
      for (var i = 0; i < mfields.length; i++) {
        if (mfields[i].option != undefined) {
          mfields[i].optionValues = _.map(mfields[i].option, "value");
        }
      }
      var colLength = this.mfields.length;
      var result = true;
      forEachTreeChildren(this.tree.children, function (parent, items) {
        var uniques = [];
        for (var row = 0; row < items.length; row++) {
          var uniqueKey = "";
          var item = items[row];
          for (var col = 0; col < colLength; col++) {
            var field = mfields[col];
            var cell = item.cols[col];
            var data = item.data;
            var key = field.key;
            var value = data[key];
            cell.error = false;
            if (field.required && (value == undefined || value == "")) {
              cell.error = true;
              cell.errorMsg = "필수값를 확인 하세요";
              result = false;
            }

            if (
              field.type == "select" &&
              _.indexOf(field.optionValues, value) == -1
            ) {
              //              console.log( field.optionValues  , value );
              cell.error = true;
              cell.errorMsg = "입력값을  확인 하세요";
              result = false;
            }
            if (field.unique) {
              //console.log( key , "["+value+"]" );
              uniqueKey = uniqueKey + value;
            }
          }
          if (uniqueKey != "" && _.indexOf(uniques, uniqueKey) > -1) {
            for (var icol = 0; icol < colLength; icol++) {
              var ifield = mfields[icol];
              var icell = item.cols[icol];
              //              console.log( uniqueKey );
              if (ifield.unique) {
                icell.error = true;
                icell.errorMsg = "중복값을 확인 하세요";
                result = false;
              }
            }
          } else {
            uniques.push(uniqueKey);
          }
        }
      });
      return result;
    }
  }
};
</script>

<style></style>
