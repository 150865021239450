<template>
  <table class="table table-hover table-bordered table-striped table-sm mb-0">
    <tfoot>
      <tr>
        <td>
          <div class="row">
            <div class="col">
              <nav>
                <ul class="pagination pagination-sm m-0">
                  <li class="page-item">
                    <a
                      class="page-link"
                      @click.stop="$parent.clickMenuInsertRow()"
                    >
                      <i class="fa fa-plus"></i>
                    </a>
                  </li>
                  <li class="page-item">
                    <a
                      class="page-link"
                      @click.stop="$parent.clickMenuDeleteRow()"
                    >
                      <i class="fa fa-minus"></i>
                    </a>
                  </li>
                  <li class="page-item">
                    <a
                      class="page-link"
                      @click.stop="$parent.clickMenuMoveUp()"
                    >
                      <i class="fa fa-caret-up"></i>
                    </a>
                  </li>
                  <li class="page-item">
                    <a
                      class="page-link"
                      @click.stop="$parent.clickMenuMoveDown()"
                    >
                      <i class="fa fa-caret-down"></i>
                    </a>
                  </li>
                  <li class="page-item">
                    <a
                      class="page-link"
                      @click.stop="$parent.clickMenuMoveLeft()"
                    >
                      <i class="fa fa-caret-left"></i>
                    </a>
                  </li>
                  <li class="page-item">
                    <a
                      class="page-link"
                      @click.stop="$parent.clickMenuMoveRight()"
                    >
                      <i class="fa fa-caret-right"></i>
                    </a>
                  </li>
                  <li class="page-item">
                    <a
                      class="page-link"
                      @click.stop="$parent.clickMenuMoveRight()"
                    >
                      <i class="fa fa-caret-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <textarea
            v-if="copyable"
            ref="hiddenCopyText"
            class="sr-only"
            cols="100"
          ></textarea>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: "em-tree-menu",
  props: {
    copyable: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
