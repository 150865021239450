<template>
  <tr class="in-table-tr-cell" :rowselected="item.row.select == true">
    <td
      width="10"
      valign="middle"
      class="p-0"
      style="width: 10px"
      :style="indicateStyle()"
    >
      <div style="width: 10px">
        <label
          class="form-control-xs form-control in-table-form-control-cell m-0"
          draggable="true"
          @click="$parent.clickIndicate($event, item)"
          @dragstart="$parent.dragStartIndicate($event, item)"
          @dragenter="$parent.dragEnterIndicate($event, item)"
          @dragover="$parent.dragOverIndicate($event, item)"
        ></label>
      </div>
    </td>
    <td
      class="p-0 in-table-td-cell"
      v-for="(c, col) in $parent.mfields"
      :width="c.width"
      :style="'width: ' + c.width"
      :selected="item.row.select == false && item.cols[col].select == true"
      :actived="item.row.select == false && item.cols[col].active == true"
      :error="item.cols[col].error == true"
      :rowselected="item.row.select == true"
      :key="'col_' + col"
    >
      <div class="row no-gutters">
        <template v-for="n in item.depth">
          <div
            class="col border-right"
            v-if="col == 0"
            :key="'col_' + col + '_d_' + n"
            style="width: 10px;max-width: 10px"
          ></div>
        </template>
        <div class="col" v-if="col == 0" style="width: 12px;max-width: 12px">
          <i
            v-if="item.children.length > 0"
            class="fa fa-caret-down"
            style="margin-top: 6px; margin-left: 2px;"
          ></i>
        </div>
        <div class="col" style="width: 10px">
          <template v-if="readonly == true || c.readonly == true">
            <label
              class="form-control-xs form-control in-table-form-control-cell in-table-label-cell m-0"
              draggable="true"
              tabindex="0"
              v-text="$parent.getColLabel(item, col)"
              :id="idlabel(col)"
              @dragstart="$parent.dragStartLabel($event, item, col)"
              @dragover="$parent.dragOverLabel($event, item, col)"
              @drop="$parent.dropLabel($event, item, col)"
            ></label>
          </template>
          <template v-else>
            <template v-if="item.cols[col].active == false">
              <label
                class="form-control-xs form-control in-table-form-control-cell in-table-label-cell m-0"
                draggable="true"
                tabindex="0"
                v-text="$parent.getColLabel(item, col)"
                :id="idlabel(col)"
                @click="$parent.clickLabel($event, item, col)"
                @dragstart="$parent.dragStartLabel($event, item, col)"
                @dragenter="$parent.dragEnterLabel($event, item, col)"
                @dragover="$parent.dragOverLabel($event, item, col)"
                @dragend="$parent.dragEndLabel($event, item, col)"
                @drop="$parent.dropLabel($event, item, col)"
                @keydown="$parent.keyDownLabel($event, item, col)"
                :draging="item.cols[col].draging == true"
              ></label>
            </template>
            <template v-else>
              <template v-if="c.type == 'text'">
                <input
                  type="text"
                  class="form-control-xs form-control in-table-form-control-cell"
                  v-model="item.data[c.key]"
                  :id="idcell(col)"
                  @click.stop
                  @change="$parent.changeCell($event, item, col)"
                  @keydown.stop="$parent.keyDownInputCell($event, item, col)"
                />
              </template>
              <template v-if="c.type == 'select'">
                <select
                  class="form-control-xs form-control in-table-form-control-cell"
                  size="mini"
                  v-model="item.data[c.key]"
                  :id="idcell(col)"
                  @click.stop
                  @change="$parent.changeCell($event, item, col)"
                  @keydown.stop="$parent.keyDownInputCell($event, item, col)"
                >
                  <option
                    v-for="(item, index) in c.option"
                    :value="item.label"
                    :key="'col_' + col + '_op_' + index"
                    >{{ item.label }}</option
                  >
                </select>
              </template>
              <template v-if="c.type == 'popup'">
                <label
                  class="form-control-xs form-control in-table-form-control-cell in-table-label-cell m-0"
                  v-text="item.data[c.key]"
                  :id="idcell(col)"
                  @click.stop="$parent.popupCell($event, item, col)"
                >
                  <span class="fa fa-eclipse"></span>
                </label>
              </template>
            </template>
          </template>
          <el-popover
            v-if="item.cols[col].error == true"
            :content="item.cols[col].errorMsg"
          >
            <div
              slot="reference"
              class="grid-cell-dot"
              v-if="item.cols[col].error == true"
            ></div>
          </el-popover>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "em-table-row",
  watch: {},
  computed: {},
  props: {
    item: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    indicateStyle: function() {
      //console.log( indicateStyle );

      if (this.item.row.select) {
        return { "background-color": "#daeeff" };
      } else {
        //console.log( this.item.children );
        if (this.item.children.length > 0) {
          return { "background-color": "#eeeeee" };
        } else {
          return { "background-color": "#eeeeee" };
        }
      }
    },
    idlabel: function(col) {
      return this.$parent.id + "_label_" + this.item.rowNo + "_" + col;
    },
    idcell: function(col) {
      return this.$parent.id + "_cell_" + this.item.rowNo + "_" + col;
    }
  },
  updated: function() {
    //   console.log("updated row" , this.item.data.key);
  }
};
</script>

<style>
/* ---------------------------------------- */
/* TABLE
/* ---------------------------------------- */
.in-table-table-cell {
  table-layout: fixed;
  width: 100px;
}

.in-table-tr-cell[rowselected] {
  border: 1px solid darkgrey;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px darkgrey;
  outline: 1px solid darkgrey;
  outline-offset: -1px;
  background-color: #daeeff;
}

.in-table-td-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  border: 2px solid #dee2e6;
  position: relative;
}
.in-table-label-cell[draging] {
  box-sizing: border-box;
  outline: 1px solid darkgrey;
  outline-offset: -1px;
  background-color: #ff0000;
  cursor: all-scroll;
}
.in-table-td-cell[rowselected] {
  box-sizing: border-box;
}

.in-table-td-cell[selected] {
  box-sizing: border-box;
  outline: 1px solid darkgrey;
  outline-offset: -1px;
  background-color: #daeeff;
}

.in-table-td-cell[actived] {
  background-color: transparent;
}
.in-table-td-cell[error] {
  outline: 2px solid #a22727;
  outline-offset: -2px;
  box-sizing: border-box;
  background-color: #ffdae3;
}

.form-control-xs {
  height: calc(1.5em + 0.5em + 2px);
  padding: 0.25rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.in-table-form-control-cell {
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.in-table-form-control-cell:focus {
  box-shadow: none !important;
}

.in-table-form-control-xs {
  height: calc(1.5em + 0.5em + 2px);
  padding: 0.25rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.in-table-empty-cell {
  border: 1px solid #e2e2e2 !important;
}

.in-table-scroll {
  overflow-y: scroll;
}
.in-tree-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: lightgrey;
}

.in-table-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.in-table-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.grid-cell-dot {
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 1px;
  background-color: #a22727;
  width: 8px;
  bottom: 3px;
}

.form-control-xs {
  height: calc(1.5em + 0.5em + 2px) !important;
  padding: 0.25rem 0.25rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}
</style>
