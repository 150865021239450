<template>
  <el-menu-item
    v-if="tree.children.length==0"
    :index="index"
    v-text="tree.data.label"
    @click="clickMenuItem( tree )"
  ></el-menu-item>
  <el-submenu :index="index" v-else>
    <template slot="title">
      <span slot="title" v-text="tree.data.label"></span>
    </template>
    <b-group-tree
      v-for="(cgroup,cgidx) in tree.children"
      :key="cgidx"
      @clickmenuitm="clickMenuItem( $event )"
      :tree="cgroup"
      :index="index + '_' +cgidx"
    ></b-group-tree>
  </el-submenu>
</template>

<script>
export default {
  name: "b-group-tree",
  props: {
    tree: {
      type: Object,
    },
    index: {
      type: String,
    },
  },
  methods: {
    clickMenuItem: function (tree) {
      this.$emit("clickmenuitm", tree);
    },
  },
};
</script>

<style></style>
