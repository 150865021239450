<template>
  <div
    @keydown="keyDownTop($event)"
    @click="clickTop($event)"
    ref="top"
    tabindex="-1"
  >
    <em-tree-menu
      v-if="showMenu"
      ref="topMenu"
      :id="id"
      copyable
    ></em-tree-menu>
    <em-tree-head v-if="showHead" ref="head"></em-tree-head>
    <div
      class="table-responsive in-table-scroll"
      :style="'height:' + height"
      @scroll.passive="scrollTable($event)"
    >
      <table
        tabindex="-1"
        ref="table"
        class="table table-bordered in-table-table-cell table-sm mb-0"
      >
        <tbody ref="treeBody">
          <em-tree-row
            :item="e"
            :readonly="readonly"
            v-for="e in datas"
            :key="'row_' + e.rowNo"
          ></em-tree-row>
          <template ng-if="items.length<minRows" v-for="n in emptyRowSize">
            <em-tree-row-empty :key="'em_row_' + n"></em-tree-row-empty>
          </template>
        </tbody>
      </table>
    </div>
    <em-tree-menu v-if="showMenu"></em-tree-menu>
  </div>
</template>

<script>
import Table from "../../table/Table";
import TreeMenu from "./TreeMenu.vue";
import TreeHead from "./TreeHead.vue";
import TreeRow from "./TreeRow.vue";
import TreeRowEmpty from "./TreeRowEmpty.vue";

import Vue from "vue";
import {
  getPathTree,
  deepCopyItems,
  forEachTreeChildren,
  forEachTree
} from "../../../utils/tree";

export default {
  extends: Table,
  name: "em-tree",
  components: {
    "em-tree-menu": TreeMenu,
    "em-tree-head": TreeHead,
    "em-tree-row": TreeRow,
    "em-tree-row-empty": TreeRowEmpty
  },
  props: {
    id: {
      type: String
    },
    mfields: {
      type: Array
    },
    items: {
      type: Array
    },
    height: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    colSelect: {
      type: Boolean,
      default: true
    },
    showMenu: {
      type: Boolean,
      default: true
    },
    showHead: {
      type: Boolean,
      default: true
    },
    minRows: {
      type: Number,
      default: 20
    }
  },
  data: function() {
    var data = {
      datas: [],
      tree: { children: [] },
      defaultCell: [],
      defaultItem: {},
      selectedCells: [],
      undoData: [],
      redoData: []
    };
    return data;
  },
  watch: {
    mfields: {
      handler: function(nv, ov) {
        this.makeFieldsInfo();
      },
      deep: false
    },
    items: {
      handler: function(nv, ov) {
        this.createDatas(this.items);
      },
      deep: false
    }
  },
  computed: {
    emptyRowSize: function() {
      return Math.max(this.minRows - this.getRowSize(), 0);
    }
  },
  methods: {
    createDatas: function(oitems) {
      //      console.log("tree createDatas" );

      var items = deepCopyItems(oitems);
      this.tree.children = items;
      var rowNo = 0;
      var datas = [];
      var defaultCell = this.defaultCell;
      var tidx = 0;
      forEachTree(this.tree.children, function(depth, index, parent, item) {
        Vue.set(item, "parent", parent);
        Vue.set(item, "depth", depth);
        Vue.set(item, "rowNo", rowNo);
        Vue.set(item, "cols", deepCopyItems(defaultCell));
        Vue.set(item, "row", { select: false, readOnly: false });
        //console.log( parent , item);

        var tid = index + 1;

        if (parent == undefined) {
          tidx++;
        } else {
          tid = parent.tid + "." + (index + 1);

          if (parent.data.fieldType == "Group") {
            if (parent.data.arrayType == "Not") {
              tidx++;
              tid = tidx;
            }
          }
        }

        if (item.data.fieldType == "Group") {
          if (item.data.arrayType == "Not") {
            tid = "";
            tidx--;
          }
        }

        Vue.set(item, "tid", tid);

        datas.push(item);
        //console.log("depth" ,depth ,"index",index,parent);
        rowNo++;
      });
      this.datas = datas;
      this.saveItems();
      //      console.log("tree createDatas",datas.length );
    },
    refreshDatas: function() {
      //      console.log("refreshDatas");

      var rowNo = 0;
      var datas = [];
      var defaultCell = this.defaultCell;
      forEachTree(this.tree.children, function(depth, index, parent, item) {
        Vue.set(item, "parent", parent);
        Vue.set(item, "depth", depth);
        Vue.set(item, "rowNo", rowNo);
        if (parent == undefined) {
          var tid = index + 1;
          Vue.set(item, "tid", tid);
        } else {
          var tid = parent.tid + "." + (index + 1);
          Vue.set(item, "tid", tid);
        }
        datas.push(item);
        rowNo++;
      });
      this.datas = datas;
    },
    moveRightRows: function(rows) {
      //      console.log("moveRightRows",rows);
      if (rows == undefined || rows.length == 0) {
        //        console.log("moveRightRows false rows");
        return false;
      }
      var isSibling = this.isSibling(rows);
      if (isSibling == false) {
        //        console.log("moveRightRows false isSibling");
        return false;
      }
      var siblings = this.getSiblings(rows[0]);
      var indexOf = _.indexOf(siblings, rows[0]);
      if (indexOf == 0) {
        //        console.log("moveRightRows false first ");
        return false;
      }

      var parent = siblings[indexOf - 1];
      this.removeChildren(rows);
      this.addChildren(parent, -1, rows);
      this.saveItems();
      this.refreshDatas();
      return true;
    },
    moveLeftRows: function(rows) {
      //      console.log("moveLeftRows",rows);
      if (rows == undefined || rows.length == 0) {
        //        console.log("moveLeftRows false rows");
        return false;
      }
      var isSibling = this.isSibling(rows);
      if (isSibling == false) {
        //        console.log("moveLeftRows false isSibling");
        return false;
      }
      var parent = this.getParent(rows[0]);
      if (parent == undefined) {
        //        console.log("moveLeftRows false getParent");
        return false;
      }

      var indexOf = this.getSiblingsIndex(parent);
      //      console.log("getSiblingsIndex indexOf" , indexOf);

      this.removeChildren(rows);
      this.addChildren(parent.parent, indexOf, rows);

      //      console.log("moveLeftRows indexOf" , indexOf);
      this.saveItems();
      this.refreshDatas();
      return true;
    },
    saveItems: function() {
      //      console.log("saveItems",this.undoData.length );
      this.undoData.push(deepCopyItems(this.tree));
      if (this.undoData.length > 10) {
        this.undoData.shift();
      }
      this.redoData = [];
      this.$emit("changeitem");
      //      console.log("changeitem");
    },
    dragStartLabel: function(e, item, col) {
      this.setSelect(item, col);
      // var data = JSON.stringify(item);
      var paths = getPathTree(this.tree.children, item);
      //      console.log( "getPathTree" , paths );
      var data = { id: this.id, paths: paths };
      e.dataTransfer.setData("application/tree-cell", "");
      e.dataTransfer.setData(
        "application/tree-cell-link",
        JSON.stringify(data)
      );
      // console.log("dragStart" , e.dataTransfer);
    },
    validateTree: function() {
      //      console.log( "b-tree","validateModel" , this );

      var _this = this;
      var mfields = this.mfields;
      var colLength = this.mfields.length;
      var result = true;
      forEachTreeChildren(this.tree.children, function(parent, items) {
        var uniques = [];
        for (var row = 0; row < items.length; row++) {
          var uniqueKey = "";
          var item = items[row];
          for (var col = 0; col < colLength; col++) {
            var field = mfields[col];
            var cell = item.cols[col];
            var data = item.data;
            var key = field.key;
            var value = data[key];
            cell.error = false;
            if (field.required && (value == undefined || value == "")) {
              cell.error = true;
              cell.errorMsg = "필수값를 확인 하세요";
              result = false;
            }
            if (field.unique) {
              //console.log( key , "["+value+"]" );
              uniqueKey = uniqueKey + value;
            }
          }
          if (uniqueKey != "" && _.indexOf(uniques, uniqueKey) > -1) {
            for (var col = 0; col < colLength; col++) {
              var field = mfields[col];
              var cell = item.cols[col];
              //              console.log( uniqueKey );
              if (field.unique) {
                cell.error = true;
                cell.errorMsg = "중복값을 확인 하세요";
                result = false;
              }
            }
          } else {
            uniques.push(uniqueKey);
          }
        }
      });
      return result;
    },
    getItems: function() {
      //      console.log("tree getItems");
      var items = deepCopyItems(this.tree.children);
      var mfields = this.mfields;
      var propertyFields = [];
      for (var i = 0; i < mfields.length; i++) {
        var f = mfields[i];
        if (f.property == true) {
          propertyFields.push(f.key);
        }
      }
      if (propertyFields.length > 0) {
        forEachTree(items, (depth, index, parent, item) => {
          var property = this.decodeProperty(item.data.property);
          for (var i = 0; i < propertyFields.length; i++) {
            var key = propertyFields[i];
            if (
              item.data.properties[key] == undefined ||
              item.data.properties[key] == ""
            ) {
              if (property[key] !== undefined) {
                delete property[key];
              }
            } else {
              property[key] = item.data.properties[key];
            }
          }
          item.data.property = this.encodeProperty(property);
        });
      }
      //      console.log("tree getItems",items);

      return items;
    }
  },
  mounted: function() {
    //    console.log("mounted tree");
    this.makeFieldsInfo();
    this.createDatas(this.items);
  },
  updated: function() {
    //    console.log("updated tree");
  }
};
</script>

<style scoped>
.in-mapping-dnd-point {
  cursor: pointer;
}
.in-tree-table-cell {
  table-layout: fixed;
}

.in-tree-tr-cell[rowselected] {
  background-color: #daeeff;
}

.in-tree-td-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  border: 2px solid #dee2e6;
}
.in-tree-label-cell[draging] {
  box-sizing: border-box;
  outline: 1px solid darkgrey;
  outline-offset: -1px;
  background-color: #ff0000;
  cursor: all-scroll;
}
.in-tree-td-cell[rowselected] {
  box-sizing: border-box;
}

.in-tree-td-cell[selected] {
  box-sizing: border-box;
  outline: 1px solid darkgrey;
  outline-offset: -1px;
  background-color: #daeeff;
}

.in-tree-td-cell[actived] {
  outline: 2px solid darkgrey;
  outline-offset: -2px;
  box-sizing: border-box;
  background-color: transparent;
}
.in-tree-td-cell[error] {
  outline: 2px solid #a22727;
  outline-offset: -2px;
  box-sizing: border-box;
  background-color: #ffdae3;
}

.in-tree-form-control-cell {
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.in-tree-form-control-cell:focus {
  box-shadow: none !important;
}

.in-tree-form-control-xs {
  height: calc(1.5em + 0.5em + 2px);
  padding: 0.25rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.aform-control-lg1 {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.aform-control-sm1 {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.grid-indicate-dot {
  position: absolute;
  top: 1px;
  right: 3px;
  border-radius: 1px;
  background-color: #f56c6c;
  width: 6px;
  bottom: 1px;
}
.grid-cell-dot {
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 1px;
  background-color: #a22727;
  width: 8px;
  bottom: 3px;
}
.td-empty-cell {
  border: 1px solid #e2e2e2 !important;
}

.in-tree-scroll {
  overflow-y: scroll;
  overflow-x: scroll;
}
.in-tree-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: lightgrey;
}

.in-tree-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.in-tree-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
</style>
