<template>
  <el-card class="my-2" shadow="never">
    <div slot="header">
      <h2 class="titleH2">최종수정 정보</h2>
    </div>
    <el-row>
      <el-col :span="6">
        <label class="col-form-label mr-2">등록사용자</label
        ><label class="col-form-label">{{ entity.crtUser }}</label>
      </el-col>
      <el-col :span="6">
        <label class="col-form-label mr-2">등록일시</label
        ><label class="col-form-label">{{
          entity.crtYmd | formatDateTime
        }}</label>
      </el-col>
      <el-col :span="6">
        <label class="col-form-label mr-2">수정사용자</label
        ><label class="col-form-label">{{ entity.mdfcnUser }}</label>
      </el-col>
      <el-col :span="6">
        <label class="col-form-label mr-2">수정일시</label
        ><label class="col-form-label">{{
          entity.mdfcnYmd | formatDateTime
        }}</label>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: "em-label-last-modified",
  props: {
    entity: {
      type: Object,
    },
  },
};
</script>

<style></style>
