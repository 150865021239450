<template>
  <el-card class="my-2" shadow="never">
    <div slot="header">
      <el-row>
        <el-col :span="12" class="pt-2">
          <h2 class="titleH2">연관 인터페이스</h2>
        </el-col>
        <el-col class="text-right" :span="12">
          <el-button native-type="button" size="small" icon="el-icon-refresh" @click="refresh"></el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th scope="col" width="20%">시스템</th>
            <th scope="col" width="20%">인터페이스</th>
            <th scope="col" width="50%">인터페이스 명</th>
            <th scope="col" width="20%">최종수정</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in entities" :Key="e.id">
            <td>{{e.systemId}} -> {{e.serverId}} -> {{e.providerSystemId}}</td>
            <td>
              <a :href="'/resource/interface/view?id='+e.id" target="_blank">{{e.id}}</a>
            </td>
            <td>{{e.name}}</td>
            <td nowrap="nowrap">{{e.mdfcnYmd|formatDateTime}}</td>
          </tr>
          <tr v-if="entities!=undefined&&entities.length==0">
            <td colspan="4" valign="middle" align="center">
              <p class="p-3">조회결과가 없습니다.</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "em-ref-list",
  props: {
    resourceid: {
      type: String
    }
  },
  data: function() {
    var data = {
      loading: true,
      entities: []
    };
    return data;
  },
  methods: {
    refresh: function() {
      this.loading = true;
      this.params.page = 0;
      this.load();
    },
    load: function() {
      this.loading = true;
      var _this = this;
      //    debugger;
      var q1 = axios.get(
        "/rest/portal/resource/interface/" + this.resourceid + "/reference"
      );

      axios.all([q1]).then(
        axios.spread(function(v1) {
          _this.entities = v1.data;
          _this.loading = false;
        })
      );
    },
    init: function() {
      this.load();
    }
  },
  mounted: function() {
    this.init();
    //console.log("BHistoryList mounted");
  }
};
</script>

<style></style>
