<template>
  <canvas ref="chartCon"></canvas>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

export default {
  name: "em-time-chart",
  props: {
    showaxes: {
      type: Boolean,
      default: false,
    },
    maxy: {
      type: Number,
      default: 0,
    },
    options: {
      type: Object,
      default() {
        return {
          type: "line",
          data: {
            labels: [""],
            datasets: [
              {
                label: "",
                backgroundColor: "rgb(75,192,192)",
                borderColor: "rgb(75,192,192)",
                data: [],
                pointRadius: 0,
                _steppedLine: "before",
                fill: false,
                borderWidth: 2,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
              mode: "index",
              intersect: false,
              custom: function (tooltip) {
                console.log("tooltip");
                var tooltipEl = document.getElementById("chartjs-tooltip");
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-tooltip";
                  tooltipEl.innerHTML = "<table></table>";
                  document.body.appendChild(tooltipEl);
                  //this._chart.canvas.parentNode.appendChild( tooltipEl );
                }
                //console.log(tooltip);

                if (tooltip.opacity === 0) {
                  tooltipEl.style.opacity = 0;

                  return;
                }

                tooltipEl.classList.remove("above", "below", "no-transform");
                if (tooltip.yAlign) {
                  tooltipEl.classList.add(tooltip.yAlign);
                } else {
                  tooltipEl.classList.add("no-transform");
                }
                if (tooltip.body) {
                  var titleLines = tooltip.title || [];
                  var bodyLines = tooltip.body.map(function (bodyItem) {
                    return bodyItem.lines;
                  });
                  var innerHtml = "<thead><tr>";
                  titleLines.forEach(function (title) {
                    innerHtml += "<th>" + title + "</th>";
                  });
                  bodyLines.forEach(function (body, i) {
                    var colors = tooltip.labelColors[i];
                    var style = "background:" + colors.backgroundColor + ";";
                    style += "border-color :" + colors.borderColor + ";";
                    style += "border-width : 2px;";
                    var span =
                      '<span class="chartjs-tooltip-key" style="' +
                      style +
                      '"></span>';
                    innerHtml += "<td>" + span + body + "</td>";
                  });

                  innerHtml += "</tr></thead>";
                  var tableRoot = tooltipEl.querySelector("table");
                  tableRoot.innerHTML = innerHtml;
                }
                //console.log(this._chart.canvas.getBoundingClientRect());
                var bound = this._chart.canvas.getBoundingClientRect();
                var positionY = this._chart.canvas.offsetTop;
                var positionX = this._chart.canvas.offsetLeft;
                tooltipEl.style.opacity = 1;
                tooltipEl.style.left =
                  bound.x + window.scrollX + +tooltip.caretX + "px";
                tooltipEl.style.top =
                  bound.y + window.scrollY + tooltip.caretY + "px";
                tooltipEl.style.padding =
                  tooltip.yPadding + "px " + tooltip.xPadding + "px";
                tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
                tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
                tooltipEl.style.fontStyle = tooltip._bodyFontfontStyle;

                //console.log(tooltipEl);
              },
            },
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  display: false,
                  type: "time",
                  time: {
                    unit: "month",
                    minUnit: "month",
                    parser: "YYYYMMDD",
                    displayFormats: {
                      week: "MM.DD",
                      month: "YYYY.MM",
                      day: "MM.DD",
                    },
                    tooltipFormat: "YYYY.MM.DD",
                  },
                  scaleLabel: {
                    display: true,
                  },
                  ticks: {
                    source: "auto",
                  },
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: true,
                  },
                },
              ],
              yAxes: [
                {
                  display: false,
                  scaleLabel: {
                    display: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 2,
                    min: 0,
                    max: 20,
                  },
                  gridLines: {
                    display: true,
                    drawBorder: true,
                    drawOnChartArea: true,
                  },
                },
              ],
            },
          },
        };
      },
    },
    data: {
      type: Array,
      default: [],
    },
    term: {
      type: String,
      default: "3M",
    },
  },
  data: function () {
    var data = {
      chartCtx: null,
      loading: true,
      entities: { content: [] },
    };
    return data;
  },
  watch: {
    data: function (newVal, oldVal) {
      this.updateData(newVal);
    },
    maxy: function (newVal, oldVal) {
      this.updateMax(newVal);
    },
  },
  methods: {
    startData: function () {
      var start = new moment();
      if (this.term === "1Y") {
        start = new moment().subtract(1, "years");
      }
      if (this.term === "3M") {
        start = new moment().subtract(3, "months");
      }
      return start;
    },
    updateMax: function (max) {
      console.log("updateMax");
      this.chartCtx.options.scales.yAxes[0].ticks.max = max;
      this.chartCtx.update();
    },
    updateData: function (chartData) {
      var current = new moment();
      var start = this.startData();
      var duration = moment.duration(current.diff(start));

      var _default = {};
      for (var i = 0; i < duration.asDays(); i++) {
        _default[start.add(1, "days").format("YYYYMMDD")] = 0;
      }
      for (var i = 0; i < this.data.length; i++) {
        //console.log(_default[this.data[i].CHART_DATE]);
        //console.log(this.data[i].CHART_DATE);

        _default[chartData[i].CHART_DATE] = chartData[i].TOTAL;
        // data.push({ x: this.data[i].CHART_DATE, y: this.data[i].TOTAL });
      }
      var data = [];

      _.forIn(_default, function (value, key) {
        data.push({ x: key, y: value });
      });
      //console.log(data);
      data = _.sortBy(data, ["x"]);

      //   if (data.length > 0) {
      //    		   this.chartCtx.options.scales.xAxes[0].ticks.min = data[0].x;
      //   }

      this.chartCtx.data.datasets[0].data = data;
      this.chartCtx.update();
    },
  },
  mounted: function () {
    //console.log(this);

    //console.log(this.data);
    //console.log("-----------------",this.data.length);
    var ctx = this.$refs.chartCon.getContext("2d");
    var options = _.cloneDeep(this.options);
    if (this.showaxes == true) {
      options.options.scales.yAxes[0].display = true;
      options.options.scales.xAxes[0].display = true;
    }
    this.chartCtx = new Chart(ctx, options);
    var current = new moment();
    var start = this.startData();
    //this.chartCtx.options.scales.yAxes[0].ticks.max = this.maxy;
    this.chartCtx.options.scales.yAxes[0].ticks.max = this.maxy;
    //console.log(this.chartCtx.options);
    this.chartCtx.options.scales.xAxes[0].ticks.min = start.format("YYYYMMDD");
    this.chartCtx.options.scales.xAxes[0].ticks.max = current.format(
      "YYYYMMDD"
    );
    this.updateData(this.data);
  },
};
</script>

<style></style>
